import { useState, useEffect } from "react";
//componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import Legenda from "../../../../components/Legenda";
//Utils
import { dataAtual, formatarData } from "../../../../utils/datas";
import { statusSolicitacao } from "../../../../utils/choices";
//Mui
import { Input, Select, Stack, Typography, Option, Button, Table, Switch, Link } from "@mui/joy";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
//Material
import { Pagination } from '@mui/material/';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
//API
import { api } from "../../../../services/api";

const dadosUsuarioLogado = {};
let idUsuario = 0;
let token = "";

//Dados do usuário logado no localstorage
const usuarioLogado = JSON.parse(localStorage.getItem('usuario'));

const filiais = [
    "1 - Pelicano Construcoes - ES",
    "2 - Pelicano Construcoes - MG",
    "4 - Pelicano Construcoes - MA",
    "5 - Pelicano Construcoes - PA",
    "7 - Pelicano Construcoes - SP",
    "100 - Pelicano Construcoes - GO",
    "101 - Pelicano Construcoes - MS",
    "102 - Pelicano Construcoes - SP"
]

export default function CentralComprasFinanceiro(props) {
    const corDaLinha = "";
    const navigate = useNavigate();
    const [dataInicio, setDataInicio] = useState(dataAtual(-1));
    const [dataTermino, setDataTermino] = useState(dataAtual(1));
    const [status, setStatus] = useState(1);
    //Verificando se o dispositivo é mobile
    const mobile = useMediaQuery('(max-width:1200px)');
    const [objetos, setObjetos] = useState([]);
    const [objetoCotacao, setObjetoCotacao] = useState();
    const [paginas, setPaginas] = useState({paginaAtual: 1,numeroPaginas: 1});
    const [verTodasCotacoes, setVerTodasCotacoes] = useState(false);
    const [numero1108Busca, setNumero1108Busca] = useState("");
    const [NFBusca, setNFBusca] = useState("");
    const [ordenaDataEntrega, setOrdenaDataEntrega] = useState(null);
    const [codLocSelectOpen, setCodLocSelectOpen] = useState(false);
    const [informacoesGerais, setInformacoesGerais] = useState({ coligadas: [], filiais: [], locaisDeEstoque: [], centrosDeCusto: [] })
    const [locaisDeEstoque, setLocaisDeEstoque] = useState([]);
    const [filialSelecionada, setFilialSelecionada] = useState();
    const [localEstoqueSelecionado, setLocalEstoqueSelecionado] = useState("");

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            dadosUsuarioLogado.nome = usuarioLogado.user.nome;
            dadosUsuarioLogado.usuario = usuarioLogado.user.login;
            dadosUsuarioLogado.obra = usuarioLogado.user.obra;
            dadosUsuarioLogado.grupo = usuarioLogado.grupo;

            token = JSON.parse(localStorage.getItem('usuario')).token;
            idUsuario = JSON.parse(localStorage.getItem('usuario')).user.id;

            let permissoes = [];
            if (dadosUsuarioLogado.grupo.length > 0) {
                dadosUsuarioLogado.grupo.forEach((item) => {
                    permissoes.push(item.perfil);
                })
            }
            dadosUsuarioLogado.permissoes = permissoes;
        }
        obterLocalEstoqueECentroCusto(1);
        buscarNotas();
    }, []);

    //Recarrega a lista toda vez que clica na coluna data:
    useEffect(() => {
        buscarNotas(paginas.paginaAtual);
    }, [ordenaDataEntrega]);

    const handleOrdenaDataEntrega = () => {
        // define estado da variavel ordenaDataAbertura (estados null, true ou false)
        if (ordenaDataEntrega == null){
            setOrdenaDataEntrega(true);
        } else if (ordenaDataEntrega == true){
            setOrdenaDataEntrega(false);
        }
        else if (ordenaDataEntrega == false){
            setOrdenaDataEntrega(null);
        }
    }

    const buscarNotas = (pagina = 1) => {

        //TODO: incluir parametro url de local estoque filial
        let urlBuscarNotas = `/notas?numeroOC=${numero1108Busca}&numeroNF=${NFBusca}&datanotai=${dataInicio}&datanotaf=${dataTermino}&localEstoque=${localEstoqueSelecionado}`;
        //Ordenar data criacao:
        // if (ordenaDataEntrega != null){
        //     urlBuscarNotas += `&orderDataEntrega=${ordenaDataEntrega}`;
        // }

        console.log("urlBuscarNotas",urlBuscarNotas);

        api.get(urlBuscarNotas,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
        ).then((response) => {
            let objetosDoBanco = [];
            if (response.data) {
                const dados = response.data;
                console.log(dados);
                dados.data.forEach(nota => {
                    objetosDoBanco.push(
                        {
                            //campos da nota
                            id: nota.id,
                            cotacao_id: nota.cotacao_id,
                            numeroNota: nota.numeroNota,
                            dataNota: nota.dataNota,
                            cnpjNota: nota.cnpjNota,
                            nomeFantasiaNota: nota.nomeFantasia,
                            codEmpresaNota: nota.codEmpresa,
                            //campos do mov de compra
                            serie: nota.cotacao.serie,
                            solicitacaoID: nota.cotacao.solicitacao_id,
                            etapaAtual: nota.cotacao.etapaAtual,
                            status: nota.cotacao.status,
                            dataEntrega: formatarData(nota.cotacao.dataEntrega.split(" ")[0]),
                            codCotacao: nota.cotacao.codCotacao,
                            comprador: nota.cotacao.comprador,
                            fornecedor: nota.cotacao.fornecedor,
                            condPagamento: nota.cotacao.condPagamento,
                            tipoFrete: nota.cotacao.tipoFrete,
                            numeroMov6: nota.cotacao.numeroMov6,
                            numeroMov8: nota.cotacao.numeroMov8,
                            codtmv: nota.codtmv,
                            numeroMov22: nota.cotacao.numeroMov22,
                            decisao: nota.cotacao.decisao,
                            codloc: nota.cotacao.codloc,
                            objetoCotacao: nota.cotacao
                        }
                    );
                });
                setObjetos(objetosDoBanco);
                //Definindo a paginação
                paginas.numeroPaginas = dados.meta.last_page;
            }
        }).catch((error) => {
            console.log(error);
            if (error.response.data.message == "Unauthenticated.") {
                localStorage.setItem("usuario", null);
                navigate("/acessoNegado");
            }
            else {
                toast.error("Ocorreu um erro ao obter as notas.");
                console.log(error.response.data.message);
            }
            toast.error(JSON.stringify(error.response.data));
        });
    }

    const alterarPagina = (event, value) => {
        paginas.paginaAtual = value;
        buscarNotas(value);
    }

    const obterLocalEstoqueECentroCusto = (filial = 1) => {
        let id_toast = toast.loading("Obtendo informações do RM...", { toastId: "toast" });
    
        let urlLocalEstoque = "";
        urlLocalEstoque = `/locaisEstoque?login=${dadosUsuarioLogado.usuario}&coligada=1&filial=${filial}&solicitacao=`;

        api.get(urlLocalEstoque, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
        ).then(function ({ data }) {

            console.log("meus locais estoque????", data);
            let listaLocaisDeEstoque = [];
        
            if (data.data.LOCALESTOQUE) {
                data.data.LOCALESTOQUE.forEach(localDeEstoque => {
                listaLocaisDeEstoque.push({ codigo: localDeEstoque.localEstoque, nome: localDeEstoque.localEstoque + " - " + localDeEstoque.nomeLocal });
                });
            } else {
                
                toast.update(id_toast, { render: "O RM não respondeu. Por favor, Atualize a página. Se o erro persistir, tente novamente mais tarde.", type: "warning", isLoading: false, closeOnClick: true });
                setTimeout(() => {
                // window.location.reload(true);
                }, 5000);
            }
            //Atualizando locais de estoque em informacoes gerais
            setLocaisDeEstoque(listaLocaisDeEstoque);
            toast.update(id_toast, { render: "Dados carregados com sucesso!", type: "success", isLoading: false, autoClose: 2000 });
            }).catch(function (err) {
                console.log("meus locais estoque erro!!", err);
                toast.error("Ocorreu um erro ao buscar locais de estoque , por favor recarregue a página.");
                toast.error(JSON.stringify(err.response.data));
                console.error(err);
            })
    }

    return(
        <Stack backgroundColor="#F5F5F5" height="100%" minHeight={"100vh"}>
            <Header notification mobile={mobile} />
            <Sidebar mobile={mobile} />

            <Stack ml={mobile ? "0px" : "280px"} mt={"50px"} flex={1} height={mobile ? "100%" : "calc(100% - 55px)"} p={mobile ? "20px" : "50px"} overflow="auto">
                {/* Conteúdo da Tela */}
                <Typography level="h4">Consulta de NF</Typography>
                <Stack ml={mobile ? "0px" : "20px"}>
                    <Stack flexDirection={mobile ? "column" : "row"} mt={mobile ? "20px" : "30px"} alignItems={mobile ? "flex-start" : "center"} justifyContent={"space-between"}>
                        <Stack width="100%" flexDirection={mobile ? "column" : "row"}>
                            <Stack mb={mobile ? "10px" : "0px"} mr={"30px"}>
                                <Typography level="body-sm">N° da OC</Typography>
                                <Stack>
                                    <Input value={numero1108Busca} onChange={e => setNumero1108Busca(e.target.value)} placeholder="000000000" sx={{ width: "130px" }} />
                                </Stack>
                            </Stack>
                            <Stack mb={mobile ? "10px" : "0px"} mr={"30px"}>
                                <Typography level="body-sm">N° da NF</Typography>
                                <Stack>
                                    <Input value={NFBusca} onChange={e => setNFBusca(e.target.value)} placeholder="000000000" sx={{ width: "130px" }} />
                                </Stack>
                            </Stack>
                            
                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"} mt={mobile ? "10px" : ""}>
                                <Typography level="body-sm">Filial:</Typography>
                                <Select key={filiais} defaultValue="1" value={filialSelecionada} onChange={(event, newValue) => [ setFilialSelecionada(newValue), obterLocalEstoqueECentroCusto(newValue)]}>
                                {filiais.map(function (filial, indice) {
                                    return (
                                    <Option key={indice} value={filial.split(" - ")[0]}>{filial}</Option>
                                    );
                                })}
                                </Select>
                            </Stack>

                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"} mr={mobile ? "0" : "30px"} mt={mobile ? "10px" : ""}>
                                <Typography level="body-sm">Local de Estoque</Typography>
                                <Select
                                    key={locaisDeEstoque}
                                    value={localEstoqueSelecionado}
                                    listboxOpen={codLocSelectOpen}
                                    onListboxOpenChange={(isOpen) => setCodLocSelectOpen(isOpen)}
                                    onChange={(event, newValue) => setLocalEstoqueSelecionado(newValue)} >
                                    { locaisDeEstoque.map(function (localEstoque, indice) {
                                    return (
                                        <Option key={indice} value={localEstoque.codigo}>{localEstoque.nome}</Option>
                                    );
                                    })}
                                </Select>
                            </Stack>

                            <Stack mt={mobile ? "10px" : "0px"}>
                                <Typography level="body-sm">Criadas a partir desta data:</Typography>
                                <Input type="date" value={dataInicio} onChange={(event) => setDataInicio(event.target.value)} />
                            </Stack>
                            <Stack ml={mobile ? "0px" : "30px"} mt={mobile ? "10px" : "0px"} mr={mobile ? "0px" : "30px"}>
                                <Typography level="body-sm">Até esta data:</Typography>
                                <Input type="date" value={dataTermino} onChange={(event) => setDataTermino(event.target.value)} />
                            </Stack>
                            {/* <Stack ml={mobile ? "0px" : "30px"} mt={mobile ? "10px" : "0px"} mb={mobile ? "20px" : "0px"}>
                                <Typography level="body-sm">Status</Typography>
                                <Select fontSize={12} value={status} onChange={(event, newValue) => setStatus(newValue)} placeholder="Status da Solicitação">
                                    <Option value={1}>Em Andamento</Option>
                                    <Option value={2}>Cancelado</Option>
                                    <Option value={3}>Concluído</Option>
                                </Select>
                            </Stack> */}
                            {/* <Stack ml={mobile ? "0px" : "30px"} mb={mobile ? "40px" : "0px"}>
                                <Typography level="body-sm">Ver todos</Typography>
                                <Stack alignSelf={"flex-start"} justifyContent="center" flex={mobile ? null : 1}>
                                    <Switch
                                        checked={verTodasCotacoes}
                                        onChange={(event) => setVerTodasCotacoes(event.target.checked)}
                                    />
                                </Stack>
                            </Stack> */}

                        </Stack>
                        <Stack mt={mobile ? "10px" : "20px"} flexDirection={"row"} justifyContent={mobile ? "space-between" : "flex-end"} width={mobile ? "100%" : "auto"}>
                            <Button onClick={() => buscarNotas()} color="neutral">ATUALIZAR</Button>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack mt="30px">
                    {mobile ?
                        <Stack>
                            {objetos.map(function (item, indice) {


                                return (
                                    <div className="hover">
                                        <Stack key={indice} onClick={() => navigate("/aprovacaoCompras/" + item.numeroMov6, { state: { cotacao: item.objetoCotacao } })} p={"10px"} boxShadow="md" borderRadius={5} mb={"20px"} sx={{ userSelect: "none" }} style={{ backgroundColor: corDaLinha }}>
                                            <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">N° Nota: </Typography>{item.numeroNota}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">CNPJ: </Typography>{item.cnpjNota}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Fornecedor: </Typography>{item.fornecedor}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Data NF: </Typography>{item.dataNota}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Cotação: </Typography>{item.codCotacao}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Comprador: </Typography>{item.comprador}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Nº OC: </Typography>{item.numeroMov8}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Cód. TMV: </Typography>{item.codtmv}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Data Entrega: </Typography>{item.dataEntrega}</Typography>
                                        </Stack>
                                    </div>
                                )
                            })}
                        </Stack>
                        :
                        <Stack>
                            <Table hoverRow noWrap>
                                <thead>
                                    <tr>
                                        <th style={{ height: "25px", width: "120px" }}>N° Nota</th>
                                        <th style={{ height: "25px", width: "180px" }}>CNPJ</th>
                                        <th style={{ height: "25px" }}>Fornecedor</th>
                                        <th style={{ height: "25px", width: "120px" }}>Data Nota</th>
                                        <th style={{ height: "25px", width: "120px" }}>Cotação</th>
                                        <th style={{ height: "25px" }}>Comprador</th>
                                        <th style={{ height: "25px", width: "100px" }}>N° OC</th>
                                        <th style={{ height: "25px", width: "100px" }}>Cód. TMV</th>
                                        <th style={{ height: "25px", width: "100px" }}><Link onClick={()=>handleOrdenaDataEntrega()}>Data Entrega {ordenaDataEntrega != null ? ordenaDataEntrega ?<FaArrowDown size={10} style={{marginLeft:"2px"}}/>:<FaArrowUp size={10} style={{marginLeft:"2px"}}/>: ""} </Link></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {objetos.map(function (item, indice) {
 

                                        return (
                                            <tr key={indice} className="hover" onClick={() => navigate("/aprovacaoCompras/" + item.numeroMov6, { state: { cotacao: item.objetoCotacao } })} style={{ backgroundColor: corDaLinha }} >
                                                <td style={{ padding: "0 10px" }}>{item.numeroNota}</td>
                                                <td style={{ padding: "0 10px" }}>{item.cnpjNota}</td>
                                                <td style={{ padding: "0 10px" }}>{item.fornecedor}</td>
                                                <td style={{ padding: "0 10px" }}>{item.dataNota}</td>
                                                <td style={{ padding: "0 10px" }}>{item.codCotacao}</td>
                                                <td style={{ padding: "0 10px" }}>{item.comprador}</td>
                                                <td style={{ padding: "0 10px" }}>{item.numeroMov8}</td>
                                                <td style={{ padding: "0 10px" }}>{item.codtmv}</td>
                                                <td style={{ padding: "0 10px" }}>{item.dataEntrega}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Stack>
                    }
                    <Stack mt={3} alignItems={mobile ? "center" : "flex-end"}>
                        <Pagination
                            onChange={alterarPagina}
                            count={paginas.numeroPaginas}
                            page={paginas.paginaAtual}
                            hideNextButton={paginas.paginaAtual == paginas.numeroPaginas}
                            hidePrevButton={paginas.paginaAtual == 1}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    )

}