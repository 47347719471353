import {
    Modal,
    ModalDialog,
    ModalClose,
    Typography,
    Stack,
    List,
    ListItem,
    Link
} from "@mui/joy"

export default function SelecaoFornecedor(props) {
    return (
        <Modal open={props.openModalListaFornecedor} onClose={() => props.setOpenModalListaFornecedor(false)}>
            <ModalDialog variant={"plain"} >
                <ModalClose />
                <Typography
                    id="basic-list-demo"
                    level="body-xs"
                    textTransform="uppercase"
                    fontWeight="lg"
                    fontSize={"18px"}
                >
                    Escolha um Fornecedor
                </Typography>

                <Stack marginTop={"20px"} sx={{ overflow: "auto", height: "50%" }}>
                    <List>
                        {props.fornecedoresResultBusca.map(function (item) {
                            return (
                                // TODO: pegar o resultado daqui e juntar numa lista de nomes fornecedores:
                                <ListItem sx={{ borderBottom: 1, borderColor: "#ddd", marginBottom: "5px" }} onClick={() => [props.addVendorList(item), props.setOpenModalListaFornecedor(false)]}>
                                    <Link fontSize={"16px"}>{item.CNPJ} - {item.NOMEFANTASIA}</Link>
                                </ListItem>
                            )
                        })}
                    </List>
                </Stack>
            </ModalDialog>
        </Modal>
    )
}