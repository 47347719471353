import { Button } from "@mui/joy";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material";

export default function ConfirmarExclusao(props) {
    return (
        <Dialog
            open={props.dialogExclusao}
            onClose={() => props.setDialogExclusao(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                ATENÇÃO
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.mensagem}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="neutral" onClick={() => props.setDialogExclusao(false)}>Cancelar</Button>
                <Button color="danger" onClick={() => props.excluirItem(props.indiceItemConsultado)} autoFocus>
                    Excluir
                </Button>
            </DialogActions>
        </Dialog>
    )
}