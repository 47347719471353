import { AspectRatio, Button, Card, CardContent, FormHelperText, IconButton, Stack, Typography } from "@mui/joy";
//Roteamento
import { useNavigate } from "react-router-dom";

import logo from "../assets/logo.png";
import centralRequisicoes from "../assets/centralRequisições.png";
import centralCompras from "../assets/centralCompras.png";
import centralDiligenciamento from "../assets/centralDiligenciamento.png";
import almox from "../assets/almox.png";
import mapaSuprimentos from "../assets/mapaDeSuprimentos.png";
import background from "../assets/background.jpeg";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';

import "../App.css";

export default function () {
    const navigate = useNavigate();

    const permissoes = JSON.parse(localStorage.getItem('usuario')).user.autenticacao;

    //Verificando se o dispositivo é mobile
    const mobile = useMediaQuery('(max-width:1200px)');
    const backgroundSize = mobile ? "contain" : "cover";

    return (
        <Stack padding={"40px"} height={mobile ? "100%" : "100vh"} style={{ backgroundImage: `url(${background})`, backgroundSize: backgroundSize, backgroundRepeat: "repeat-y" }}>
            <Stack alignItems={"center"} mb="30px">
                <img width={mobile ? "80" : "120"} src={logo} />
                <FormHelperText sx={{ marginTop: "10px" }}>Escolha o módulo que deseja acessar</FormHelperText>
            </Stack>

            {permissoes.includes("diligenciamentos") &&
                <Stack alignItems={"center"} mb={"20px"}>
                    <Card color="neutral" variant="outlined" onClick={() => navigate("/diligenciamento")} className="hover" sx={mobile ? { width: "100%", marginBottom: "30px" } : { width: 960, }}>
                        {/* {mobile ? null : */}
                        <Stack flex={1}>
                            <Typography level="body-sm" alignSelf={"center"} mb={"15px"}>Sistema de acompanhamento de aprovações.</Typography>
                            <img
                                src={mapaSuprimentos}
                                // loading="lazy"
                                alt=""
                                style={mobile ?
                                    { width: "auto", height: "auto", boxShadow: "0 2px 10px 0px #ddd", borderRadius: "10px" } :
                                    { width: "auto", height: "auto", boxShadow: "0 2px 10px 0px #ddd", borderRadius: "20px" }}
                            />
                        </Stack>
                        {/* } */}
                    </Card>
                </Stack>
            }

            <Stack width={mobile ? "100%" : 990} alignSelf={"center"} flexDirection={mobile ? "column" : "row"} justifyContent="space-evenly" alignItems="center">
                {permissoes.includes("requisicoes") &&
                    <Card color="neutral" variant="outlined" onClick={() => navigate("/centralTarefas")} className="hover" sx={mobile ? { width: "95%", marginBottom: "30px" } : { width: 200 }}>
                        <Stack height={mobile ? "auto" : "90px"}>
                            <Typography level="title-lg">Central de Requisições</Typography>
                            <Typography level="body-sm">Sistema e aprovações de requisições.</Typography>
                        </Stack>
                        <Stack flex={1}>
                            <img
                                src={centralRequisicoes}
                                // loading="lazy"
                                alt=""
                                style={mobile ?
                                    { width: "200px", height: "200px", alignSelf: "center", boxShadow: "0 2px 10px 0px #ddd", borderRadius: "30px" } :
                                    { width: "auto", height: "auto", boxShadow: "0 2px 10px 0px #ddd", borderRadius: "30px" }
                                }
                            />
                        </Stack>
                    </Card>
                }

                {permissoes.includes("compras") &&
                    <Card color="neutral" variant="outlined" onClick={() => navigate("/compras")} className="hover" sx={mobile ? { width: "95%", marginBottom: "30px" } : { width: 200 }}>
                        <Stack height={mobile ? "auto" : "90px"}>
                            <Typography level="title-lg">Central de Compras</Typography>
                            <Typography level="body-sm">Sistema e aprovação de compras e contratos.</Typography>
                        </Stack>
                        <Stack flex={1}>
                            <img
                                src={centralCompras}
                                // loading="lazy"
                                alt=""
                                style={mobile ?
                                    { width: "200px", height: "200px", alignSelf: "center", boxShadow: "0 2px 10px 0px #ddd", borderRadius: "30px" } :
                                    { width: "auto", height: "auto", boxShadow: "0 2px 10px 0px #ddd", borderRadius: "30px" }
                                }
                            />
                        </Stack>
                    </Card>
                }

                {permissoes.includes("followups") &&
                    <Card color="neutral" variant="outlined" onClick={() => navigate("/followUp")} className="hover" sx={mobile ? { width: "95%", marginBottom: "30px" } : { width: 200 }}>
                        <Stack height={mobile ? "auto" : "90px"}>
                            <Typography level="title-lg">Central de Diligenciamento</Typography>
                            <Typography level="body-sm">Acompanhamento de processos e entregas.</Typography>
                        </Stack>
                        <Stack flex={1}>
                            <img
                                src={centralDiligenciamento}
                                // loading="lazy"
                                alt=""
                                style={mobile ?
                                    { width: "200px", height: "200px", alignSelf: "center", boxShadow: "0 2px 10px 0px #ddd", borderRadius: "30px" } :
                                    { width: "auto", height: "auto", boxShadow: "0 2px 10px 0px #ddd", borderRadius: "30px" }
                                }
                            />
                        </Stack>
                    </Card>
                }

                {permissoes.includes("almox") &&
                    <Card color="neutral" variant="outlined" onClick={() => window.open('https://almox.pelicano.eng.br')} className="hover" sx={mobile ? { width: "95%", marginBottom: "30px" } : { width: 200 }}>
                        <Stack height={mobile ? "auto" : "90px"}>
                            <Typography level="title-lg">Almox</Typography>
                            <Typography level="body-sm">Sistema de baixas e transferencia de responsável.</Typography>
                        </Stack>
                        <Stack flex={1}>
                            <img
                                src={almox}
                                // loading="lazy"
                                alt=""
                                style={mobile ?
                                    { width: "200px", height: "200px", alignSelf: "center", boxShadow: "0 2px 10px 0px #ddd", borderRadius: "30px" } :
                                    { width: "auto", height: "auto", boxShadow: "0 2px 10px 0px #ddd", borderRadius: "30px" }
                                }
                            />
                        </Stack>
                    </Card>
                }
            </Stack>
        </Stack>
    )
}