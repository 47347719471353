import { useState, useEffect } from "react";

import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


//componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import Legenda from "../../../../components/Legenda";
//Mui
import { Input, Select, Stack, Typography, Option, Button, Table, Switch, Link } from "@mui/joy";
//Utils
import { dataAtual, formatarData, formatarDataBrasil } from "../../../../utils/datas";
import { api } from "../../../../services/api";
//Material
import { Pagination } from '@mui/material/';
import useMediaQuery from '@mui/material/useMediaQuery';

const dadosUsuarioLogado = {};
let idUsuario = 0;
let token = "";

//Dados do usuário logado no localstorage
const usuarioLogado = JSON.parse(localStorage.getItem('usuario'));

export default function CentralTransferenciaMaterial(props) {

    const navigate = useNavigate();
    //Verificando se o dispositivo é mobile
    const mobile = useMediaQuery('(max-width:1200px)');
    //filtros:
    const [paginas, setPaginas] = useState({
        paginaAtual: 1,
        numeroPaginas: 1
    });
    const [dataInicio, setDataInicio] = useState(dataAtual(-2));
    const [dataTermino, setDataTermino] = useState(dataAtual());
    const [itens, setItens] = useState([]);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            dadosUsuarioLogado.nome = usuarioLogado.user.nome;
            dadosUsuarioLogado.usuario = usuarioLogado.user.login;
            dadosUsuarioLogado.obra = usuarioLogado.user.obra;
            dadosUsuarioLogado.grupo = usuarioLogado.grupo;

            token = JSON.parse(localStorage.getItem('usuario')).token;
            idUsuario = JSON.parse(localStorage.getItem('usuario')).user.id;

        }
        buscarItens();

    }, []);


    const buscarItens = (pagina = 1) => {
        //TODO: trazer lista de processos de transferência:
        paginas.paginaAtual = pagina;
        let urlBuscarListagem = "/transferenciaMateriais?page=" + pagina;
        api.get(urlBuscarListagem, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            if (response.data) {
                setItens(response.data.data);
                paginas.numeroPaginas = response.data.meta.last_page;
            }
        })
        .catch((error) => {
            console.log(error);
            if (error.response.data) {
                if (error.response.data.message == "Unauthenticated.") {
                    localStorage.setItem("usuario", null);
                    navigate("/acessoNegado");
                } else {
                    toast.error("Ocorreu um erro ao obter a lista de itens");
                    console.log(error.response.data.message);
                }
            }
            toast.error(JSON.stringify(error.response.data));
        });
    }

    const alterarPagina = (event, value) => {
        paginas.paginaAtual = value;
        buscarItens(value);
    }

    return(
        <Stack backgroundColor="#F5F5F5" height="100%" minHeight={"100vh"}>
            <Header notification mobile={mobile} />
            <Sidebar mobile={mobile} />

            <Stack ml={mobile ? "0px" : "280px"} mt={"50px"} flex={1} height={mobile ? "100%" : "calc(100% - 55px)"} p={mobile ? "20px" : "50px"} overflow="auto">
                {/* Conteúdo da Tela */}
                <Typography level="h4">Consulta de Transferências de Materiais</Typography>
                <Stack ml={mobile ? "0px" : "20px"}>
                    <Stack flexDirection={mobile ? "column" : "row"} mt={mobile ? "20px" : "30px"} alignItems={mobile ? "flex-start" : "center"} justifyContent={"space-between"}>
                        <Stack width="100%" flexDirection={mobile ? "column" : "row"}>
                            <Stack mt={mobile ? "10px" : "0px"}>
                                <Typography level="body-sm">Criadas a partir desta data:</Typography>
                                <Input type="date" value={dataInicio} onChange={(event) => setDataInicio(event.target.value)} />
                            </Stack>
                            <Stack ml={mobile ? "0px" : "30px"} mt={mobile ? "10px" : "0px"} mr={mobile ? "0px" : "30px"}>
                                <Typography level="body-sm">Até esta data:</Typography>
                                <Input type="date" value={dataTermino} onChange={(event) => setDataTermino(event.target.value)} />
                            </Stack>
                            {/* <Stack ml={mobile ? "0px" : "30px"} mt={mobile ? "10px" : "0px"} mb={mobile ? "20px" : "0px"}>
                                <Typography level="body-sm">Status</Typography>
                                <Select fontSize={12} value={status} onChange={(event, newValue) => setStatus(newValue)} placeholder="Status da Solicitação">
                                    <Option value={1}>Em Andamento</Option>
                                    <Option value={2}>Cancelado</Option>
                                    <Option value={3}>Concluído</Option>
                                </Select>
                            </Stack> */}
                            {/* <Stack ml={mobile ? "0px" : "30px"} mb={mobile ? "40px" : "0px"}>
                                <Typography level="body-sm">Ver todos</Typography>
                                <Stack alignSelf={"flex-start"} justifyContent="center" flex={mobile ? null : 1}>
                                    <Switch
                                        checked={verTodasCotacoes}
                                        onChange={(event) => setVerTodasCotacoes(event.target.checked)}
                                    />
                                </Stack>
                            </Stack> */}
                        </Stack>
                        <Stack mt={mobile ? "10px" : "20px"} flexDirection={"row"} justifyContent={mobile ? "space-between" : "flex-end"} width={mobile ? "100%" : "auto"}>
                            <Button onClick={() => buscarItens()} color="neutral">ATUALIZAR</Button>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack mt="30px">
                    {mobile ?
                        <Stack>
                            {itens.map(function (item, indice) {
                                return (
                                    <div className="hover">
                                        <Stack key={indice} onClick={() => navigate("/aprovacaoTransferencia/" + item.id)} p={"10px"} boxShadow="md" borderRadius={5} mb={"20px"} sx={{ userSelect: "none" }} style={{ backgroundColor: "" }}>
                                            <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Solicitação: </Typography>{item.solicitacao_id}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Solicitante: </Typography>{item.user.nome}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Filial Origem: </Typography>{item.filial_origem.nome}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Local Origem: </Typography>{item.localEstoqueOrigem}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Filial Destino: </Typography>{item.filial_destino.nome}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Local Destino: </Typography>{item.localEstoqueDestino}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Série: </Typography>{item.serie}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">N° Movimento: </Typography>{item.numeroMovimento}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Data Emissão: </Typography>{formatarDataBrasil(item.DataEmissao)}</Typography>
                                        </Stack>
                                    </div>
                                )
                            })}
                        </Stack>
                        :
                        <Stack>
                            <Table hoverRow noWrap>
                                <thead>
                                    <tr>
                                        <th style={{ height: "25px", width: "120px" }}>ID Solicitação</th>
                                        <th style={{ height: "25px", width: "180px" }}>Solicitante</th>
                                        <th style={{ height: "25px" }}>Filial Origem</th>
                                        <th style={{ height: "25px", width: "120px" }}>Local Origem</th>
                                        <th style={{ height: "25px", }}>Filial Destino</th>
                                        <th style={{ height: "25px", width: "120px" }}>Local Destino</th>
                                        <th style={{ height: "25px", width: "100px" }}>Série</th>
                                        <th style={{ height: "25px", width: "100px" }}>Nº Movimento</th>
                                        <th style={{ height: "25px", width: "100px" }}>Data Emissão</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {itens.map(function (item, indice) {
                                        return (
                                            <tr key={indice} className="hover" onClick={() => navigate("/aprovacaoTransferencia/" + item.id)}>
                                                <td style={{ padding: "0 10px" }}>{item.solicitacao_id}</td>
                                                <td style={{ padding: "0 10px" }}>{item.user.nome}</td>
                                                <td style={{ padding: "0 10px" }}>{item.filial_origem.nome}</td>
                                                <td style={{ padding: "0 10px" }}>{item.localEstoqueOrigem}</td>
                                                <td style={{ padding: "0 10px" }}>{item.filial_destino.nome}</td>
                                                <td style={{ padding: "0 10px" }}>{item.localEstoqueDestino}</td>
                                                <td style={{ padding: "0 10px" }}>{item.serie}</td>
                                                <td style={{ padding: "0 10px" }}>{item.numeroMovimento}</td>
                                                <td style={{ padding: "0 10px" }}>{formatarDataBrasil(item.DataEmissao)}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Stack>
                    }
                    <Stack mt={3} alignItems={mobile ? "center" : "flex-end"}>
                        <Pagination
                            onChange={alterarPagina}
                            count={paginas.numeroPaginas}
                            page={paginas.paginaAtual}
                            hideNextButton={paginas.paginaAtual == paginas.numeroPaginas}
                            hidePrevButton={paginas.paginaAtual == 1}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );

}