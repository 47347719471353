import { useState, useEffect } from "react";
import { Stack, Typography, Card, CardContent, Button, Input, FormLabel, Link, 
         FormControl, Modal, ModalClose, Sheet, FormHelperText } from "@mui/joy";
//stack like a div
import Pelican from "../../../../assets/logoPelicano.png";

//Roteamento
import { useLocation, useParams } from "react-router-dom";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';

import { api } from "../../../../services/api";
import { toast } from 'react-toastify';


export default function RecuperarSenha(){

    const [senha, setSenha] = useState('');
    const [confirmacaoSenha, setConfirmacaoSenha] = useState('');
    const location = useLocation();
    const acessoNegado = location.pathname == "/acessoNegado";
    const mobile = useMediaQuery('(max-width:1200px)');
    const { id_param } = useParams(); //id do codigo de acesso
    const [token, setToken] = useState();
    const [email, setEmail] = useState();

    useEffect(() => {
        if (id_param) {
            setToken(id_param);
        }
      }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (senha.length < 6) {
            toast.warning("Sua senha deve ter no mínimo 6 caracteres");
            return;
        } else {
            if (senha != confirmacaoSenha) {
                toast.warning("Sua senha e confirmação de senha estão divergentes. Redigite, por favor.");
                return;
            }
        }

        api.post('/newPassword/', { token: token, password: senha, password_confirmation: confirmacaoSenha })
            .then(function (response) {
                if (response.data) {
                    toast.success("Senha alterada com sucesso! Tente logar com sua nova senha.");
                    setTimeout(() => {
                        window.location.href = "/";
                    }, 2000);
                }
                else {
                    toast.error("Não foi possível realizar a troca de senha, consulte o log");
                    console.error(response);
                }
            }).catch(function (response) {
                if (response.response) {
                    if (response.response.data.message == "Não Autorizado") {
                        toast.warning("Acesso não autorizado");
                    }
                    else {
                        toast.warning(response.response.data.message);
                    }
                }
                else {
                    toast.error("Sem conexão com o servidor");
                }
            });
    }

    return (
        <Stack backgroundColor="#F5F5F5" justifyContent={"center"} height="100vh">
            <Stack style={{ backgroundColor: "" }} alignItems="center" marginX={mobile ? "" : "auto"} p={mobile ? "30px" : "50px"}>

                <Stack m={"auto"} width={mobile ? "200px" : "300px"} height={mobile ? "150px" : "200px"}>
                    <img
                        width={"100%"}
                        height={"auto"}
                        alt=""
                        src={Pelican} />
                </Stack>

                <Typography level="title-lg" m={"auto"} mb={"20px"}>SUPRIMENTOS</Typography>

                {/* Conteúdo da Tela */}
                <Card variant="outlined" sx={[mobile ? { width: "90%", maxWidth: "500px", minWidth: "250px" } : { width: 480 }, { marginBottom: "100px" }]}>
                    <form onSubmit={handleSubmit}>
                        <CardContent orientation="vertical">
                            <FormControl sx={{ mb: "10px" }}>
                                <FormLabel style={{ fontWeight: "bold" }}>Informe a nova senha</FormLabel>
                                <Input placeholder="Senha" type="password" value={senha} onChange={(e) => setSenha(e.target.value)} />

                                {/* {erro.senhaInvalida && (
                                    <FormHelperText error>
                                        Informe a senha.
                                    </FormHelperText>
                                )} */}
                            </FormControl>

                            <FormControl sx={{ mb: "10px" }}>
                                <FormLabel style={{ fontWeight: "bold" }}>redigite a nova senha</FormLabel>
                                <Input placeholder="Confirmar Senha" type="password" value={confirmacaoSenha} onChange={(e) => setConfirmacaoSenha(e.target.value)} />

                                {/* {erro.senhaInvalida && (
                                    <FormHelperText error>
                                        Informe a senha.
                                    </FormHelperText>
                                )} */}
                            </FormControl>

                            <Button
                                onClick={handleSubmit}
                                type="submit"
                                variant="solid"
                                size="md"
                                color="primary"
                                aria-label="Enviar"
                                sx={{ m: 'auto', mb: '20px', alignSelf: 'center', fontWeight: 600 }} >
                                Alterar Senha
                            </Button>
                        </CardContent>
                    </form>
                </Card>
            </Stack>
        </Stack>
    );

}