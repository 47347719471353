import { toast } from 'react-toastify';
import { useState, useEffect } from "react";
import { Modal, Sheet, ModalClose, Typography, Stack, Input, Select, Option, Button, Autocomplete } from "@mui/joy";
import { api } from '../../services/api';
import MultipleSelect from 'react-select'

//Utils
import { obterTodasEtapas } from '../../utils/etapasDoProcesso';

let token = "";

export default function UsuariosGrupo(props) {

    const [formulario, setFormulario] = useState({ usuario: null, perfil: "", tipoPedido: [], subgrupo: "", valorMinimo: 0, valorMaximo: 0, valor_limit: 0 });
    const [opcoesFormulario, setOpcoesFormulario] = useState({ nomes: [], perfis: [] });
    const [tiposPedido, setTiposPedido] = useState([]);

    useEffect(() => {
        token = JSON.parse(localStorage.getItem('usuario')).token;
        listaUsuarios();
        obtendoPerfis();
        listaLeadTime();

        if (props.usuarioGrupoSelecionado != null) {
            carregaUsuarioGrupoSelecionado(props.usuarioGrupoSelecionado);
        }
    }, [props.usuarioGrupoSelecionado, props.novoUsuarioModal]);

    const carregaUsuarioGrupoSelecionado = (objeto) => {

        //Preparando o tipoPedido do backend para o frontend
        let arrayTipoPedido = [objeto.tipoPedido];
        if (objeto.tipoPedido.includes(',')) {
            arrayTipoPedido = objeto.tipoPedido.split(',');
        }

        //Transformando o array de códigos em um array de objetos para exibir no select
        let arrayDeObjetosTipoPedido = [];
        console.log(tiposPedido);
        arrayTipoPedido.forEach(tipo => {
            arrayDeObjetosTipoPedido.push(tiposPedido.find(tipos => tipos.value == tipo));
        });

        setFormulario({
            id: objeto.id,
            usuario: objeto.usuarios,
            perfil: objeto.perfil,
            tipoPedido: arrayDeObjetosTipoPedido,
            subgrupo: objeto.subGrupo,
            valorMinimo: objeto.valorMin,
            valorMaximo: objeto.valorMax,
            valor_limit: objeto.valor_limit
        });
    }

    const listaUsuarios = (page = 1) => {
        api.get("/usuariosRelac/?paginacao=false")
            .then((response) => {
                if (response.data.data) {
                    const usuariosDoBanco = response.data.data;
                    let usuarios = [];
                    usuariosDoBanco.forEach(usuario => {
                        usuarios.push(
                            {
                                id: usuario.id,
                                nome: usuario.nome
                            }
                        )
                    });

                    setOpcoesFormulario(prevState => ({
                        ...prevState,
                        nomes: usuarios,
                    }));
                }
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro : " + err);
                toast.error(JSON.stringify(err.response.data));
            });
    }

    const obtendoPerfis = async () => {

        const etapas = await obterTodasEtapas();
        let perfis = [];
        etapas.forEach(etapa => {
            // if ((etapa.label != "Comprador")) {
            perfis.push(etapa.label);
            // }
        });

        setOpcoesFormulario(prevState => ({
            ...prevState,
            perfis: perfis,
        }));
    }

    const handleInputChange = (event, name, newValue) => {
        if (newValue) {
            setFormulario(prevState => ({
                ...prevState,
                [name]: newValue,
            }));

            //Alterando subgrupo conforme seleção do tipo do pedido
            if (newValue == "001" || newValue == "002" || newValue == "003" || newValue == "004" || newValue == "008") {
                setFormulario(prevState => ({
                    ...prevState,
                    subgrupo: "Materiais e Serviços",
                }));
            }
            else if (newValue == "005" || newValue == "006" || newValue == "007") {
                setFormulario(prevState => ({
                    ...prevState,
                    subgrupo: "Manutenção de Equipamentos",
                }));
            }

            return;
        }
        else if (event) {
            setFormulario(prevState => ({
                ...prevState,
                [name]: event.target.value || event.target.checked,
            }));
            return;
        }

    }

    const adicionaUsuarioGrupo = () => {

        const token = JSON.parse(localStorage.getItem('usuario')).token;

        //Preparando tipoPedido para o backend
        let tiposPedidosString = "";
        formulario.tipoPedido.forEach((tipo, indice) => {
            tiposPedidosString = indice == 0 ? tipo.value : tiposPedidosString + "," + (tipo.value);
        });

        const data = {
            grupo_id: props.grupo.id,
            usuario_id: formulario.usuario.id,
            perfil: formulario.perfil,
            tipoPedido: tiposPedidosString,
            subGrupo: formulario.subgrupo,
            valorMin: formulario.valorMinimo,
            valorMax: formulario.valorMaximo,
            valor_limit: formulario.valor_limit
        }

        let id_toast = toast.loading("Salvando...", { toastId: "toast" });

        api.post(`/usuariosSalvar`,
            data,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }
        ).then(function (response) {
            toast.update(id_toast, { render: "Usuário adicionado ao grupo com sucesso!", type: "success", isLoading: false, autoClose: 1000 });
            props.setOpenModal(false);
            //Atualizando a listagem dos usuários do grupo
            props.listaGrupoUsuariosRelac();
            limpaFormulario();
        }).catch(function (err) {
            toast.update(id_toast, { render: "Erro ao adicionar Usuário ao grupo, consulte o log.", type: "error", isLoading: false, autoClose: 2000 });
            console.error(err);
            toast.error(JSON.stringify(err.response.data));
        })
    }

    const editaUsuarioGrupo = () => {

        const token = JSON.parse(localStorage.getItem('usuario')).token;

        //Preparando tipoPedido para o backend
        let tiposPedidosString = "";
        formulario.tipoPedido.forEach((tipo, indice) => {
            tiposPedidosString = indice == 0 ? tipo.value : tiposPedidosString + "," + (tipo.value);
        });

        const data = {
            grupo_id: props.grupo.id,
            usuario_id: formulario.usuario.id,
            perfil: formulario.perfil,
            tipoPedido: tiposPedidosString,
            subGrupo: formulario.subgrupo,
            valorMin: formulario.valorMinimo,
            valorMax: formulario.valorMaximo,
            valor_limit: formulario.valor_limit
        }

        let id_toast = toast.loading("Atualizando...", { toastId: "toast" });

        api.put(`/usuariosRelac/` + formulario.id,
            data,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            }
        ).then(function (response) {
            toast.update(id_toast, { render: "Regra de usuário no grupo atualizado com sucesso!", type: "success", isLoading: false, autoClose: 2000 });
            props.setOpenModal(false);
            //Atualizando a listagem dos usuários do grupo
            props.listaGrupoUsuariosRelac();
            limpaFormulario();
        }).catch(function (err) {
            toast.update(id_toast, { render: "Erro ao atualiza regra de usuário no grupo, consulte o log.", type: "error", isLoading: false, autoClose: 2000 });
            console.error(err);
            toast.error(JSON.stringify(err.response.data));
        })
    }

    const limpaFormulario = () => {
        setFormulario({ usuario: null, perfil: "", tipoPedido: "", subgrupo: "", valorMinimo: 0, valorMaximo: 0, valor_limit: 0 });
    }

    const excluiUsuarioGrupo = (id) => {

        const confirmar_exclusao = window.confirm("Deseja excluir este registro?");

        if (confirmar_exclusao) {

            const token = JSON.parse(localStorage.getItem('usuario')).token;
            let id_toast = toast.loading("Atualizando...", { toastId: "toast" });

            api.delete(`/usuariosDeletar/` + id,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                    }
                }
            ).then(function (response) {
                toast.update(id_toast, { render: "Regra de usuário no grupo excluída com sucesso!", type: "success", isLoading: false, autoClose: 1000 });
                props.setOpenModal(false);
                //Atualizando a listagem dos usuários do grupo
                props.listaGrupoUsuariosRelac();
                limpaFormulario();
            }).catch(function (err) {
                toast.update(id_toast, { render: "Erro ao excluir regra de usuário no grupo, consulte o log.", type: "error", isLoading: false, autoClose: 2000 });
                console.error(err);
                toast.error(JSON.stringify(err.response.data));
            })
        }
    }

    const handleChangeSelectedNames = (e, newValue) => {

        // Verifique se o pedido é nulo, indefinido ou uma string vazia
        const tiposPedidos =
            formulario.pedido ? `${formulario.tipoPedido},${newValue}` : newValue;

        // Atualize o estado usando spread para manter as propriedades existentes
        setFormulario((prevFormulario) => ({
            ...prevFormulario,
            tipoPedido: tiposPedidos,
        }));
    };

    const listaLeadTime = (page = 1) => {
        //TODO: Chamando api de leadTime:
        api.get("/tiposPedido/?page=" + page,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
        )
            .then(({ data }) => {
                let tiposPedidoFormatados = [];
                data.forEach(tp => {
                    tiposPedidoFormatados.push({
                        value: tp.tipoPedido,
                        label: tp.descricao
                    });
                });
                setTiposPedido(tiposPedidoFormatados);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro : " + err);
                toast.error(JSON.stringify(err.response.data));
            });
    }

    return (
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={props.open}
            onClose={() => [limpaFormulario(), props.setOpenModal(false)]}

            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                outline: "none",
            }}
        >
            <Sheet
                variant="outlined"
                sx={{
                    width: "80%",
                    maxWidth: props.mobile ? "100%" : "500px",
                    borderRadius: 'md',
                    p: props.mobile ? 2 : 3,
                    boxShadow: 'lg',
                    outline: "none",
                }}
            >
                <ModalClose
                    variant="outlined"
                    sx={{
                        top: "calc(-1/4 * var(--IconButton-size))",
                        right: "calc(-1/4 * var(--IconButton-size))",
                        boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                        borderRadius: "50%",
                        bgcolor: "background.surface",
                    }}
                />
                <Typography
                    component="h2"
                    id="modal-title"
                    level="h4"
                    textColor="inherit"
                    fontWeight="lg"
                    mb={1}
                >
                    Dados do Usuário
                </Typography>

                <Stack display={"flex"} sx={props.mobile ? { overflow: "auto", height: "70vh" } : {}}>
                    <Stack mt="20px">
                        <Typography fontSize="14px">Nome</Typography>
                        <Autocomplete
                            value={formulario.usuario}
                            onChange={(e, newValue) => handleInputChange(e, "usuario", newValue)}
                            placeholder="Nome do usuário"
                            options={opcoesFormulario.nomes}
                            getOptionLabel={option => option.nome}
                        />
                    </Stack>
                    <Stack mt="20px">
                        <Typography fontSize="14px">Perfil</Typography>
                        <Select value={formulario.perfil} onChange={(e, newValue) => handleInputChange(e, "perfil", newValue)}>
                            {opcoesFormulario.perfis.map((perfil, indice) => {
                                return (
                                    <Option key={indice} value={perfil}>{perfil}</Option>
                                )
                            })}
                        </Select>
                    </Stack>
                    <Stack mt="20px">
                        <Typography fontSize="14px">Tipo do Pedido</Typography>
                        <MultipleSelect
                            value={formulario.tipoPedido}
                            onChange={(e) => setFormulario(prevState => ({ ...prevState, tipoPedido: e }))}
                            placeholder=""
                            isMulti
                            options={tiposPedido}
                            noOptionsMessage={() => "Sem opções"}
                        />
                    </Stack>
                    {/* <Stack mt="20px">
                        <Typography fontSize="14px">Subgrupo do Pedido</Typography>
                        <Input value={formulario.subgrupo} onChange={(e) => "subgrupo"} sx={{ backgroundColor: "#eee" }} disabled />
                    </Stack> */}
                    <Stack mt="20px">
                        <Typography fontSize="14px">Valor Mínimo (R$)</Typography>
                        <Input value={formulario.valorMinimo} onChange={(e) => handleInputChange(e, "valorMinimo")} type={"number"} />
                    </Stack>
                    <Stack mt="20px">
                        <Typography fontSize="14px">Valor Máximo (R$)</Typography>
                        <Input type={"number"} value={formulario.valorMaximo} onChange={(e) => handleInputChange(e, "valorMaximo")} />
                    </Stack>
                    <Stack mt="20px">
                        <Typography fontSize="14px">Limite Mensal de Compras (R$)</Typography>
                        <Input type={"number"} value={formulario.valor_limit} onChange={(e) => handleInputChange(e, "valor_limit")} />
                    </Stack>
                </Stack>
                <Stack mt="30px" flexDirection={"row"} justifyContent="space-between" alignItems="center">
                    <Button color="danger" onClick={() => excluiUsuarioGrupo(formulario.id)}>Excluir</Button>
                    {/* <Button color="neutral">Duplicar</Button> */}
                    <Button onClick={props.novoUsuarioModal ? adicionaUsuarioGrupo : editaUsuarioGrupo} >Salvar</Button>
                </Stack>
            </Sheet>
        </Modal>
    );
}