import axios from "axios";
import { useState, useEffect } from "react";
//Componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import InformacoesItem from "../../../../components/InformacoesItem";
import Anexos from "../../../../components/Anexos";
import SelecaoItem from "../../../../components/SelecaoItem";
import ConfirmarExclusao from "../../../../components/ConfirmarExclusao";
//Utils
import { obterProximaEtapa } from "../../../../utils/etapasDoProcesso";
import { dataAtual, formatarDataHoraBrasil } from "../../../../utils/datas";
import { formatarMoeda } from "../../../../utils/moedas";
import { definirProximaEtapa, obterEtapasAnteriores, obterWorkflowEtapaAtual } from "../../../../utils/etapasDoProcesso";

import {
  Stack,
  Typography,
  Button,
  Select,
  Option,
  Input,
  FormLabel,
  Table,
  ToggleButtonGroup,
  Link,
  Checkbox,
  Textarea
} from "@mui/joy";

import { toast } from 'react-toastify';

//icones react
import { FaRegEdit, FaRegTrashAlt, FaDownload, FaFolderOpen } from "react-icons/fa";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';

//Apis do RM
import { RM, api } from '../../../../services/api';

import { useNavigate, useLocation, useParams } from "react-router-dom";

import Autocomplete from '@mui/joy/Autocomplete';

let token = "";
let usuarioID = 0;
let usuarioLogin = "";


export default function ContratoOC(props) {

    document.addEventListener("keydown", function (event) {
        // Verifique se a tecla pressionada é Enter (código 13)
        if (event.keyCode === 13) {
          // Chame a sua função desejada aqui
          event.preventDefault();
        }
      });

    const navigate = useNavigate(); //navegacao entre components
    const { id_param } = useParams(); // id da cotação passada por url
    const location = useLocation(); //navegacao entre components
    const mobile = useMediaQuery('(max-width:1200px)'); //Telas menores que 1200px de largura serão considerados mobile

    const [valueTogglePagina, setValueTogglePagina] = useState("formulario"); //toggle que muda para aba formulario/anexo
    const [openModal, setOpenModal] = useState(false); //Modal de informar qtd a solicitar do item pedido
    const [openModalListaItem, setOpenModalListaItem] = useState(false); //Modal para exibir lista de itens quando pesquisar por descrição
    const [numeroProcesso, setNumeroProcesso] = useState("");
    const [anexos, setAnexos] = useState([])
    const [uploadAnexos, setUploadAnexos] = useState(new FormData());
    const [anexosSelecionados, setAnexosSelecionados] = useState(null); // guarda arquivos selecionados da aba anexos, para usuario nao perder os pré-selecinados antes de enviar
    const [decisaoAprovacao, setDecisaoAprovacao] = useState("");
    const [numeroOS, setNumeroOS] = useState("");
    const [osVinculada, setOsVinculada] = useState(false);
    const [indiceItemConsultado, setIndiceItemConsultado] = useState(0);
    const [nomeCodigoNovoItem, setNomeCodigoNovoItem] = useState("");
    const [itensEncontradosBusca, setItensEncontradosBusca] = useState([]);
    const [itemTemporario, setItemTemporario] = useState({});
    const [dialogExclusao, setDialogExclusao] = useState(false);
    const [atualizarEstados, setAtualizarEstados] = useState(false);
    const [etapaAtual, setEtapaAtual] = useState("");
    const [numeroPms, setNumeroPms] = useState("");
    const [permissaoAprovador, setPermissaoAprovador] = useState(false);
    const [informacoesGerais, setInformacoesGerais] = useState({ coligadas: [], filiais: [], locaisDeEstoque: [], centrosDeCusto: [] })
    const [etapasAnteriores, setEtapasAnteriores] = useState([]);
    const [etapaARetornar, setEtapaARetornar] = useState("");
    //Campos cotação:
    const [numeroContrato, setNumeroContrato] = useState();
    const [contrato, setContrato] = useState(
      {
        id: 2,
        solicitacao_id: 2,
        contratoFornecedor: "sdasd",
        contratoMov22: "asdas",
        contratoMov08: "asda",
        contratoMovNota: "asdas",
        contratoCodloc: "asd",
        contratoFilial: "asd",
        contratoDataEntrega: "2024-03-08 10:57:52",
        contratoDataEmissao: "2024-03-08 10:58:00",
        created_at: "2024-03-08T13:58:02.000000Z",
        updated_at: "2024-03-08T13:58:05.000000Z",
        contratoStatus: "s",
        contratoDecisao: "asd",
        contratoEtapaAtual: "asd",
        itens: []
      }
    );
    const [motivoReprovacao, setMotivoReprovacao] = useState("");
    const [motivoCancelamento, setMotivoCancelamento] = useState("");
    const [informacoesAd, setInformacoesAd] = useState("");
    const [formulario, setFormulario] = useState(
        {
            id: "",
            criterio: "",
            tipoPedido: "",
            numeroMov1: "",
            localEstoque: "",
            filial: "",
            coligada: ""
        }
    );


    useEffect(() => {

        //Obtendo o token
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            token = JSON.parse(localStorage.getItem('usuario')).token;
            usuarioID = JSON.parse(localStorage.getItem('usuario')).user.id;
            usuarioLogin = JSON.parse(localStorage.getItem('usuario')).user.login;
        }
        //Parâmetro id contrato:
        if (id_param) {
            //Buscar contrato passado por parâmetro:
            api.get(`/contrato/${id_param}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }
            ).then(function ({ data }) {
                setContrato(data.data); // dados do contrato                
                setEtapaAtual(data.data.contratoEtapaAtual); //Etapa atual da cotação

                // obterEtapasAnteriores(data.data.contratoEtapaAtual).then((result) => {
                //     let _etapasAnteriores = result;
                //     // Agora você pode usar o valor de _etapasAnteriores normalmente
                //     setEtapasAnteriores(_etapasAnteriores);
                // }).catch((error) => {
                //     console.error('Erro ao obter etapas anteriores:', error);
                // });

                //workflow da etapa atual possui as etapas que o aprovador atual pode retornar caso reprove:
                obterWorkflowEtapaAtual(data.data.contratoEtapaAtual).then((result) => {
                    let _etapaAtual = result;
                    let _retornos = JSON.parse(_etapaAtual.retornos);
                    let indiceAprovador = -1;
                    // identifica posicao da etapa atual, no campo retornos do workflow:
                    for (let i = 0; i < _retornos.length; i++) {
                        if (_retornos[i].etapa === data.data.contratoEtapaAtual) {
                          indiceAprovador = i;
                          break;
                        }
                    }
                    //sinaliza que encontrou etapa atual:
                    if (indiceAprovador !== -1) {
                      //pegar as etapas anteriores a etapa atual que estao no array:
                      let _retornosAnteriores = _retornos.slice(0, indiceAprovador);
                      // mantem apenas as etapas com campo 1.1.21 = true;
                      let etapasAnteriores1121 = _retornosAnteriores.filter(item => item["1.1.21"] === true);
                      // monta lista de strings para o autocomplete de etapas retornar
                      etapasAnteriores1121 = etapasAnteriores1121.map(item => item.etapa);

                      if (data.data.itens.length > 0){
                        //se tipo pedido origem for de materiais e servicos, eliminar etapas que nao correspondem ao tipo dele
                        if (data.data.itens[0].solicitacao.tipoPedido != "005" &&
                            data.data.itens[0].solicitacao.tipoPedido != "006" &&
                            data.data.itens[0].solicitacao.tipoPedido != "007") {
                              etapasAnteriores1121 = etapasAnteriores1121.filter(item => item !== "Aprov. diretoria equipamentos" && item !== "Aprov. gestor corporativo equipamentos");
                          } else {
                            //se tipo pedido for manutencao equipamentos, eliminar etapas desnecessarias
                            etapasAnteriores1121 = etapasAnteriores1121.filter(item => item !== "Aprov. diretoria operaçõe");
                          }
                      }

                      setEtapasAnteriores(etapasAnteriores1121);
                    }
                  }).catch((error) => {
                      console.error('Erro ao obter etapas workflow a retornar:', error);
                      toast.error(JSON.stringify(error.response.data));
                });

                setNumeroContrato(data.data.id);
                setNumeroProcesso(data.data.solicitacao_id);

                //Definindo a permissão de aprovação
                const grupos = JSON.parse(localStorage.getItem("usuario")).grupo;
                var obra = "";
                const idDoUsuario = JSON.parse(localStorage.getItem("usuario")).user.id;
                //Varrer variável grupos do usuário e identificar se a atividade atual pertence a ele:
                grupos.forEach((grupo) => {
                    //se perfil do grupo do usuário corresponde a etapa atual da aprovação de contrato,
                    // e o contrato tenha mesmo localEstoque que o grupo do usuário, ele pode Aprovar:
                    if ((grupo.perfil == data.data.contratoEtapaAtual) && (grupo.obra == data.data.contratoCodloc)) {
                        obra = grupo.obra;
                        setPermissaoAprovador(true);
                    }
                   });
                }).catch(function (err) {
                    console.error(err);
                    toast.error("Ocorreu um erro ao carregar o contrato. Por favor, atualize a página.");
                    toast.error(JSON.stringify(err.response.data));
                  })
        }
        //Carregar locais de estoque e centros de custo do solicitante
        const usuario = JSON.parse(localStorage.getItem('usuario')).user.login;
        //Aqui um trecho de cleanup, que serve para limpar variaveis de sessão e recursos assim que este componente for desmontado:
        return () => {
            localStorage.removeItem('qtdArquivosSelecionados');
            localStorage.removeItem('nomesArquivosSelecionados');
        };
    }, []);

    //Atualizar variável de estado que armazena os dados do formulário
    const atualizarFormulario = (chave, valor, item = false, indiceItem) => {
        let objFormulario = formulario;
        if (item) {
        osVinculada ?
            objFormulario.itens[indiceItem][chave] = valor :
            objFormulario.itens[indiceItemConsultado][chave] = valor;
        }
        else {
        objFormulario[chave] = valor;
        }
        setFormulario(objFormulario);
        setAtualizarEstados(!atualizarEstados);
    }

    // const buscarItem = () => {
    //     if (nomeCodigoNovoItem) {
    //       const id = toast.loading("Buscando o item informado");
    //       const isNumber = (!isNaN(parseInt(nomeCodigoNovoItem.replace("/^\d+$/.test(str)"))));
    //       const codigo = isNumber ? nomeCodigoNovoItem.padStart(5, "0") : null;
    //       const descricao = isNumber ? null : nomeCodigoNovoItem;
    
    //       RM.get(`https://pelicanoconstrucoes126704.rm.cloudtotvs.com.br:8051/api/framework/v1/consultaSQLServer/RealizaConsulta/660642/1/G?parameters=CODIGOPRD_S=${codigo};NOME_PRODUTO=${descricao};CODLOC=${contrato.contratoCodloc};CODFILIAL=${contrato.contratoFilial};`)
    //         .then(function ({ data }) {
    //           if (data.length > 1) {
    //             toast.update(id, { render: "Item(s) encontrado(s)", type: "success", isLoading: false, autoClose: 1000 });
    //             setItensEncontradosBusca(data);
    //             setOpenModalListaItem(true);
    //             setNomeCodigoNovoItem("");
    //           } else if (data.length == 1) {
    //             toast.update(id, { render: "Item encontrado", type: "success", isLoading: false, autoClose: 1000 });
    //             novoItem(data[0]);
    //             setNomeCodigoNovoItem("");
    //           } else {
    //             toast.update(id, { render: "Item não encontrado neste local de estoque", type: "warning", isLoading: false, autoClose: 2500 });
    //           }
    //         }).catch(function (err) {
    //             console.error(err);
    //             toast.update(id, { render: "Erro ao adicionar novo item", type: "error", isLoading: false, autoClose: 1000 });
    //         });
    //     }
    // }

    //Adicionar novo item manualmente no formulário
    const novoItem = (dados) => {

        const dadosFormatados = {
            codProduto: dados.CODIGOPRD,
            idPrd: dados.IDPRD,
            descricao: dados.NOMEFANTASIA,
            quantidadeProduto: dados.QUANTIDADEARECEBER,
            QUANTIDADEARECEBER: "",
            CODTMV: "",
            estoque: dados.SALDOFISICO2,
            saldoMin: dados.SALDO_MIN,
            saldoMax: dados["SALDO MAX"],
            CODUND: dados.UNIDADE,
            saldoContrato: dados.SALDODISPCONT,
            pedidoAFornecedor: dados.PEDIDOF,
            precoUntAlvo: "",
            idObjetoOficina: "",
            observacoesItem: "",
            naoUsarEstoque: false,
            naoUsarContrato: false,
        }
        setItemTemporario(dadosFormatados);
        setOpenModal(true);
    }

    const acrescentarItem = () => {
        let itens = contrato.itens;
        if (itens.length > 0) {
            let itemEncontrado = false;
            itens.forEach((item, indice) => {
            if (item.codProduto == itemTemporario.codProduto) {
                itens[indice] = itemTemporario;
                itemEncontrado = true;
            }
            });
            if (!itemEncontrado) {
            itens.push(itemTemporario);
            }
        }
        else {
            itens.push(itemTemporario);
        }
        contrato.itens = itens;
        setOpenModal(false);
        setItemTemporario({});
    }

    const excluirItem = (indice) => {
    setDialogExclusao(false)
    setIndiceItemConsultado(0);
    contrato.itens.splice(indice, 1);
    }

    const rolarParaOCampo = (decisao) => {
        if (decisao != "Aprovado") {
          //Precisa haver um delay na chamada da função de rolagem porque a renderização do componente é assíncrona
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 50);
        }
    }

    const movimentarProcesso = async () => {
        if ((decisaoAprovacao == "Reprovado") && (motivoReprovacao == "")) {
          toast.warning("O campo motivo da reprovação é obrigatório");
          return;
        }
        if ((decisaoAprovacao == "Cancelado") && (motivoCancelamento == "")) {
          toast.warning("O campo motivo do cancelamento é obrigatório");
          return;
        }
        
        // Obter proxima etapa (nao existe conceito de urgente no mov.contrato, tratar como criterio normal):
        formulario.criterio = "NO";
        const proximaEtapa = await obterProximaEtapa(formulario, etapaAtual, decisaoAprovacao, etapaARetornar, contrato.itens_sum_preco_total);
        
        console.log("proxima etapa "+ proximaEtapa);
        console.log("preco contrato: "+ contrato.itens_sum_preco_total);

        //TODO: motivocancel/rep/info. adicionais
        contrato.motivoCancelamento = motivoCancelamento;
        contrato.motivoReprovacao = motivoReprovacao;
        contrato.informacoesAd = informacoesAd;

        contrato.usuario_id = JSON.parse(localStorage.getItem("usuario")).user.id;
        contrato.contratoDecisao = decisaoAprovacao; 
        contrato.contratoEtapaAtual = proximaEtapa;
        contrato.contratoEtapaAnterior = etapaAtual;
    
        if (decisaoAprovacao == "Cancelado") {
          contrato.contratoStatus = 2; //se cancelou marcar cancelado
        }
        else if (proximaEtapa == "diligenciamento") {
          await montaEvolucaoPedido(proximaEtapa, formulario, contrato);
        }
        else {
          contrato.contratoStatus = 1; //senao está em andamento
        }

        console.log("json contrato");
        console.log(contrato);

        api.put(`/contrato/` + contrato.id,
          contrato,
          {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          }
        )
          .then(function ({ data }) {
            toast.success("Movimentação de contrato realizada com sucesso!");
            api.post(`/anexos?solicitacao_id=${contrato.solicitacao_id}&responsavel=${usuarioLogin}`,
              uploadAnexos,
              {
                headers: {
                  'Authorization': 'Bearer ' + token,
                  'Content-Type': 'multipart/form-data'
                }
              }
            ).then(function (response) {
              toast.success("Anexo salvo com sucesso!");
              navigate("/centralContratos");
            }).catch(function (err) {
              console.error(err);
              toast.error(JSON.stringify(err.response.data));
            })
          })
          .catch(function (err) {
            console.error(err);
            toast.error("Ocorreu um erro ao salvar o movimento de contrato. Tente novamente em alguns instantes.");
            toast.error(JSON.stringify(err.response.data));
          });
    }

    const definirStatus = (decisao) => {
        //se aprovado ou reprovado, continua em andamento:
        if ((decisao == "Aprovado") || (decisao == "Reprovado")) {
          contrato.contratoStatus = 1;
          return;
        }
        if (decisao == "Cancelado") {
          contrato.contratoStatus = 2;
          return;
        }
        if (decisao == "Concluído") {
          contrato.contratoStatus = 3;
          return;
        }
    }

    const montaEvolucaoPedido = async (proximaEtapa, formulario, contrato) => {
        //TODO: atualizar para caso contrato.
        //Caso a próxima etapa seja a final, fará evolução do movimento:
        let pedidoEvolucao1122 = {};
        let itensEvolucao = [];
    
        //TODO: qual será a ultima etapa do movimento de contrato:
        if (proximaEtapa == "diligenciamento") {

          let listaItensContrato = [];
          //montar a lista de itens de contrato, passando apenas sequencia e quantidade:
          contrato.itens.forEach((item, index) => {
            if (item.quantidadeBaixarContratoRM > 0) {
              let obj = {
                QUANTIDADE: item.quantidadeBaixarContratoRM,
                SEQUENCIA: parseInt(index) + 1
              }
              listaItensContrato.push(obj);
            }
          })
          //Header do pedido 1.1.22 (1.1.22 nao precisa enviar itens):
          pedidoEvolucao1122 = {
            // CODCOLIGADA: formulario.coligada,
            CODMOVIMENTODESTINO: "1.1.22",
            CODMOVIMENTOORIGEM: "1.1.21",
            IDMOV: contrato.contratoMov21,
            CODUSUARIO: usuarioLogin,
            ITENS: listaItensContrato,
            SERIE: "OCC",
            PROCESSO_ID: " ",
            CODLOC: contrato.contratoCodloc,
            CODFILIAL: contrato.contratoFilial,
            CODCOLIGADA: "1" //Pelicano
          }
    
          try {
            let id_toast = toast.loading("Salvando movimento 1.1.22...");
            
            console.log("json do 1.1.22 request:");
            console.log(pedidoEvolucao1122);
            //Requisicao para gerar movimento 1102
            await axios.post(`https://almox.pelicano.eng.br/php/movimento/faturamento/Faturamento.php`,
            pedidoEvolucao1122, {
              timeout: 100000
            }
            ).then(async function (response) {
              console.log("retorno 1.1.22");
              console.log(response);

              let retorno = response.data;
              // let retornoPrefixo = retorno.substring(0, 2);
              let sucessoAoGerar1122 = false;
              if (retorno == "1") {
                //TODO: Guardar número do movimento 1122 Gerado?:
                contrato.contratoMov22 = retorno;
                contrato.contratoStatus = 3; // contrato com status concluido.
                toast.update(id_toast, { render: "O movimento 1.1.22 foi Salvo com sucesso!", type: "success", isLoading: false, autoClose: 2000 });
                sucessoAoGerar1122 = true;
              }
              else {
                contrato.contratoEtapaAtual = etapaAtual;
                const mensagemErro = "Aviso do RM ao gerar movimento 1.1.22: " + response.data.split("=======================================")[0];
                toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
              }
            }).catch(function (err) {
              contrato.contratoEtapaAtual = etapaAtual;
              console.log("retorno erro 1.1.22");
              console.log(err);
              const mensagemErro = "Aviso do RM ao gerar movimento 1.1.22: " + err.data.split("=======================================")[0];
              toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
              toast.error(JSON.stringify(err.response.data));
            })
          } catch (error) {
            contrato.contratoEtapaAtual = etapaAtual;
            console.error("Erro nas requisições 1.1.22");
            console.log(error);
          }
        }//if diligenciamento
    }

    const cancelaMovimento = async (formulario, contrato) => {
      
        let jsonCancelaMovimento = {
          coligada: 1,
          idmov: contrato.contratoMov22
        };
        
        let id_toast = toast.loading("Cancelando movimento");
        await axios.post(`https://api.pelicano.eng.br/api/totvs/cancela-movimento`,
        jsonCancelaMovimento, {
          timeout: 100000
        }
        ).then(async function (response) {
          let resp = response.data;
          if (resp) {
            toast.update(id_toast, { render: "Movimento 1.1.22 cancelado com sucesso.", type: "success", isLoading: false, autoClose: 2000 });
          } else {
            const mensagemErro = "Aviso do RM ao cancelar movimento 1.1.22: " + response.data.split("=======================================")[0];
            toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
          }
        }).catch(function (err) {
          console.log(err);
          toast.update(id_toast, { render: "O cancelamento do movimento 1.1.22 não foi efetivado, consulte o log.", type: "warning", isLoading: false, closeOnClick: true });
          toast.error(JSON.stringify(err.response.data));
        })
    }

    const gerarMovimento = async (jsonEvolucao, numero_movimento) => {
        /*
          Método genérico que serve para evoluir os movimentos
         */
        let id_toast = toast.loading("Gerando movimento " + numero_movimento + "..");
        try {
          const response = await axios.post(`https://almox.pelicano.eng.br/php/movimento/faturamento/Faturamento.php`,
            jsonEvolucao, {
            timeout: 100000
          });
          let retorno = response.data;
          let retornoPrefixo = retorno.substring(0, 2);
    
          if (retornoPrefixo == "1;") {
    
            toast.update(id_toast, { render: "O movimento " + numero_movimento + " foi Salvo com sucesso no RM!", type: "success", isLoading: false, autoClose: 2000 });
            return true;
          } else {
            let substring = "=======================================";
            if (response.data.includes(substring)) {
              const mensagemErro = "Aviso do RM ao salvar movimento " + numero_movimento + ": " + response.data.split("=======================================")[0];
              toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
            }
            else {
              const mensagemErro = "O RM encontrou uma inconsistência ao salvar o movimento " + numero_movimento + ". Para mais informações, consulte o log";
              toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
            }
            return false;
          }
        } catch (err) {
          let substring = "=======================================";
          if (err.data.includes(substring)) {
            const mensagemErro = "Aviso do RM ao salvar movimento " + numero_movimento + ": " + err.data.split("=======================================")[0];
            toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
          }
          else {
            const mensagemErro = "O RM encontrou uma inconsistência ao salvar o movimento " + numero_movimento + ". Para mais informações, consulte o log";
            toast.update(id_toast, { render: mensagemErro, type: "warning", isLoading: false, closeOnClick: true });
          }
    
          return false;
        }
    }

    const handleAutocompleteEtapaARetornar = (event, newValue) => {
        setEtapaARetornar(newValue);
    };


    return (
        <Stack backgroundColor="#F5F5F5" height="100%" minHeight={"100vh"}>
          <Header mobile={mobile} notification />
          <Sidebar mobile={mobile} />
    
          <Stack
            ml={mobile ? "0px" : "250px"}
            mt="50px"
            flex={1}
            height={"calc(100% - 55px)"}
            p={mobile ? "20px" : "50px"}
            overflow="auto"
          >
            {/* Conteúdo da Tela */}
            <Typography level="h4">Movimento de Contrato: {contrato.id } | Etapa atual: {etapaAtual}</Typography>
    
            <Stack flexDirection={"row"} mt="10px" ml="auto">
              {numeroProcesso != "" ?
                <Link fontSize="16px" onClick={() => navigate("/fluxoAprovacao", { state: { processo: numeroProcesso, origemChamada: "/contratos/", contrato: contrato.id } })} mr="20px">
                  Fluxo de Aprovação
                </Link>
                : null
              }
              <ToggleButtonGroup
                value={valueTogglePagina}
                onChange={(event, newValue) => newValue ? setValueTogglePagina(newValue) : null}
              >
                <Button value="formulario" sx={valueTogglePagina == "formulario" ? { fontWeight: "bold" } : { fontWeight: "normal" }}>Formulário</Button>
                <Button value="anexo" sx={valueTogglePagina == "anexo" ? { fontWeight: "bold" } : { fontWeight: "normal" }}>Anexos</Button>
              </ToggleButtonGroup>
            </Stack>
    
            {/* ABA FORMULARIO  */}
            {valueTogglePagina == "formulario" && (
              <>

                <Typography level="title-lg" mt="40px">
                  Dados do Contrato
                </Typography>
    
                { contrato ? (
                  <>
                  <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
                    <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"}>
                      <FormLabel>Fornecedor</FormLabel>
                      <Input type="text" value={contrato.contratoFornecedor} disabled/>
                    </Stack>
                    <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"}>
                      <FormLabel>Cod. Local</FormLabel>
                      <Input type="text" value={contrato.contratoCodloc} disabled/>
                    </Stack>
                    <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"}>
                      <FormLabel>N° Solicitação</FormLabel>
                      <Input type="text" value={contrato.solicitacao_id} disabled/>
                    </Stack>
                  </Stack>
    
                  <Stack flexDirection={mobile ? "column" : "row"} mt="15px">

                    <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} mt={mobile ? "20px" : ""}>
                      <FormLabel>Data Entrega</FormLabel>
                      <Input type="text" value={formatarDataHoraBrasil(contrato.contratoDataEntrega)} disabled/>
                    </Stack>
                    
                    <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"}>
                      <FormLabel>Data Emissão</FormLabel>
                      <Input type="text" value={formatarDataHoraBrasil(contrato.contratoDataEmissao)} disabled/>
                    </Stack>

                    <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile ? "0" : "30px"}>
                      <FormLabel>N° 1.1.21</FormLabel>
                      <Input type="text" value={contrato.contratoMov21} disabled/>
                    </Stack>

                  </Stack>
                  </>
                )
                :null
                }
    
                <Typography level="title-lg" mt="40px">
                  Itens
                </Typography>
    
                {/* {etapaAtual == "Solicitante" && !osVinculada ?
                  <Stack mt="10px" ml="auto">
                    <Stack flexDirection={"row"}>
                      <Input value={nomeCodigoNovoItem} onChange={(event) => setNomeCodigoNovoItem(event.target.value)} placeholder="Código ou Descrição" sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
                      <Button size="sm" onClick={buscarItem} sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>Adicionar Item</Button>
                    </Stack>
                  </Stack>
                  : null} */}
    
                <Stack mt="30px">
                  {mobile ?
                    <Stack>
                      {contrato.itens.map((item, indice) => {
                          return (
                            <div>
                              <Stack p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                                <Stack flexDirection="row" alignItems="center" justifyContent={"space-between"}>
                                  <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Código: </Typography>{item.codProduto}</Typography>
                                  
                                </Stack>
                                <Typography level="body-sm"><Typography level="title-md">Solicitação origem: </Typography>{item.solicitacao_id}</Typography>
                                <Typography level="body-sm"><Typography level="title-md">Item: </Typography>{indice + 1}</Typography>
                                <Typography level="body-sm"><Typography level="title-md">Descrição: </Typography>{item.descricao}</Typography>
                                <Typography level="body-sm"><Typography level="title-md">Saldo Contrato: </Typography>{item.estoque}</Typography>
                                <Typography level="body-sm"><Typography level="title-md">Qtd Contrato: </Typography>{item.quantidadeBaixarContratoRM}</Typography>
                                <Typography level="body-sm"><Typography level="title-md">Preço Unit: </Typography>{formatarMoeda(item.precoUnit)}</Typography>
                                <Typography level="body-sm"><Typography level="title-md">Preço Total: </Typography>{formatarMoeda(item.precoTotal)}</Typography>
                                {etapaAtual == "Solicitante" ? <Typography level="body-sm"><Typography level="title-md">Status: </Typography>{item.CODTMV == "1.1.01" ? "Pedido" : item.CODTMV == "1.1.03" ? "Baixado" : " - "}</Typography> : null}
                                <Stack level="body-sm" mt="20px">
                                  <Stack flexDirection="row" alignItems="center">
                                    <Button onClick={() => [setOpenModal(true), setIndiceItemConsultado(indice), setItemTemporario(contrato.itens[indice])]} color="neutral" sx={osVinculada ? { width: "100%" } : { width: "50%", marginRight: "10px" }}>{osVinculada || numeroProcesso != "" ? "Consultar" : "Editar"}</Button>
                                  </Stack>
                                </Stack>
                              </Stack>
                            </div>
                          ); 
                      })}
                    </Stack>
                    :
                    <Table hoverRow stripe="even" noWrap aria-label="basic table">
                      <thead>
                        <tr>
                          {osVinculada && etapaAtual == "Solicitante" ? <th style={{ height: "25px", width: "25px" }}></th> : null}
                          <th style={{ width: "40px", height: "25px" }}>Item</th>
                          <th style={{ width: "80px", height: "25px" }}>Código</th>
                          <th style={{ width: "130px", height: "5px" }}>Solicitação origem</th>
                          <th style={{ height: "25px" }}>Descrição</th>
                          <th style={{ width: "80px", height: "25px" }}>Saldo</th>
                          <th style={{ width: "100px", height: "25px" }}>Qtd Contrato</th>
                          <th style={{ width: "100px", height: "25px" }}>Preço Unit.</th>
                          <th style={{ width: "100px", height: "25px" }}>Preço Total</th>
                          {etapaAtual == "Solicitante" ? <th style={{ width: "80px", height: "25px" }}>Status</th> : null}
                          <th style={{ width: "70px", height: "25px", textAlign: "center" }}>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contrato.itens.map((item, index) =>
                            <tr>
                              <td style={{ padding: "0 10px" }}>{parseInt(index + 1)}</td>
                              <td style={{ padding: "0 10px" }}>{item.codProduto}</td>
                              <td style={{ padding: "0 10px" }}>{item.solicitacao_id}</td>
                              <td style={{ padding: "0 10px" }}>{item.descricao}</td>
                              <td style={{ padding: "0 10px" }}>{item.estoque}</td>
                              <td style={{ padding: "0 10px" }}>{item.quantidadeBaixarContratoRM}</td>
                              <td style={{ padding: "0 10px" }}>{formatarMoeda(item.precoUnit)}</td>
                              <td style={{ padding: "0 10px" }}>{formatarMoeda(item.precoTotal)}</td>
                              {/* <td style={{ padding: "0 10px", display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
                                <div className="hover">
                                </div>
                              </td> */}
                              <td style={{ padding: "0 10px", display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
                                <div className="hover">
                                  {((etapaAtual == "Solicitante") && (!osVinculada)) ?
                                    <FaRegEdit onClick={() => [setOpenModal(true), setIndiceItemConsultado(index), setItemTemporario(contrato.itens[index])]} color="#2974ac" size={22} />
                                    :
                                    <FaFolderOpen onClick={() => [setOpenModal(true), setIndiceItemConsultado(index), setItemTemporario(contrato.itens[index])]} color="#2974ac" size={22} />
                                  }
                                </div>
                                {((etapaAtual == "Solicitante") && (!osVinculada)) ?
                                  <div onClick={() => [setIndiceItemConsultado(index), setDialogExclusao(true)]} className="hover" style={{ marginLeft: "10px" }}>
                                    <FaRegTrashAlt color="#990000" size={22} />
                                  </div>
                                  : null}
                              </td>
                            </tr>
                        )}
                      </tbody>
                    </Table>
                  }
    
                  <Stack
                    fontSize="22px"
                    fontWeight={"bold"}
                    alignItems={"end"}
                    mt="20px"
                    mr="0px"
                    color="#333"
                  >
                    Total: { contrato ? formatarMoeda(contrato.itens_sum_preco_total): ""}
                  </Stack>
                </Stack>
    
                {/* Container de aprovação */}
                {permissaoAprovador ?
                  <Stack flexDirection={"column"} boxShadow={mobile ? "" : "md"} padding={0} borderRadius={"10px"} mt="50px" sx={mobile ? {} : { backgroundColor: "#fff" }}>
                    <Stack p={mobile ? 0 : 3} flexDirection={mobile ? "column" : "row"} justifyContent={mobile ? "flex-start" : "space-between"} alignItems={mobile ? "flex-start" : "flex-end"}>
                      <Stack>
                        <Typography level="title-lg">Decisão</Typography>
                        <ToggleButtonGroup
                          value={decisaoAprovacao}
                          onChange={(event, newValue) =>
                            [
                              setDecisaoAprovacao(newValue ? newValue : decisaoAprovacao),
                              rolarParaOCampo(newValue ? newValue : decisaoAprovacao),
                              definirStatus(newValue ? newValue : decisaoAprovacao),
                            ]
                          }
                        >
                          <Button value="Aprovado" style={decisaoAprovacao == "Aprovado" ? { backgroundColor: "#7cb342" } : {}}>
                            <Typography sx={decisaoAprovacao == "Aprovado" ? { color: "#eee" } : {}}>Aprovar</Typography>
                          </Button>
                          {/* se nao ha etapa a retornar, o botao reprovar nao tem utilidade */}
                          { etapasAnteriores.length != 0 && (
                          <Button value="Reprovado" style={decisaoAprovacao == "Reprovado" ? { backgroundColor: "#ff8f00" } : {}}>
                            <Typography sx={decisaoAprovacao == "Reprovado" ? { color: "#eee" } : {}}>Reprovar</Typography>
                          </Button>
                          )}
                          <Button value="Cancelado" style={decisaoAprovacao == "Cancelado" ? { backgroundColor: "#f44336" } : {}}>
                            <Typography sx={decisaoAprovacao == "Cancelado" ? { color: "#eee" } : {}}>Cancelar</Typography>
                          </Button>
                        </ToggleButtonGroup>
                      </Stack>
                      <Stack width={mobile ? "100%" : "auto"}>
    
                        {/* Select de selecionar etapas anteriores, caso reprovacao (fase 2) */}
                        { etapasAnteriores.length > 0 && decisaoAprovacao == "Reprovado" ? (
                          <Stack m={"10px"}>
                            <Typography level="title-md">Retornar para Atividade:</Typography>
                            <Autocomplete options={etapasAnteriores} onChange={handleAutocompleteEtapaARetornar} value={etapaARetornar} />
                          </Stack>
                        ) : null }
    
                        <Button disabled={decisaoAprovacao == ""} sx={mobile ? { marginTop: "20px" } : {}} onClick={movimentarProcesso}>Confirmar Decisão</Button>
                      </Stack>
                    </Stack>
                    <Stack p={mobile ? 0 : 3} flexDirection={mobile ? "column" : "row"} justifyContent={"space-between"}>
                      <Stack mt="30px" width={mobile ? "100%" : "50%"} mr="30px">
                        <Typography level="title-md">Informações Adicionais</Typography>
                        <Textarea value={informacoesAd} onChange={event => setInformacoesAd(event.target.value)} minRows={3} sx={{ marginTop: "5px" }} placeholder="Descreva as informações adicionais" />
                      </Stack>
                      {decisaoAprovacao == "Reprovado" ?
                        <Stack mt="30px" width={mobile ? "100%" : "50%"}>
                          <Typography level="title-md">Motivo da Reprovação</Typography>
                          <Textarea value={motivoReprovacao} onChange={event => setMotivoReprovacao(event.target.value)} minRows={3} sx={{ marginTop: "5px", border: 1, borderColor: "#ff8f00" }} placeholder="Informe o motivo da reprovação" />
                        </Stack>
                        : null}
                      {decisaoAprovacao == "Cancelado" ?
                        <Stack mt="30px" width={mobile ? "100%" : "50%"}>
                          <Typography level="title-md">Motivo do Cancelamento</Typography>
                          <Textarea value={motivoCancelamento} onChange={event => setMotivoCancelamento(event.target.value)} minRows={3} sx={{ marginTop: "5px", border: 1, borderColor: "#f44336" }} placeholder="Informe o motivo do cancelamento" />
                        </Stack>
                        : null}
                    </Stack>
                  </Stack>
                  : null
                }
    
    
                <InformacoesItem
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  mobile={mobile}
                  itemTemporario={itemTemporario}
                  setItemTemporario={setItemTemporario}
                  formulario={formulario}
                  indiceItemConsultado={indiceItemConsultado}
                  atualizarFormulario={atualizarFormulario}
                  numeroProcesso={numeroProcesso}
                  etapaAtual={etapaAtual}
                  osVinculada={osVinculada}
                  acrescentarItem={acrescentarItem}
                />
    
                {/* Modal de listagem de itens quando pesquisado por descricao */}
                <SelecaoItem
                  openModalListaItem={openModalListaItem}
                  setOpenModalListaItem={setOpenModalListaItem}
                  itensEncontradosBusca={itensEncontradosBusca}
                  novoItem={novoItem}
                />
              </>
            )}
    
            {contrato.itens.length > 0 ?
    
              <ConfirmarExclusao
                dialogExclusao={dialogExclusao}
                setDialogExclusao={setDialogExclusao}
                mensagem={`Tem certeza que deseja excluir o item de código ${contrato.itens[indiceItemConsultado].CODIGOPRD} ? Esta ação é irreversível`}
                excluirItem={excluirItem}
                indiceItemConsultado={indiceItemConsultado}
              />
              : null
            }
    
            {/* ABA DE ANEXO */}
            {valueTogglePagina == "anexo" && (
              <Anexos numeroProcesso={numeroProcesso} mobile={mobile} anexos={anexos}
                setUploadAnexos={setUploadAnexos} setAnexosSelecionados={setAnexosSelecionados} anexosSelecionados={anexosSelecionados}
                usuarioResponsavel={usuarioLogin} solicitacoesOrigem={[contrato.solicitacao_id]}/>
            )}
          </Stack>
        </Stack>
      );

}