import { Modal, ModalDialog, ModalClose, Typography, List, Stack, Divider } from "@mui/joy";

export default function SaldoDosItens(props) {
    return (
        <Modal
            open={props.openModalInfoItem}
            onClose={() => props.setOpenModalInfoItem(false)}
        >
            <ModalDialog
                aria-labelledby="variant-modal-title"
                aria-describedby="variant-modal-description"
                variant={"soft"}>
                <ModalClose />
                <Typography
                    id="basic-list-demo"
                    level="body-xs"
                    textTransform="uppercase"
                    fontWeight="lg"
                >
                    Saldos do Item
                </Typography>
                <List sx={{ marginTop: "20px" }} aria-labelledby="basic-list-demo">
                    {props.saldosDoItem.map(function (saldo, indice) {
                        return (
                            <Stack mb="10px">
                                <Typography fontSize={14}>{saldo.NOME}</Typography>
                                <Typography fontSize={14} fontWeight={"bold"} sx={{ marginBottom: "10px" }}>Saldo: {saldo.SALDOFISICO2}</Typography>
                                {((props.saldosDoItem.length - 1) > indice) ?
                                    <Divider />
                                    : null}
                            </Stack>
                        )
                    })}
                </List>
            </ModalDialog>
        </Modal>
    );
}