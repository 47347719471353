import { useState, useEffect } from "react";

//Icones
import { FaTasks } from "react-icons/fa";
import { IoChevronForwardSharp, IoMenuSharp, IoCloseOutline, IoPricetagsOutline, IoDocumentTextOutline } from "react-icons/io5";
import { BsFileEarmarkPlus, BsLock } from "react-icons/bs";
import { LiaUsersCogSolid } from "react-icons/lia";
import { GoWorkflow } from "react-icons/go";

//Roteamento
import { Link, useLocation, useNavigate } from "react-router-dom";

import {
    Avatar,
    Typography,
    Stack,
    List,
    ListItem,
    ListItemButton,
    ListItemDecorator,
    ListItemContent,
    Button,
    Dropdown,
    MenuButton,
    MenuItem,
    Menu,
    Tooltip
} from '@mui/joy';

import LinkMui from "@mui/joy/Link";
import { api } from "../../services/api";

export default function Sidebar(props) {

    const permissoes = JSON.parse(localStorage.getItem('usuario')).user.autenticacao;
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [drawer, setDrawer] = useState(false);
    const [usuario, setUsuario] = useState({ user: { nome: "" } });
    const [perfilAprovador, setPerfilAprovador] = useState(false);
    const [adm, setAdm] = useState(false);
    const [notificacoes, setNotificacoes] = useState({ solicitacoes: 0, cotacoes: 0, contratos: 0 });

    useEffect(() => {
        if (localStorage.getItem("usuario") != "null") {
            setUsuario(JSON.parse(localStorage.getItem("usuario")));
            setPerfilAprovador(JSON.parse(localStorage.getItem("usuario")).grupo.length > 0);

            //Verificando se o usuário é administrador
            if (JSON.parse(localStorage.getItem("usuario")).grupo.length > 0) {
                const adm = JSON.parse(localStorage.getItem("usuario")).grupo.filter(grupo => grupo.nome == "ADM");
                if (adm.length > 0) {
                    setAdm(true);
                }
            }
        }

        //Obtendo notificações
        const token = JSON.parse(localStorage.getItem('usuario')).token;
        api.get("/notificacoes",
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
        ).then(({ data }) => {
            const dados = ({
                solicitacoes: data.filter(permissao => permissao.tipo == "Solicitações")[0].count,
                cotacoes: data.filter(permissao => permissao.tipo == "Cotações")[0].count,
                contratos: data.filter(permissao => permissao.tipo == "Contrato")[0].count
            });
            setNotificacoes(dados);
        });
    }, []);

    return (
        <Stack position="fixed" zIndex={2} top={5} left={5} overflow="auto" >
            <Stack position={"relative"} zIndex={2} top={0}>
                <Button variant="plain" onClick={() => setDrawer(!drawer)}>
                    <IoMenuSharp size={28} color="#ddd" />
                </Button>
            </Stack>
            <Stack position="fixed" zIndex={2} top={0} left={(props.mobile && !drawer) ? -285 : 0} height="100%" boxShadow={"0px 0px 8px #ccc"} overflow="auto" width={"280px"} backgroundColor="#fff"  >
                {props.mobile ?
                    <Stack position="absolute" right={0} top={0}>
                        <Button variant="plain" onClick={() => setDrawer(!drawer)}>
                            <IoCloseOutline size={28} color="#555" />
                        </Button>
                    </Stack>
                    : null}
                <Stack display="flex" flexDirection="column" borderBottom="1px solid #ddd" mb="10px" py={3} pb={2} alignItems={"center"} justifyContent={"center"}>
                    <Avatar />
                    <Typography level="title-md" mt={1}>{JSON.parse(localStorage.getItem("usuario")).user.nome ? JSON.parse(localStorage.getItem("usuario")).user.nome.toUpperCase() : "Não Informado"}</Typography>
                    {perfilAprovador ?
                        <Stack width="90%">
                            <Dropdown>
                                <MenuButton sx={{ border: 0, backgroundColor: "#eee" }}>
                                    Ver Perfis
                                </MenuButton>
                                <Menu>
                                    {usuario.grupo.length > 0 ? (
                                        usuario.grupo.map((item, indice) => (
                                            // <span key={index} style={{ display: "block", marginBottom: "3px" }}>{item.obra + " - " + item.perfil} </span>
                                            <MenuItem key={indice}>{item.obra + " - " + item.perfil}</MenuItem>
                                        ))
                                    ) : (
                                        "Solicitante"
                                    )}

                                </Menu>
                            </Dropdown>
                            {/* <Typography fontSize={"12px"} ml={"10px"} mt={1}>
                                <strong>Obra(s) - Perfil(s): </strong>
                                <Stack mt="5px">
                                    {usuario.grupo.length > 0 ? (
                                        usuario.grupo.map((item, index) => (
                                            <span key={index} style={{ display: "block", marginBottom: "3px" }}>{item.obra + " - " + item.perfil} </span>
                                        ))
                                    ) : (
                                        "Solicitante"
                                    )}
                                </Stack>
                            </Typography> */}
                        </Stack>
                        : null
                    }
                    <LinkMui onClick={() => navigate("/modulos")} sx={{ marginTop: "10px" }}>Ir para módulos</LinkMui>
                    <LinkMui onClick={() => [navigate("/"), localStorage.setItem("usuario", null)]} fontSize={14}>sair</LinkMui>
                </Stack>
                <Stack>
                    <List>
                        {permissoes.includes("requisicoes") &&
                            <Link to={"/centralTarefas"} onClick={() => setDrawer(false)} style={{ textDecoration: "none" }}>
                                <ListItem>
                                    <ListItemButton selected={pathname == "/centralTarefas"}>
                                        <ListItemDecorator><FaTasks fontSize={20} /></ListItemDecorator>
                                        <ListItemContent style={{ fontSize: "14px", userSelect: "none" }}>CENTRAL DE REQUISIÇÕES</ListItemContent>
                                        {notificacoes.solicitacoes > 0 ?
                                            <Tooltip title="Requisições aguardando sua aprovação" variant="solid">
                                                <Typography fontSize={12} sx={{ backgroundColor: "#ffe082", width: "22px", padding: "2px 7px", textAlign: "center", borderRadius: "10px", color: "#555", fontWeight: "bold" }}>{notificacoes.solicitacoes > 99 ? "99+" : notificacoes.solicitacoes}</Typography>
                                            </Tooltip>
                                            :
                                            <IoChevronForwardSharp />
                                        }
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        }

                        {permissoes.includes("compras") &&
                            <Link to={"/compras"} onClick={() => setDrawer(false)} style={{ textDecoration: "none" }}>
                                <ListItem>
                                    <ListItemButton selected={pathname == "/compras"}>
                                        <ListItemDecorator><IoPricetagsOutline fontSize={20} /></ListItemDecorator>
                                        <ListItemContent style={{ fontSize: "14px", userSelect: "none" }}>CENTRAL DE COTAÇÕES</ListItemContent>
                                        {notificacoes.cotacoes > 0 ?
                                            <Tooltip title="Cotações aguardando sua aprovação" variant="solid">
                                                <Typography fontSize={12} sx={{ backgroundColor: "#ffe082", width: "22px", padding: "2px 7px", textAlign: "center", borderRadius: "10px", color: "#555", fontWeight: "bold" }}>{notificacoes.cotacoes > 99 ? "99+" : notificacoes.cotacoes}</Typography>
                                            </Tooltip>
                                            :
                                            <IoChevronForwardSharp />
                                        }
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        }

                        {/* TODO: permissao para acessar o financeiro? */}
                        {permissoes.includes("compras") &&
                            <Link to={"/comprasFinanceiro"} onClick={() => setDrawer(false)} style={{ textDecoration: "none" }}>
                                <ListItem>
                                    <ListItemButton selected={pathname == "/comprasFinanceiro"}>
                                        <ListItemDecorator><IoPricetagsOutline fontSize={20} /></ListItemDecorator>
                                        <ListItemContent style={{ fontSize: "14px", userSelect: "none" }}>CONSULTA DE NF</ListItemContent>
                                        <IoChevronForwardSharp />

                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        }

                        {permissoes.includes("requisicoes") &&
                            <Link to={"/centralTransferencia"} onClick={() => setDrawer(false)} style={{ textDecoration: "none" }}>
                                <ListItem>
                                    <ListItemButton selected={pathname == "/centralTransferencia"}>
                                        <ListItemDecorator><FaTasks fontSize={20} /></ListItemDecorator>
                                        <ListItemContent style={{ fontSize: "14px", userSelect: "none" }}>TRANSFERÊNCIAS</ListItemContent>
                                        <IoChevronForwardSharp />
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        }

                        {permissoes.includes("contratos") &&
                            <Link to={"/centralContratos"} onClick={() => setDrawer(false)} style={{ textDecoration: "none" }}>
                                <ListItem>
                                    <ListItemButton selected={pathname == "/centralContratos"}>
                                        <ListItemDecorator><IoDocumentTextOutline fontSize={20} /></ListItemDecorator>
                                        <ListItemContent style={{ fontSize: "14px", userSelect: "none" }}>CONTRATOS</ListItemContent>
                                        {notificacoes.contratos > 0 ?
                                            <Tooltip title="Contratos aguardando sua aprovação" variant="solid">
                                                <Typography fontSize={12} sx={{ backgroundColor: "#ffe082", width: "22px", padding: "2px 7px", textAlign: "center", borderRadius: "10px", color: "#555", fontWeight: "bold" }}>{notificacoes.contratos > 99 ? "99+" : notificacoes.contratos}</Typography>
                                            </Tooltip>
                                            :
                                            <IoChevronForwardSharp />
                                        }
                                    </ListItemButton>
                                </ListItem>
                            </Link>
                        }

                        {permissoes.includes("admin") &&
                            <Stack>
                                <Link to={"/usuarios"} onClick={() => setDrawer(false)} style={{ textDecoration: "none" }}>
                                    <ListItem>
                                        <ListItemButton selected={pathname == "/usuarios"}>
                                            <ListItemDecorator><LiaUsersCogSolid fontSize={22} /></ListItemDecorator>
                                            <ListItemContent style={{ fontSize: "14px", userSelect: "none" }}>USUÁRIOS E GRUPOS</ListItemContent>
                                            <IoChevronForwardSharp />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>
                                <Link to={"/workflow"} onClick={() => setDrawer(false)} style={{ textDecoration: "none" }}>
                                    <ListItem>
                                        <ListItemButton selected={pathname == "/workflow"}>
                                            <ListItemDecorator><GoWorkflow fontSize={22} /></ListItemDecorator>
                                            <ListItemContent style={{ fontSize: "14px", userSelect: "none" }}>GESTÃO DE WORKFLOW</ListItemContent>
                                            <IoChevronForwardSharp />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>
                                <Link to={"/leadTime"} onClick={() => setDrawer(false)} style={{ textDecoration: "none" }}>
                                    <ListItem>
                                        <ListItemButton selected={pathname == "/leadTime"}>
                                            <ListItemDecorator><IoPricetagsOutline fontSize={20} /></ListItemDecorator>
                                            <ListItemContent style={{ fontSize: "14px", userSelect: "none" }}>LEADTIME</ListItemContent>
                                            <IoChevronForwardSharp />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>
                            </Stack>
                        }
                    </List>
                </Stack>
            </Stack>
        </Stack>
    )
}