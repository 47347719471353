import { formatarDataHoraBrasil } from "../../utils/datas";
import { Stack, Table, Button, Card, CardContent, Typography, Input, Alert, FormHelperText, Checkbox } from "@mui/joy";
import { toast } from 'react-toastify';
//icones react
import { FaRegTrashAlt, FaEye, FaFolderOpen, FaTrash, FaInfoCircle } from "react-icons/fa";
import { IoAttach } from "react-icons/io5";
import React, { useState, useEffect, useRef } from 'react';
import { api } from '../../services/api';
import { Link } from "react-router-dom";

let token = "";
let usuarioID = 0;
let usuarioLogin = "";

export default function (props) {

    const inputRef = useRef(null);
    const [anexos, setAnexos] = useState([]);
    const [qtdArquivosSelecionados, setQtdArquivosSelecionados] = useState(0);
    const [nomesArquivosSelecionados, setNomesArquivosSelecionados] = useState([]);

    let url_arquivo = "";

    useEffect(() => {

        //Obtendo o token
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            token = JSON.parse(localStorage.getItem('usuario')).token;
            usuarioID = JSON.parse(localStorage.getItem('usuario')).user.id;
            usuarioLogin = JSON.parse(localStorage.getItem('usuario')).user.login;
        }
        // Recuperar a lista de objetos formData do localStorage ao montar o componente
        listarAnexosProcesso();

        let qtdArquivos = localStorage.getItem("qtdArquivosSelecionados");
        if (qtdArquivos !== null && qtdArquivos !== '') {
            setQtdArquivosSelecionados(localStorage.getItem("qtdArquivosSelecionados"));
        }

        let nomesArquivos = localStorage.getItem("nomesArquivosSelecionados");
        if (nomesArquivos !== null && nomesArquivos !== '') {
            setNomesArquivosSelecionados(localStorage.getItem("nomesArquivosSelecionados").split(','));
        }

    }, []);

    const listarAnexosProcesso = async () => {

        //buscar anexos de todas as solicitações de origem:
        if (props.solicitacoesOrigem && props.solicitacoesOrigem.length > 0) {

            let novosAnexos = [];
            for (let i = 0; i < props.solicitacoesOrigem.length; i++) {
                let solicitacao_id = props.solicitacoesOrigem[i];

                try {
                    await api.get("/anexos/" + solicitacao_id + "/")
                        .then(async (response) => {
                            novosAnexos = novosAnexos.concat(response.data.data);
                        })
                        .catch((err) => {
                            console.error("ops! ocorreu um erro ao buscar anexos: " + err);
                            toast.error(JSON.stringify(err.response.data));
                        });
                } catch (err) {
                    console.error("ops! ocorreu um erro ao trazer anexos: " + err);
                }
            }
            setAnexos(novosAnexos);
            // Atualiza o estado uma vez após o loop
        }
        else if ((props.numeroProcesso !== "")) {
            api.get("/anexos/" + props.numeroProcesso + "/")
                .then((response) => {
                    setAnexos(response.data.data);
                })
                .catch((err) => {
                    console.error("ops! ocorreu um erro ao trazer anexos: " + err);
                    toast.error(JSON.stringify(err.response.data));
                });
        }

    }

    const handleFileChange = (e) => {
        let arquivosSelecionados;
        if (props.anexosSelecionados !== null && props.anexosSelecionados !== "") {
            let target_files = e.target.files;
            arquivosSelecionados = [...target_files, ...props.anexosSelecionados];
        } else {
            arquivosSelecionados = e.target.files;
        }

        setQtdArquivosSelecionados((parseInt(qtdArquivosSelecionados) + parseInt(e.target.files.length)));
        localStorage.setItem("qtdArquivosSelecionados", arquivosSelecionados.length);
        const formData = new FormData();
        Array.from(arquivosSelecionados).forEach((arquivo, indice) => {
            formData.append('file' + (indice + 1), arquivo);
        });
        // for (const arquivo of arquivosSelecionados) {
        // }
        props.setUploadAnexos(formData);
        props.setAnexosSelecionados(arquivosSelecionados);

        //Capturar os nomes dos arquivos para visualizacao:
        const filesParaNomes = Array.from(arquivosSelecionados);
        const nomesArquivos = filesParaNomes.map((file) => file.name);
        setNomesArquivosSelecionados(nomesArquivos);
        localStorage.setItem("nomesArquivosSelecionados", nomesArquivos);

        listarAnexosProcesso();
    };

    const removeArquivo = (anexo_id) => {

        const confirmar_exclusao = window.confirm("Deseja excluir este anexo?");

        if (confirmar_exclusao) {
            api.delete("/anexos/" + anexo_id + "/")
                .then((response) => {
                    toast.info("Anexo removido com sucesso.");
                    listarAnexosProcesso();
                })
                .catch((error => {
                    toast.error("Não foi possível remover este anexo.");
                    toast.error(JSON.stringify(error.response.data));
                }));
        }
    }

    const downloadUrl = (nome_arquivo) => {
        url_arquivo = `${process.env.REACT_APP_API_URL}/anexos/` + props.numeroProcesso + "/" + nome_arquivo;
        return url_arquivo;
    }

    const handleCancelUpload = () => {
        // Reseta o valor do input file para cancelar a seleção de arquivos
        if (inputRef.current) {
            inputRef.current.value = null;
            setQtdArquivosSelecionados(0);
            localStorage.setItem("qtdArquivosSelecionados", '');
            setNomesArquivosSelecionados([]);
            localStorage.setItem("nomesArquivosSelecionados", '');
            props.setUploadAnexos(new FormData());
            props.setAnexosSelecionados(null);
            props.setItensParaFornecedor([]);
        }
    };

    const removerAnexo = (indice, nomeArquivo) => {
        let files = Array.from(props.anexosSelecionados);
        files.splice(indice, 1);

        //Removendo o item dos selecionados caso ele esteja selecionado
        let itens = [...props.itensParaFornecedor];
        if (itens.indexOf(nomeArquivo) != -1) {
            console.log("Excluindo item marcado");
            itens.splice(props.itensParaFornecedor.indexOf(nomeArquivo), 1);
        }
        props.setItensParaFornecedor(itens);

        setQtdArquivosSelecionados(parseInt(files.length));
        localStorage.setItem("qtdArquivosSelecionados", files.length);
        const formData = new FormData();
        files.forEach((arquivo, indice) => {
            formData.append('file' + (indice + 1), arquivo);
        });
        // for (const arquivo of files) {
        // }
        props.setUploadAnexos(formData);
        props.setAnexosSelecionados(files);

        //Capturar os nomes dos arquivos para visualizacao:
        const nomesArquivos = files.map((file) => file.name);
        setNomesArquivosSelecionados(nomesArquivos);
        localStorage.setItem("nomesArquivosSelecionados", nomesArquivos);

        listarAnexosProcesso();
    }

    const selecionarItemFornecedor = (nomeItem) => {
        console.log({ nomeItem });
        let itens = [...props.itensParaFornecedor];
        if (props.itensParaFornecedor.indexOf(nomeItem) != -1) {
            console.log("Existe");
            itens.splice(props.itensParaFornecedor.indexOf(nomeItem), 1);
        }
        else {
            console.log("Não existe");
            itens.push(nomeItem);
        }
        console.log(itens);
        props.setItensParaFornecedor(itens);
    }

    return (
        <Stack mt="30px">
            {props.etapaAtual != "Compras" ?
                <Card variant="soft" sx={props.mobile ? { marginBottom: "10px", padding: 0, backgroundColor: "transparent" } : { marginBottom: "10px" }}>
                    <CardContent sx={props.mobile ? { padding: "0px" } : { padding: "20px" }}>
                        <Stack alignSelf={"center"} width={props.mobile ? "100%" : "600px"}>
                            <Input
                                startDecorator={<FaFolderOpen size={20} />}
                                endDecorator={<Button>Selecionar</Button>}
                                placeholder={qtdArquivosSelecionados + " Arquivo(s)"}
                                onClick={() => inputRef.current.click()}
                            ></Input>
                            <input style={{ display: "none" }} ref={inputRef} type="file" onChange={handleFileChange} multiple /*accept=".jpg, .jpeg, .png, .pdf, .docx"*/ />
                            {nomesArquivosSelecionados.length > 0 ?
                                <Stack backgroundColor="#fff" p={props.mobile ? "10px" : "20px"} mt="10px" borderRadius={"10px"} boxShadow={"md"}>
                                    {props.etapaAtual == "Jurídico" ?
                                        <Stack width={props.mobile ? "85%" : "92%"} mx="10px" mb="15px" px="20px" py="5px" borderRadius={"5px"} backgroundColor="#ff6f00" boxShadow={"sm"} flexDirection={"row"} alignItems={"center"} alignSelf={"center"} mt="15px">
                                            <FaInfoCircle color="#eee" size={props.mobile ? 30 : 20} />
                                            <FormHelperText sx={{ marginLeft: "5px", color: "#eee" }}>Selecione os arquivos a serem enviados para o fornecedor</FormHelperText>
                                        </Stack>
                                        : null
                                    }
                                    {nomesArquivosSelecionados.map((nomeArquivo, indice) => {
                                        console.log(props.itensParaFornecedor);
                                        if (props.itensParaFornecedor) {
                                            return (
                                                <Stack backgroundColor={props.itensParaFornecedor.indexOf(nomeArquivo) != -1 ? { backgroundColor: "#e3f2fd" } : { backgroundColor: "transparent" }} borderRadius={"5px"} key={indice} mb="8px" flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                                                    <Stack flexDirection="row" px="5px" alignItems={"center"}>
                                                        {props.etapaAtual == "Jurídico" ? <Checkbox checked={props.itensParaFornecedor.indexOf(nomeArquivo) != -1} onChange={() => selecionarItemFornecedor(nomeArquivo)} sx={{ marginRight: "10px" }} /> : null}
                                                        {props.mobile ? null : <IoAttach size={20} style={props.itensParaFornecedor.indexOf(nomeArquivo) != -1 ? { backgroundColor: "#ddd", borderRadius: "10px", padding: "5px" } : { backgroundColor: "#eee", borderRadius: "10px", padding: "5px" }} />}
                                                        <Typography ml="5px">{nomeArquivo}</Typography>
                                                    </Stack>
                                                    <Button
                                                        onClick={() => removerAnexo(indice, nomeArquivo)}
                                                        variant="soft"
                                                        color="danger"
                                                    >
                                                        <FaTrash color="red" />
                                                    </Button>
                                                </Stack>
                                            );
                                        }
                                    })}
                                </Stack>
                                : null
                            }

                            {nomesArquivosSelecionados.length > 0 ?
                                <Button
                                    onClick={handleCancelUpload}
                                    // onClick={() => [console.log(props.anexosSelecionados), console.log(props.itensParaFornecedor)]}
                                    color="danger"
                                    size="sm"
                                    sx={{
                                        marginTop: "15px",
                                        width: "100%",
                                    }}
                                >
                                    Limpar a Lista
                                </Button>
                                : null
                            }
                        </Stack>
                    </CardContent>
                </Card>
                : null
            }

            <Stack ml={"10px"} mb={props.mobile ? "20px" : "30px"}>
                <Typography mt={props.mobile ? "10px" : "30px"} sx={{ fontWeight: "bold" }} level="body-sm">Legenda</Typography>
                <Stack flexDirection={"row"} mt="10px">
                    <Stack flexDirection={"row"} alignItems="center">
                        <div style={{ width: "15px", height: "15px", backgroundColor: "#ffe082" }}></div>
                        <Typography ml={"10px"} level="body-sm">Anexos para enviar ao fornecedor</Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Table hoverRow stripe="even" noWrap aria-label="basic table">
                <thead>
                    <tr>
                        <th style={props.mobile ? { height: "20px" } : { height: "25px" }}>Nome do Arquivo</th>
                        <th style={props.mobile ? { display: "none" } : { height: "25px", width: "250px" }}>Usuário que anexou</th>
                        <th style={props.mobile ? { display: "none" } : { height: "25px", width: "200px", }}>Data da Inclusão</th>
                        <th style={props.mobile ? { width: "60px", height: "20px" } : { width: "100px", height: "25px", textAlign: "center" }}>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {anexos.map((anexo, index) => {
                        return (
                            <tr key={index} style={anexo.contrato ? { backgroundColor: "#ffe082" } : {}}>
                                <td style={{ padding: "0 10px" }}>{anexo.nomeArquivo}</td>
                                <td style={props.mobile ? { display: "none" } : { padding: "0 10px" }}>{anexo.usuarioResponsavel}</td>
                                <td style={props.mobile ? { display: "none" } : { padding: "0 10px" }}>{formatarDataHoraBrasil(anexo.created_at)}</td>
                                <td style={{ padding: "0 10px", textAlign: "center" }}>

                                    <Link to={downloadUrl(anexo.nomeArquivo)} download target="_blank"><FaEye size={20} color="#0077b6"></FaEye></Link>

                                    { usuarioLogin == anexo.usuarioResponsavel ?
                                        <>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Link onClick={() => removeArquivo(anexo.id)}><FaRegTrashAlt size={20} color="#990000"></FaRegTrashAlt></Link>
                                        </>
                                    :
                                    null
                                    }

                                </td>
                            </tr>
                        )
                    })
                    }
                </tbody>
            </Table>
        </Stack>
    );
}