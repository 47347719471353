import { Modal, ModalDialog, ModalClose, Typography, List, Stack, Divider } from "@mui/joy";
import { dataEHora, formatarData } from '../../utils/datas';

export default function UltimasCompras(props) {
    return (
        <Modal
            open={props.open}
            onClose={() => props.setOpen(false)}
        >
            <ModalDialog
                aria-labelledby="variant-modal-title"
                aria-describedby="variant-modal-description"
                variant={"soft"}>
                <ModalClose />
                <Typography
                    id="basic-list-demo"
                    level="body-xs"
                    textTransform="uppercase"
                    fontWeight="lg"
                >
                    Ultimas Compras
                </Typography>
                <Stack overflow={"auto"} mt="20px">
                    <List aria-labelledby="basic-list-demo">
                        <Stack mb="20px">
                            <Typography level="title-lg">Ultima Compra:</Typography>
                            <Typography>Fornecedor: {props.data.ULTIMOFORNECEDOR ? props.data.ULTIMOFORNECEDOR : "Não Informado"}</Typography>
                            <Typography>Data: {props.data.ULTIMODATA ? formatarData(dataEHora(props.data.ULTIMODATA).data) : "Não Informado"}</Typography>
                            <Typography>Quantidade: {props.data.ULTIMOQTD ? props.data.ULTIMOQTD : "Não Informado"}</Typography>
                            <Typography>Valor Unitário: {props.data.ULTIMODATA ? "R$" + parseFloat(props.data.ULTIMOVALOR).toFixed(2).replace(".", ",") : "Não Informado"}</Typography>
                            {props.data.ULTIMOOBJ ? <Typography>O.S: {props.data.ULTIMOOBJ ? props.data.ULTIMOOBJ.split(" - ")[0] : ""}</Typography> : null}
                            {props.data.ULTIMOOBJ ? <Typography>Cód. Equip: {props.data.ULTIMOOBJ ? props.data.ULTIMOOBJ.split(" - ")[1] : ""}</Typography> : null}
                        </Stack>
                        <Stack mb="20px">
                            <Typography level="title-lg">Penúltima Compra:</Typography>
                            <Typography>Fornecedor: {props.data.PENULTIMOFORNECEDOR ? props.data.PENULTIMOFORNECEDOR : "Não Informado"}</Typography>
                            <Typography>Data: {props.data.PENULTIMODATA ? formatarData(dataEHora(props.data.PENULTIMODATA).data) : "Não Informado"}</Typography>
                            <Typography>Quantidade: {props.data.PENULTIMOQTD ? props.data.PENULTIMOQTD : "Não Informado"}</Typography>
                            <Typography>Valor Unitário: {props.data.PENULTIMOVALOR ? "R$" + parseFloat(props.data.PENULTIMOVALOR).toFixed(2).replace(".", ",") : "Não Informado"}</Typography>
                            {props.data.ULTIMOOBJ ? <Typography>O.S: {props.data.PENULTIMOOBJ ? props.data.PENULTIMOOBJ.split(" - ")[0] : ""}</Typography> : null}
                            {props.data.ULTIMOOBJ ? <Typography>Cód. Equip: {props.data.PENULTIMOOBJ ? props.data.PENULTIMOOBJ.split(" - ")[1] : ""}</Typography> : null}
                        </Stack>
                        <Stack>
                            <Typography level="title-lg">Antepenúltima Compra:</Typography>
                            <Typography>Fornecedor: {props.data.ANTEPENULTIMOFORNECEDOR ? props.data.ANTEPENULTIMOFORNECEDOR : "Não Informado"}</Typography>
                            <Typography>Data: {props.data.ANTEPENULTIMODATA ? formatarData(dataEHora(props.data.ANTEPENULTIMODATA).data) : "Não Informado"}</Typography>
                            <Typography>Quantidade: {props.data.ANTEPENULTIMOQTD ? props.data.ANTEPENULTIMOQTD : "Não Informado"}</Typography>
                            <Typography>Valor Unitário: {props.data.ANTEPENULTIMOQTD ? "R$" + parseFloat(props.data.ANTEPENULTIMOVALOR).toFixed(2).replace(".", ",") : "Não Informado"}</Typography>
                            {props.data.ULTIMOOBJ ? <Typography>O.S: {props.data.ANTEPENULTIMOOBJ ? props.data.ANTEPENULTIMOOBJ.split(" - ")[0] : ""}</Typography> : null}
                            {props.data.ULTIMOOBJ ? <Typography>Cód. Equip: {props.data.PENULTIMOOBJ ? props.data.PENULTIMOOBJ.split(" - ")[1] : ""}</Typography> : null}
                        </Stack>
                    </List>
                </Stack>
            </ModalDialog>
        </Modal>
    );
}