import '@fontsource/inter';
import Rotas from './routes';

function App() {
  return (
      <Rotas />
  );
}

export default App;
