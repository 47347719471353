function dataAtual(meses) {
  const currentDate = new Date();
  if (meses) {
    currentDate.setMonth(new Date().getMonth() + (meses));
  }
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Os meses são indexados a partir de 0
  const day = String(currentDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function formatarData(data) {
  const partes = data.split('-'); // Divide a data em partes: ano, mês e dia
  if (partes.length !== 3) {
    return 'Data inválida';
  }

  const ano = partes[0];
  const mes = partes[1];
  const dia = partes[2];

  // Formate a data no formato "dd/mm/yyyy"
  const dataFormatada = `${dia}/${mes}/${ano}`;

  return dataFormatada;
}

function formatarDataHoraBrasil(dataHoraString) {
  const dataHoraUTC = new Date(dataHoraString);

  if (isNaN(dataHoraUTC.getTime())) {
    return "Data e hora inválidas";
  }

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: undefined,
  };

  const formatoBrasil = dataHoraUTC.toLocaleDateString("pt-BR", options);
  return formatoBrasil;
}

function formatarDataBrasil(dataHoraString) {
  const dataHoraUTC = new Date(dataHoraString);

  if (isNaN(dataHoraUTC.getTime())) {
    return "Data e hora inválidas";
  }

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZoneName: undefined,
  };

  const formatoBrasil = dataHoraUTC.toLocaleDateString("pt-BR", options);
  return formatoBrasil;
}

function dataEHora(datetime) {
  const dateObj = new Date(datetime);

  // Extrai a data (yyyy-mm-dd)
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');

  // Extrai a hora (hh:mm)
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours}:${minutes}`;

  return {
    data: formattedDate,
    hora: formattedTime
  };
}

export { dataAtual, formatarData, formatarDataHoraBrasil, formatarDataBrasil, dataEHora };