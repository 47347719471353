import {
    Link,
    Stack,
    Menu,
    MenuButton,
    MenuItem,
    Dropdown,
    Typography,
    Divider
} from '@mui/joy';

export default function Legenda(props) {
    /* 
        Componente da legenda que aparece nas telas de listagem (requisições, compras e contratos)
    */
    return (
        <>
        <Typography mt={props.mobile ? "10px" : "30px"} sx={{ fontWeight: "bold" }} level="body-sm">Legenda</Typography>
            <Stack flexDirection={"row"} mt="10px">
                <Stack flexDirection={"row"} alignItems="center">
                    <div style={{ width: "15px", height: "15px", backgroundColor: "#ffe082" }}></div>
                    <Typography ml={"10px"} level="body-sm">Aguardando sua aprovação</Typography>
                </Stack>
                <Stack flexDirection={"row"} alignItems="center" ml="20px">
                    <div style={{ width: "15px", height: "15px", backgroundColor: "#ef9a9a" }}></div>
                    <Typography ml={"10px"} level="body-sm">Reprovados</Typography>
                </Stack>
            </Stack>
        </>
    )
}