import { api } from '../../../../services/api';
import { useRef } from 'react';
import { React, useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { formatarDataHoraBrasil, formatarDataBrasil } from '../../../../utils/datas';
import {
    Button,
    Stack,
    Typography,
    Table,
    Modal,
    ModalDialog,
    ModalClose,
    Sheet,
    FormControl,
    FormLabel,
    Input,
    Divider,
    Box,
    Select,
    Option,
    ToggleButtonGroup,
    Switch,
    Textarea,
    FormHelperText,
    Link,
} from "@mui/joy";
// import { Link } from "react-router-dom";
import { IoChevronBack, IoAlbumsOutline } from 'react-icons/io5';
import { Pagination } from '@mui/material/';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { FaRegTrashAlt, FaEye, FaFolderOpen } from "react-icons/fa";
import {Link as ReactLink} from "react-router-dom";

import useMediaQuery from '@mui/material/useMediaQuery';
let token = "";
let usuarioID = 0;
let usuarioLogin = "";

export default function CadastroFollowUp(props) {

    const navigate = useNavigate();
    const { id_param } = useParams(); // id do movimento compra passado por url
    const { num_1108 } = useParams();
    const mobile = useMediaQuery('(max-width:1200px)');
    const [listaFollowUp, setListaFollowUp] = useState([]);
    const [novoFollowUp, setNovoFollowUp] = useState(true);
    //campos cadastro followUp
    const [tipoContato, setTipoContato] = useState();
    const [dataEfetivaEntrega, setDataEfetivaEntrega] = useState('');
    const [comentario, setComentario] = useState('');
    const [followUpId, setFollowUpId] = useState();

    const [modalCadastro, setModalCadastro] = useState(false);
    const [erro, setErro] = useState({
        tipoContato: "",
        comentario: "",
        dataEfetivaEntrega: "",
        tipoContatoInvalido: false,
        comentarioInvalido: false,
        dataEfetivaEntregaInvalido: false
    });

    //variaveis anexo
    const inputRef = useRef(null);
    const [modalAnexo, setModalAnexo] = useState(false);
    const [qtdArquivosSelecionados, setQtdArquivosSelecionados] = useState(0);
    const [anexosSelecionados, setAnexosSelecionados] = useState([]);
    const [nomesArquivosSelecionados, setNomesArquivosSelecionados] = useState([]);
    const [uploadAnexos, setUploadAnexos] = useState(new FormData());
    const [anexos, setAnexos] = useState([]);
    const [readMode, setReadMode] = useState(false);
    const [adm, setAdm] = useState(false);

    let url_arquivo = "";
    
    useEffect(() => {
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            token = JSON.parse(localStorage.getItem('usuario')).token;
            usuarioID = JSON.parse(localStorage.getItem('usuario')).user.id;
            usuarioLogin = JSON.parse(localStorage.getItem('usuario')).user.login;
        }

        //Verificando se o usuário é administrador
        if (JSON.parse(localStorage.getItem("usuario")).grupo.length > 0) {
            const adm = JSON.parse(localStorage.getItem("usuario")).grupo.filter(grupo => grupo.nome == "ADM");
            if (adm.length > 0) {
                setAdm(true);
            }
        }

        if (id_param) {
            //parametro é o id da cotacao, buscar todos registros de followUp da cotacao informada:
            listaRegistroFollowUp(id_param);
        }

    }, []);

    const limpaModalAnexo = () => {
        inputRef.current.value = null;
        setQtdArquivosSelecionados(0);
        setAnexosSelecionados([]);
        setNomesArquivosSelecionados([]);
        setUploadAnexos(new FormData());
    }

    const handleFileChange = (e) => {
        let arquivosSelecionados;
        if (anexosSelecionados !== null && anexosSelecionados !== "") {
            let target_files = e.target.files;
            arquivosSelecionados = [...target_files, ...anexosSelecionados];
        } else {
            arquivosSelecionados = e.target.files;
        }

        setQtdArquivosSelecionados((parseInt(qtdArquivosSelecionados) + parseInt(e.target.files.length)));
        // localStorage.setItem("qtdArquivosSelecionados", arquivosSelecionados.length);

        const formData = new FormData();
        for (const arquivo of arquivosSelecionados) {
            formData.append('file' + Math.random() * 100, arquivo);
        }
        setUploadAnexos(formData);
        setAnexosSelecionados(arquivosSelecionados);

        //Capturar os nomes dos arquivos para visualizacao:
        const filesParaNomes = Array.from(arquivosSelecionados);
        const nomesArquivos = filesParaNomes.map((file) => file.name);
        setNomesArquivosSelecionados(nomesArquivos);
        // localStorage.setItem("nomesArquivosSelecionados", nomesArquivos);
        //listarAnexosProcesso();
    };

    const handleCancelUpload = () => {
        // Reseta o valor do input file para cancelar a seleção de arquivos
        if (inputRef.current) {
            inputRef.current.value = null;
            setQtdArquivosSelecionados(0);
            //localStorage.setItem("qtdArquivosSelecionados", '');
            setNomesArquivosSelecionados([]);
            //localStorage.setItem("nomesArquivosSelecionados", '');
            setUploadAnexos(new FormData());
            setAnexosSelecionados(null);
        }
    };

    const listaRegistroFollowUp = (cotacao_id) => {
        /*
         Carrega a lista de registros de comentarios do follow Up de uma determinada cotacao_id 
         */
        api.get("/followup/" + cotacao_id)
            .then((response) => {
                setListaFollowUp(response.data);
            })
            .catch((err) => {
                console.error("Ops! ocorreu um erro lista-registro-followUp: " + err);
            });
    }

    const adicionaRegistroFollowUp = () => {

        // Validação dos campos antes de salvar no banco:
        const erros = [];
        setErro({ tipoContato: "", comentario: "", dataEfetivaEntrega: "", tipoContatoInvalido: false, comentarioInvalido: false, dataEfetivaEntregaInvalido: false });

        if (!comentario) {
            erros.comentario = 'Informe o comentário.';
            erros.comentarioInvalido = true;
        }
        if (!tipoContato) {
            erros.tipoContato = 'Informe o tipo de contato.';
            erros.tipoContatoInvalido = true;
        }
        if (!dataEfetivaEntrega) {
            erros.dataEfetivaEntrega = 'Informe a data efetiva de entrega.';
            erros.dataEfetivaEntregaInvalido = true;
        }

        if (Object.keys(erros).length > 0) {
            setErro(erros);
        } else {
            let novoObjeto = {
                cotacao_id: id_param,
                tipoContato: tipoContato,
                dataEfetivaEntrega: dataEfetivaEntrega,
                comentario: comentario,
                usuario_id: usuarioID
            }
            api.post("/followup/", novoObjeto)
                .then((response) => {
                    setModalCadastro(false);
                    limpaFormCadastro();
                    listaRegistroFollowUp(id_param);

                })
                .catch((err) => {
                    console.error("Ops! ocorreu um erro lista-registro-followUp: " + err);
                });
        }
    }

    const limpaFormCadastro = () => {
        setTipoContato("");
        setDataEfetivaEntrega("");
        setComentario("");
        setReadMode(false);
    }


    const exibirModalCadastro = () => {
        setModalCadastro(true);
        setReadMode(false);
    }

    const exibirModalAnexo = (followup_id) => {
        setModalAnexo(true);
        setFollowUpId(followup_id);
        listarAnexoFollowUp(followup_id);
    }

    const salvarAnexoFollowUp = () => {
        let id_toast = toast.loading("Salvando anexo..");

        api.post(`/anexosFollowup?followup_id=${followUpId}&responsavel=${usuarioID}`,
            uploadAnexos,
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(function (response) {
            toast.update(id_toast, { render: "Anexos salvos com Sucesso!", type: "success", isLoading: false, autoClose: 3000 });
            limpaModalAnexo();
            listarAnexoFollowUp(followUpId);
            //navigate("/centralTarefas");
        }).catch(function (err) {
            console.error(err);
            toast.update(id_toast, { render: "Não foi possível salvar anexo. Por favor, tente novamente.", type: "error", isLoading: false, });
        })
    }

    const listarAnexoFollowUp = async (followup_id) => {

        await api.get("/anexosFollowup/" + followup_id + "/")
            .then(async (response) => {
                setAnexos(response.data.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro ao buscar anexos: " + err);
            });
    }

    const removeArquivo = (anexo_id) => {
        const confirmar_exclusao = window.confirm("Deseja excluir este anexo?");

        if (confirmar_exclusao) {
            api.delete("/anexosFollowup/" + anexo_id + "/")
                .then((response) => {
                    toast.info("Anexo removido com sucesso.");
                    listarAnexoFollowUp(followUpId);
                })
                .catch(error => {
                    toast.error("Não foi possível remover este anexo.");
                })
        }
    }


    const downloadUrl = (nome_arquivo) => {
        url_arquivo = `${process.env.REACT_APP_API_URL}/anexosFollowup/` + followUpId + "/" + nome_arquivo;
        return url_arquivo;
    }

    const visualizaRegistroModal = (tipoContato, dataEfetivaEntrega, comentario) => {
        /* 
            Método para transformar o modal de cadastro em somente visualizacao:
        */
        setTipoContato(tipoContato);
        setDataEfetivaEntrega(dataEfetivaEntrega);
        setComentario(comentario);
        setModalCadastro(true);
        setReadMode(true);
    }

    return (
        <>
            <Stack backgroundColor="#F5F5F5" height="100vh">

                <Stack flexDirection={"row"} overflow={"auto"} justifyContent="space-between" alignItems="center" backgroundColor="#222" padding="10px 20px">
                    <Typography sx={{ color: "#eee", fontSize: 13 }}>FOLLOW UP</Typography>
                    <Stack flexDirection={"row"}>
                        
                        { adm ?
                            <Stack flexDirection={"row"} mr={mobile? "10px": "40px"}>
                                <Link onClick={()=> navigate("/temposDiligenciamento")} sx={{ color: "#fff", marginLeft: "10px", fontSize: 13 }}>Tempos de Diligenciamento</Link>
                            </Stack>
                            :
                            null
                        }

                        <Stack flexDirection={"row"} mr={mobile? "10px": "40px"}>
                            <Link onClick={() => navigate("/modulos")} sx={{ color: "#888", marginLeft: "10px", fontSize: 13 }}>Ir para módulos</Link>
                        </Stack>
                        <Stack flexDirection={"row"}>
                            <Typography sx={{ color: "#eee" }} level='body-sm'>{ usuarioLogin }</Typography>
                            <Link sx={{ color: "#888", marginLeft: "10px" }} onClick={() => [navigate("/"), localStorage.setItem("usuario", null)]}>sair</Link>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack ml={mobile ? "0px" : "30px"} mt="50px" overflow={"auto"} height={mobile ? "100%" : "calc(100vh - 200px)"} p={mobile ? "20px" : "50px"}>
                    {/* Conteúdo da Tela */}
                    <Typography level="h2">Cadastro Follow Up</Typography>
                    <Typography level="h4">Número OC: {num_1108} </Typography>

                    <Typography mt={mobile ? "10px" : "30px"} sx={{ fontWeight: "bold" }} level="body-sm">Legenda</Typography>
                    <Stack flexDirection={"row"} mt="10px">
                        <Stack flexDirection={"row"} alignItems="center">
                            <div style={{ width: "15px", height: "15px", backgroundColor: "#fcbf49" }}></div>
                            <Typography ml={"10px"} level="body-sm">Registro Ativo</Typography>
                        </Stack>
                    </Stack>

                    <Stack display={"flex"} justifyContent={"space-between"} flexDirection="row" mt={"10px"}>
                        <Stack flexDirection="row" alignItems="center">
                            <Button onClick={() => navigate("/followup")} color="neutral">
                                <IoChevronBack size={20} />
                                Voltar
                            </Button>
                            {/* <Typography ml="10px" level="body-md">Processo: #{numeroProcesso}</Typography> */}
                        </Stack>
                        {/*TODO: Somente um grupo especifico (adm?) poderá adicionar registro de followup */}
                        { adm  ?
                          <Stack display={"flex"} ml={mobile ? "0" : "30px"} flexDirection={"row"} alignItems="end" justifyContent={"flex-end"}>
                            <Button onClick={() => exibirModalCadastro()}>Adicionar Novo Registro</Button>
                          </Stack>
                          :
                          null
                        }
                    </Stack>

                    {/* Seção de Modal de cadastro de registro followUp */}


                    <Modal
                        aria-labelledby="modal-title"
                        aria-describedby="modal-desc"
                        open={modalCadastro}
                        disableScrollLock={true}
                        onClose={() => [setModalCadastro(false), limpaFormCadastro()]}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Sheet
                            variant="outlined"
                            sx={{
                                maxWidth: "80%",
                                borderRadius: 'md',
                                p: 3,
                                boxShadow: 'lg',
                            }}
                        >
                            <ModalClose
                                variant="outlined"
                                sx={{
                                    top: 'calc(-1/4 * var(--IconButton-size))',
                                    right: 'calc(-1/4 * var(--IconButton-size))',
                                    boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                                    borderRadius: '50%',
                                    bgcolor: 'background.surface',
                                }}
                            />
                            <Typography
                                component="h2"
                                id="modal-title"
                                level="h4"
                                textColor="inherit"
                                fontWeight=""
                                mb={1}
                                sx={{ color: "#1e88e5" }} >

                                { readMode ?
                                    "Visualizar Registro"
                                    :
                                    "Adicionar Registro"
                                }
                                
                            </Typography>
                            <Stack maxHeight={"100%"}>
                                <Stack>
                                    <Stack flexDirection={mobile ? "column" : "row"} mt="30px">

                                        <Stack flex={1} maxWidth={mobile ? "" : "240px"} minWidth={mobile ? "100%" : "130px"} ml={mobile ? "0" : "10px"} mt={mobile ? "20px" : "5px"}>
                                            <FormControl error={erro.tipoContatoInvalido} sx={{ mb: "10px" }}>
                                                <Typography fontSize="14px">Tipo de Contato</Typography>
                                                <Select disabled={readMode} value={tipoContato} onChange={(e, newValue) => setTipoContato(newValue)}>
                                                    <Option key="email" value="email">E-mail</Option>
                                                    <Option key="telefone" value="telefone">Telefone</Option>
                                                    <Option key="whatsapp" value="whatsapp">WhatsApp</Option>
                                                    <Option key="presencial" value="presencial">Presencial</Option>
                                                </Select>

                                                {erro.tipoContatoInvalido && (
                                                    <FormHelperText error={erro.tipoContatoInvalido}>
                                                        {erro.tipoContato}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Stack>

                                        <Stack flex={1} maxWidth={mobile ? "" : "240px"} minWidth={mobile ? "100%" : "150px"} ml={mobile ? "0" : "10px"} mt={mobile ? "20px" : ""}>
                                            <FormControl error={erro.dataEfetivaEntregaInvalido} sx={{ mb: "10px" }}>
                                                <FormLabel>Data Efetiva Entrega</FormLabel>
                                                <Input disabled={readMode} type="date" value={dataEfetivaEntrega} onChange={(e) => setDataEfetivaEntrega(e.target.value)} />

                                                {erro.dataEfetivaEntregaInvalido && (
                                                    <FormHelperText error={erro.dataEfetivaEntregaInvalido}>
                                                        {erro.dataEfetivaEntrega}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                        </Stack>
                                    </Stack>
                                    <Stack mt={mobile ? "20px" : "20px"} flexDirection={"row"} alignItems="center" justifyContent={"space-between"}>
                                        <Stack flex={1} maxWidth={mobile ? "" : "510px"} minWidth={mobile ? "100%" : "160px"} ml={mobile ? "0" : "10px"}>

                                            <FormControl disabled={readMode} error={erro.comentarioInvalido} sx={{ mb: "10px" }}>
                                                <FormLabel>Comentário</FormLabel>
                                                <Textarea minRows={5} value={comentario} onChange={(e) => setComentario(e.target.value)}></Textarea>

                                                {erro.comentarioInvalido && (
                                                    <FormHelperText error={erro.comentarioInvalido}>
                                                        {erro.comentario}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>

                                        </Stack>
                                    </Stack>
                                    <Stack ml={mobile ? "0" : "30px"} flexDirection={"row"} mt={mobile ? "20px" : "20px"} alignItems="center" justifyContent={"flex-end"}>
                                        <Button disabled={readMode} onClick={adicionaRegistroFollowUp}>Salvar</Button>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Sheet>
                    </Modal>


                    {/* Modal Add Anexos  */}
                    <Modal
                        aria-labelledby="modal-title"
                        aria-describedby="modal-desc"
                        open={modalAnexo}
                        onClose={() => [setModalAnexo(false), limpaModalAnexo()]}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: mobile ? 'column' : 'row' }}
                    >
                        <Sheet
                            variant="outlined"
                            sx={{
                                maxWidth: mobile? "300px": "1000px",
                                borderRadius: 'md',
                                p: 3,
                                boxShadow: 'lg',
                                width: mobile ? '90%' : 'auto',
                            }}
                        >
                            <ModalClose
                                variant="outlined"
                                sx={{
                                    top: 'calc(-1/4 * var(--IconButton-size))',
                                    right: 'calc(-1/4 * var(--IconButton-size))',
                                    boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                                    borderRadius: '50%',
                                    bgcolor: 'background.surface',
                                }}
                            />
                            <Typography
                                component="h2"
                                id="modal-title"
                                level="h4"
                                textColor="inherit"
                                fontWeight=""
                                mb={1}
                                sx={{ color: "#1e88e5" }} >
                                Adicionar Anexo
                            </Typography>
                            <Stack maxHeight={"100%"}>
                                <Stack>
                                    <Stack flexDirection={mobile ? "column" : "row"} mt="30px">

                                        <Stack flex={1} maxWidth={mobile ? "" : "280px"} minWidth={mobile ? "100%" : "130px"} ml={mobile ? "0" : "10px"} mt={mobile ? "20px" : "5px"}>
                                            <FormControl sx={{ mb: "10px" }}>
                                                <Typography fontSize="14px">Anexo</Typography>
                                                <Input
                                                    startDecorator={<FaFolderOpen size={20} />}
                                                    endDecorator={<Button>Selecionar</Button>}
                                                    placeholder={qtdArquivosSelecionados + " Arquivo(s)"}
                                                    onClick={() => inputRef.current.click()}
                                                ></Input>
                                                <input style={{ display: "none" }} ref={inputRef} type="file" onChange={handleFileChange} />
                                                <Typography level="title-md">Arquivos selecionados: {nomesArquivosSelecionados.join(', ')}</Typography>

                                            </FormControl>
                                        </Stack>

                                    </Stack>
                                    <Stack ml={mobile ? "0" : "10px"} flexDirection={"row"} mt={mobile ? "20px" : "20px"} alignItems="center" justifyContent={"space-between"}>
                                        <Button onClick={handleCancelUpload}
                                            color="neutral"
                                            size="sm"
                                            sx={{
                                                marginTop: "1px",
                                                width: "150px",
                                            }}
                                        >Cancelar Envio</Button>
                                        <Button onClick={salvarAnexoFollowUp}>Salvar Anexo</Button>
                                    </Stack>
                                </Stack>

                                <Stack sx={{overflow: mobile? 'scroll': "" }}>

                                    <Table hoverRow stripe="even" noWrap>
                                    <thead>
                                        <tr>
                                            <th style={{ height: "25px", width: "180px" }}>Nome</th>
                                            <th style={{ height: "25px", width: "100px" }}>Usuário</th>
                                            <th style={{ height: "25px", width: "100px" }}>Criação</th>
                                            <th style={{ heigth: "25px", width: mobile? "60px": "30px" }}>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {anexos.map((anexo, index) => (
                                            <tr key={index}>
                                                <td style={{ padding: "0 10px" }}>{anexo.nomeArquivo}</td>
                                                <td style={props.mobile ? { display: "none" } : { padding: "0 10px" }}>{anexo.usuario.nome}</td>
                                                <td style={props.mobile ? { display: "none" } : { padding: "0 10px" }}>{formatarDataHoraBrasil(anexo.created_at)}</td>
                                                <td style={{ padding: "0 10px", textAlign: "center" }}>
                                                    
                                                    <ReactLink to={downloadUrl(anexo.nomeArquivo)} download target="_blank"><FaEye size={20} color="#0077b6"></FaEye></ReactLink>

                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <Link onClick={() => removeArquivo(anexo.id)}><FaRegTrashAlt size={20} color="#990000"></FaRegTrashAlt></Link>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                    </Table>
                                </Stack>

                            </Stack>
                        </Sheet>
                    </Modal>

                    {/* Fim Modal Add Anexos */}

                    {mobile ?
                        <Stack mt="30px">
                            <Stack>

                                {listaFollowUp.map(function (item, indice) {
                                    if (!Array.isArray(item)) {
                                        return (
                                            <div key={indice} className="hover" onClick={() => console.log("clicou follow up")}>
                                                <Stack style={{ backgroundColor: item.ativo? "#fcbf49": ""}} p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                                                    <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Data Cadastro:</Typography> {formatarDataHoraBrasil(item.created_at)}</Typography>
                                                    <Typography level="body-sm"><Typography level="title-md">Tipo Contato:</Typography>{item.tipoContato}</Typography>
                                                    <Typography level="body-sm"><Typography level="title-md">Comentário:</Typography>{item.comentario}</Typography>
                                                    <Typography level="body-sm"><Typography level="title-md">Usuário:</Typography>{item.usuario.nome}</Typography>
                                                    <Typography level="body-sm"><Typography level="title-md">Data Efetiva Entrega: </Typography>{formatarDataBrasil(item.dataEfetivaEntrega)}</Typography>
                                                    <Typography level="body-sm"><Typography level="title-md">Anexos: </Typography><IoAlbumsOutline onClick={() => exibirModalAnexo(item.id)}></IoAlbumsOutline></Typography>
                                                    <Typography level="body-sm"><Typography level="title-md">Ações: </Typography><FaEye size={20} color="#0077b6" onClick={() => visualizaRegistroModal(item.tipoContato, item.dataEfetivaEntrega.split(" ")[0], item.comentario)}></FaEye></Typography>
                                                    
                                                </Stack>
                                            </div>
                                        )
                                    }
                                })}

                            </Stack>
                        </Stack>
                        :
                        <Stack mt="50px">
                            {/* Visualizacao desktop */}
                            <Table hoverRow stripe="even" noWrap>
                                <thead>
                                    <tr>
                                        <th style={{ height: "25px", width: "180px" }}>Data Cadastro</th>
                                        <th style={{ height: "25px", width: "300px" }}>Comentário</th>
                                        <th style={{ height: "25px", width: "100px" }}>Tipo Contato</th>
                                        <th style={{ height: "25px", width: "100px" }}>Usuário Responsável</th>
                                        <th style={{ height: "25px", width: "150px" }}>Data Efetiva Entrega</th>
                                        <th style={{ heigth: "25px", width: "50px" }}>Anexos</th>
                                        <th style={{ heigth: "25px", width: "50px" }}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listaFollowUp.map(function (item, indice) {
                                        // ignorar os arrays de itens recebidos que nao pertencem a esse component
                                        if (!Array.isArray(item)) {
                                            return (
                                                <tr key={indice} className="hover" style={{ backgroundColor: item.ativo? "#fcbf49": ""}}>
                                                    <td style={{ padding: "0 10px" }}>
                                                        {formatarDataHoraBrasil(item.created_at)}</td>
                                                    <td style={{ padding: "0 10px" }}>
                                                        {item.comentario}</td>
                                                    <td style={{ padding: "0 10px" }}>
                                                        {item.tipoContato}</td>
                                                    <td style={{ padding: "0 10px" }}>
                                                        {item.usuario.nome}</td>
                                                    <td style={{ padding: "0 10px" }}>
                                                        {formatarDataBrasil(item.dataEfetivaEntrega)}</td>
                                                    <td style={{ padding: "0 10px" }} onClick={() => exibirModalAnexo(item.id)}>
                                                        <IoAlbumsOutline /></td>
                                                    <td style={{ padding: "0 10px" }}>
                                                    <FaEye size={20} color="#0077b6" onClick={() => visualizaRegistroModal(item.tipoContato, item.dataEfetivaEntrega.split(" ")[0], item.comentario)}></FaEye></td>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </Table>

                        </Stack>
                    }

                    {/* <Stack mt={3} alignItems={mobile ? "center" : "flex-end"}>
                            <Pagination count={lastPageUsuario} page={pageUsuario} onChange={handleChangePage} />
                </Stack> */}
                </Stack>


            </Stack>
        </>
    );

}


