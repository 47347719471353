import {
    Modal,
    ModalDialog,
    ModalClose,
    Typography,
    Stack,
    List,
    ListItem,
    Link
} from "@mui/joy"

export default function SelecaoClassificacaoRD(props) {
    return (
        <Modal open={props.openModalListaClassificacaoRD} onClose={() => props.setOpenModalListaClassificacaoRD(false)}>
            <ModalDialog variant={"plain"} >
                <ModalClose />
                <Typography
                    id="basic-list-demo"
                    level="body-xs"
                    textTransform="uppercase"
                    fontWeight="lg"
                    fontSize={"18px"}
                >
                    Seleção de Classificação RD
                </Typography>

                <Stack marginTop={"20px"} sx={{ overflow: "auto", height: "50%" }}>
                    <List>
                        {props.classificacaoRDResultBusca.map(function (item) {
                            return (
                                // pegar o resultado daqui e juntar numa lista de classificacao RD:
                                <ListItem sx={{ borderBottom: 1, borderColor: "#ddd", marginBottom: "5px" }} onClick={() => [props.addClassificacaoRD(item), props.setOpenModalListaClassificacaoRD(false)]}>
                                    <Link fontSize={"16px"}>{item.CODTB2FLX} - {item.DESCRICAO}</Link>
                                </ListItem>
                            )
                        })}
                    </List>
                </Stack>
            </ModalDialog>
        </Modal>
    )
}