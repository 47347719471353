import { useState } from "react";
import { Modal, Sheet, ModalClose, Typography, Stack, FormLabel, Input, Checkbox, Button, Link, Textarea } from "@mui/joy";
import { toast } from 'react-toastify';
import Tooltip from '@mui/joy/Tooltip';

import { FaCircleInfo } from "react-icons/fa6";

//Componentes
import SaldoDosItens from "../SaldoDosItens";
import UltimasCompras from "../UltimasCompras";

//Apis do RM
import { RM } from '../../services/api';
import realizaConsulta from "../../services/rm";

export default function InformacoesItem(props) {

    const novoItem = Object.keys(props.itemTemporario).length > 0;

    const [saldosDoItem, setSaldosDoItem] = useState([]);
    const [ultimasCompras, setUltimasCompras] = useState({});
    const [openModalInfoItem, setOpenModalInfoItem] = useState(false); //Modal de informacoes dos saldos em cada local
    const [modalUltimasCompras, setModalUltimasCompras] = useState(false);

    const infoNaoUsarEstoque = "O botão “Não usar estoque” deve utilizado quando se tem saldo, mas além da quantidade em estoque será necessário a aquisição de mais itens em complemento. Será necessário justificar sua escolha no campo observações.";
    const infoNaoUsarContrato = "O botão “Não usar contrato” deve ser utilizado quando as condições do contrato não atendam a demanda do solicitante, como prazo de entrega, qualidade do produto e etc. Será necessário justificar sua escolha no campo observações.";

    const obterTodosSaldos = (codigoprd) => {
        const id = toast.loading("Buscando todos os saldos");
        realizaConsulta('660617', { params: { 'CODIGOPRD_S': codigoprd } })
            .then(function ({ data }) {
                let locaisComSaldo = [];
                data.forEach(saldo => {
                    if (saldo.SALDOFISICO2 > 0) {
                        locaisComSaldo.push(saldo);
                    }
                });
                if (locaisComSaldo.length > 0) {
                    setSaldosDoItem(locaisComSaldo);
                    setOpenModalInfoItem(true);
                    toast.update(id, { render: "Saldos obtido com sucesso", type: "success", isLoading: false, autoClose: 1000 });
                }
                else {
                    toast.update(id, { render: "O item não possui saldos", type: "warning", isLoading: false, autoClose: 2000 });
                }
            }).catch(function (err) {
                toast.update(id, { render: "Erro ao obter todos os saldos", type: "error", isLoading: false, autoClose: 2000 });
                toast.error(JSON.stringify(err.response.data));
                console.log(err);

            });
    }

    const obterUltimasCompras = (codigoprd) => {
        const id = toast.loading("Buscando ultimas compras");
        realizaConsulta('660609', { params: { 'CODIGOPRD': codigoprd } })
            .then(function ({ data }) {
                toast.dismiss();
                setUltimasCompras(data[0]);
                setModalUltimasCompras(true);
            }).catch(function (err) {
                toast.update(id, { render: "Erro ao obter ultimas compras", type: "error", isLoading: false, autoClose: 2000 });
                toast.error(JSON.stringify(err.response.data));
            });
    }

    //Definindo se os checkbox estarão desabilitados
    const grupos = JSON.parse(localStorage.getItem('usuario')).grupo;
    //TODO: são vários grupos agora:
    // const perfil = grupos.length > 0 ? grupos[0].perfil : "";
    const perfil = grupos.length > 0 ? grupos.map(grupo => grupo.perfil) : [];
    const checkboxDesabilitado = !((
        ((props.formulario.etapaAtual == "Aprov. Técnico") && (perfil.includes(props.formulario.etapaAtual))) ||
        ((props.formulario.etapaAtual == "Solicitante"))
    ));

    return (
        <Stack>
            {Object.keys(props.itemTemporario).length > 0 ?
                <Modal
                    aria-labelledby="modal-title"
                    aria-describedby="modal-desc"
                    open={props.openModal}
                    onClose={() => props.setOpenModal(false)}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        outline: "none",
                    }}
                >
                    <Sheet
                        variant="outlined"
                        sx={{
                            width: "85%",
                            maxWidth: props.mobile ? "100%" : "1000px",
                            borderRadius: 'md',
                            p: props.mobile ? 2 : 3,
                            boxShadow: 'lg',
                            outline: "none",
                        }}
                    >
                        <ModalClose
                            variant="outlined"
                            sx={{
                                top: "calc(-1/4 * var(--IconButton-size))",
                                right: "calc(-1/4 * var(--IconButton-size))",
                                boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                                borderRadius: "50%",
                                bgcolor: "background.surface",
                            }}
                        />
                        <Typography
                            component="h2"
                            id="modal-title"
                            level="h4"
                            textColor="inherit"
                            fontWeight="lg"
                            mb={1}
                        >
                            Informações do Item
                        </Typography>

                        <Stack display={"flex"} sx={props.mobile ? { overflow: "auto", height: "70vh" } : {}}>
                            <Stack flexDirection={props.mobile ? "column" : "row"} mt="20px">
                                <Stack width="100px" flexDirection={"column"} mr="20px">
                                    <FormLabel>Código</FormLabel>
                                    <Input disabled variant="plain" sx={{ margin: 0, padding: 0 }} value={props.itemTemporario.codProduto} />
                                </Stack>
                                <Stack width={props.mobile ? "100%" : "60%"} flexDirection={"column"} mr="20px">
                                    <FormLabel>Descrição</FormLabel>
                                    <Input disabled variant="plain" sx={{ margin: 0, padding: 0 }} value={props.itemTemporario.descricao} />
                                </Stack>
                            </Stack>

                            <Stack flexDirection={"row"} mt="20px">
                                <Stack flexDirection={"column"} mr="10px" sx={props.mobile ? { width: "70px" } : { width: "70px" }}>
                                    <FormLabel>Estoque</FormLabel>
                                    <Input disabled variant="plain" sx={{ margin: 0, padding: 0 }} value={props.itemTemporario.estoque} />
                                </Stack>
                                <Stack flexDirection={"column"} mr="10px" sx={props.mobile ? { width: "50px" } : { width: "70px" }}>
                                    <FormLabel>Min.</FormLabel>
                                    <Input disabled variant="plain" sx={{ margin: 0, padding: 0 }} value={props.itemTemporario.saldoMin} />
                                </Stack>
                                <Stack flexDirection={"column"} mr="10px" sx={props.mobile ? { width: "50px" } : { width: "70px" }}>
                                    <FormLabel>Max.</FormLabel>
                                    <Input disabled variant="plain" sx={{ margin: 0, padding: 0 }} value={props.itemTemporario.saldoMax} />
                                </Stack>
                                <Stack flexDirection={"column"} mr="10px" sx={props.mobile ? { width: "50px" } : { width: "70px" }}>
                                    <FormLabel>Unidade</FormLabel>
                                    <Input disabled variant="plain" sx={{ margin: 0, padding: 0 }} value={props.itemTemporario.CODUND || props.itemTemporario.unidade} />
                                </Stack>
                                {/* <Stack>
                                    <Link>Ver Ultimas Compras</Link>
                                </Stack> */}
                            </Stack>

                            <Stack flexDirection={props.mobile ? "column" : "row"} mt="20px">
                                <Stack flexDirection={"column"} mr="0px" mb={props.mobile ? "20px" : "0"}>
                                    <Link onClick={() => { obterTodosSaldos(props.itemTemporario.codProduto) }} mr="20px">
                                        Ver todos os locais de estoque
                                    </Link>
                                </Stack>
                                <Stack mr="20px">
                                    <Link onClick={() => obterUltimasCompras(props.itemTemporario.codProduto)}>Ver ultimas compras</Link>
                                </Stack>
                                <Stack width="150px" flexDirection={"column"} mr="10px" mt={props.mobile ? "20px" : "0px"}>
                                    <FormLabel>Saldo de Contrato</FormLabel>
                                    <Input disabled variant="plain" sx={{ margin: 0, padding: 0 }} value={props.itemTemporario.saldoContrato} />
                                </Stack>
                                <Stack width="150px" flexDirection={"column"} mr="10px">
                                    <FormLabel>Saldo de O.C</FormLabel>
                                    <Input disabled variant="plain" sx={{ margin: 0, padding: 0 }} value={props.itemTemporario.pedidoAFornecedor} />
                                </Stack>
                                {/* Sla de contrato aparece para o solicitante apenas: */}
                                {
                                    // props.formulario.etapaAtual == "Solicitante" ? 
                                    <Stack width="170px" flexDirection={"column"} mr="10px">
                                        <FormLabel>SLA Item Contrato (dias)</FormLabel>
                                        {console.log(props.itemTemporario)}
                                        <Input disabled variant="plain" sx={{ margin: 0, padding: 0 }} value={props.itemTemporario.slaContrato || props.itemTemporario.SLACONTRATO} />
                                    </Stack>
                                    //  :
                                    //  null
                                }

                            </Stack>

                            <Stack flexDirection={props.mobile ? "column" : "row"} mt="20px">
                                <Stack flexDirection={"column"} mr="30px" mb={props.mobile ? "10px" : "0"}>
                                    <FormLabel>Quantidade</FormLabel>
                                    <Input type="number" placeholder="Quantidade"
                                        value={props.itemTemporario.quantidadeProduto}
                                        onChange={(event) => props.setItemTemporario({ ...props.itemTemporario, quantidadeProduto: event.target.value })} disabled={(props.etapaAtual != "Solicitante") || (props.osVinculada) || (props.formulario.usuario_id ? props.usuarioLogado != props.formulario.usuario_id : false)}
                                        // onKeyUp={(event) => novoItem ? props.setItemTemporario({ ...props.itemTemporario, QUANTIDADEARECEBER: event.target.value.match(/^-/, '') }) : props.atualizarFormulario("QUANTIDADEARECEBER", event.target.value.match(/^-/, ''), true)}
                                        onKeyUp={(event) => props.setItemTemporario({ ...props.itemTemporario, quantidadeProduto: event.target.value.replace("-", "") })}
                                    />
                                </Stack>

                                <Stack flexDirection={"column"} mr="30px" mb={props.mobile ? "10px" : "0"}>
                                    <FormLabel>Não usar estoque 
                                        <Tooltip arrow title={infoNaoUsarEstoque} color="primary" placement="top" variant={"solid"}>
                                        <Button variant="" color="primary"><FaCircleInfo></FaCircleInfo></Button>
                                        </Tooltip>
                                    </FormLabel>

                                    <Checkbox disabled={checkboxDesabilitado || !props.itemTemporario.estoque || props.transferencia} checked={props.itemTemporario.naoUsarEstoque || !props.itemTemporario.estoque}
                                        onChange={(event) => props.setItemTemporario({ ...props.itemTemporario, naoUsarEstoque: event.target.checked })}
                                    />
                                </Stack>

                                <Stack flexDirection={"column"} mr="20px" mb={props.mobile ? "10px" : "0"}>
                                    <FormLabel>Não usar contrato
                                        <Tooltip arrow title={infoNaoUsarContrato} color="primary" placement="top" variant={"solid"}>
                                            <Button variant="" color="primary"><FaCircleInfo></FaCircleInfo></Button>
                                        </Tooltip>
                                    </FormLabel>
                                    <Checkbox
                                        disabled={checkboxDesabilitado || props.transferencia} checked={novoItem ? props.itemTemporario.naoUsarContrato : props.itemTemporario.itens[props.indiceItemConsultado].naoUsarContrato}
                                        onChange={(event) => props.setItemTemporario({ ...props.itemTemporario, naoUsarContrato: event.target.checked })}
                                    />
                                </Stack>
                            </Stack>

                            <Stack flexDirection={props.mobile ? "column" : "row"} mt="20px">
                                <Stack flexDirection={"column"} mr="20px" mb={props.mobile ? "20px" : "0"}>
                                    <FormLabel>Preço Unt. Alvo</FormLabel>
                                    <Input type="text" disabled={true} placeholder="Preço Unt. Alvo" value={props.itemTemporario.precoUntAlvo} />
                                </Stack>
                                <Stack flexDirection={"column"} mr="20px">
                                    <FormLabel>Id. Objeto Oficina</FormLabel>
                                    <Input type="text" disabled={true} placeholder="Id. Objeto Oficina" value={props.itemTemporario.idObjetoOficina} />
                                </Stack>
                            </Stack>

                            <Stack flexDirection={"row"} mt="20px" mb="20px">
                                <Stack flexDirection={"column"} mr="20px" width="100%">
                                    <FormLabel>Observacoes</FormLabel>
                                    <Textarea minRows={4} placeholder="Observações" disabled={(props.etapaAtual != "Solicitante" || props.osVinculada)}
                                        value={props.itemTemporario.observacoesItem}
                                        onChange={(event) => props.setItemTemporario({ ...props.itemTemporario, observacoesItem: event.target.value })}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>

                        {((props.etapaAtual == "Solicitante" && !props.osVinculada) || (props.etapaAtual == "Aprov. Técnico")) ?
                            <Button
                                variant="solid"
                                size="md"
                                color="primary"
                                aria-label="Adicionar"
                                sx={{
                                    m: "auto",
                                    mb: "20px",
                                    alignSelf: "center",
                                    fontWeight: 600,
                                }}
                                style={props.mobile ? { marginTop: "15px" } : {}}
                                disabled={!(props.itemTemporario.quantidadeProduto && parseInt(props.itemTemporario.quantidadeProduto, 10) >= 1)}
                                // disabled={((props.itemTemporario != {} && props.itemTemporario.quantidadeProduto == "" || props.itemTemporario.quantidadeProduto == "-"))}
                                onClick={() => props.acrescentarItem()}
                            >
                                Confirmar
                            </Button>
                            : null}
                    </Sheet>
                </Modal>
                : null
            }

            {/* Modal saldo dos itens */}
            <SaldoDosItens
                openModalInfoItem={openModalInfoItem}
                setOpenModalInfoItem={setOpenModalInfoItem}
                saldosDoItem={saldosDoItem}
            />

            <UltimasCompras
                open={modalUltimasCompras}
                setOpen={setModalUltimasCompras}
                data={ultimasCompras}
            />
        </Stack>
    );
}