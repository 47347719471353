import axios from "axios";
import { useState, useEffect } from "react";
//Componentes
import ConfirmarExclusao from "../ConfirmarExclusao";
import ModalAvisos from "../modalAvisos";
//Utils
import { dataAtual } from "../../utils/datas";

import { Stack, Typography, Button, Select, Option, Input, FormLabel, Table, ToggleButtonGroup, Link, Checkbox, Textarea, Sheet, Switch, FormHelperText } from "@mui/joy";

import { toast } from 'react-toastify';

//icones react
import { FaRegEdit, FaRegTrashAlt, FaDownload, FaFolderOpen, FaInfoCircle, FaCheck } from "react-icons/fa";
import { GoLaw } from "react-icons/go";
import { IoEye, IoEyeOff } from "react-icons/io5";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';

//Apis do RM
import { api, RM } from "../../services/api";

import { useNavigate, useLocation, useParams } from "react-router-dom";

import Autocomplete from '@mui/joy/Autocomplete';
import realizaConsulta from "../../services/rm";


export default function DadosTransferenciaMaterial(props) {
    // Serie padrão: TP

    const [formulario, setFormulario] = useState({ });
    const [mostrarCamposPedidoTransferencia, setMostrarCamposPedidoTransferencia] = useState(false);
    const [filialOrigem, setFilialOrigem] = useState(null);
    const [observacoesTransferencia, setObservacoesTransferencia] = useState("");
    const [locaisDeEstoque, setLocaisDeEstoque] = useState([]);
    const [localEstoqueSelecionado, setLocalEstoqueSelecionado] = useState(null);
    const [codLocSelectOpen, setCodLocSelectOpen] = useState(false);

    useEffect(() => {
        obterLocalEstoqueECentroCusto(1);
    }, []);


    const obterLocalEstoqueECentroCusto = (filial = 1) => {

    //  if(filialOrigem != null){
        let id_toast = toast.loading("Obtendo informações do RM...", { toastId: "toast" });
        let urlLocalEstoque = "";
  
        console.log("cade props????  ", props.usuarioLogin);
        
        urlLocalEstoque = `/locaisEstoque?login=${props.usuarioLogin}&coligada=1&filial=${filial}&solicitacao=`;
  
        api.get(urlLocalEstoque, {
          headers: {
            'Authorization': 'Bearer ' + props.token
          }
        }
        ).then(function ({ data }) {
  
            let listaLocaisDeEstoque = [];
        
            if (data.data.LOCALESTOQUE) {
                data.data.LOCALESTOQUE.forEach(localDeEstoque => {
                listaLocaisDeEstoque.push({ codigo: localDeEstoque.localEstoque, nome: localDeEstoque.localEstoque + " - " + localDeEstoque.nomeLocal });
                });
            } else {
                toast.update(id_toast, { render: "O RM não respondeu. Por favor, Atualize a página. Se o erro persistir, tente novamente mais tarde.", type: "warning", isLoading: false, closeOnClick: true });
            }
            //Atualizando locais de estoque em informacoes gerais
            setLocaisDeEstoque(listaLocaisDeEstoque);
            toast.update(id_toast, { render: "Dados carregados com sucesso!", type: "success", isLoading: false, autoClose: 2000 });
            }).catch(function (err) {
                console.log("meus locais estoque erro!!", err);
                toast.error("Ocorreu um erro ao buscar locais de estoque, por favor recarregue a página.");
                toast.error(JSON.stringify(err.response.data));
                console.error(err);
            })
    //  }

    }

    return(
        <>
            {/* Escolher se é pedido de transferencia de material, apenas o solicitante faz */}
            { props.etapaAtual == "Solicitante" ?
              <Stack mt={props.mobile ? "20px" : "10px"} mb={"10px"}>
                <Typography level="body-sm">Pedido de Transferência de Material?</Typography>
                <Stack alignSelf={"flex-start"} justifyContent="center" flex={props.mobile ? null : 1}>
                  <Switch
                    checked={mostrarCamposPedidoTransferencia} disabled={props.osVinculada}
                    onChange={(event) => [setMostrarCamposPedidoTransferencia(event.target.checked), props.atualizarFormulario("transferencia", event.target.checked)]}
                  />
                </Stack>
              </Stack>
              :

              null
              // <Typography mt={"30px"} level="title-lg">{formulario.pedidoContrato ? "Pedido de transferencia" : null} </Typography>
            }

            { mostrarCamposPedidoTransferencia ?
              <>
                <Stack flexDirection={props.mobile? "column":"row"}>
                    <Stack flex={1} minWidth={props.mobile ? "100%" : "110px"} mt={props.mobile ? "20px" : ""}>
                    <FormLabel>Filial Origem</FormLabel>
                    <Select defaultValue="1" value={filialOrigem} onChange={(event, newValue) => [ props.atualizarFormulario("filialOrigem", newValue), setFilialOrigem(newValue), obterLocalEstoqueECentroCusto(newValue)]} >
                        {props.filiais.map(function (filial, indice) {
                        return (
                            <Option key={indice} value={filial.split(" - ")[0]}>{filial}</Option>
                        );
                        })}
                    </Select>
                    </Stack>

                    <Stack flex={1} minWidth={props.mobile ? "100%" : "110px"} ml={props.mobile ? "0" : "30px"} mt={props.mobile ? "10px" : ""}>
                    <Typography level="body-sm">Local de Estoque Origem</Typography>
                    <Select
                        key={locaisDeEstoque}
                        value={localEstoqueSelecionado}
                        listboxOpen={codLocSelectOpen}
                        onListboxOpenChange={(isOpen) => setCodLocSelectOpen(isOpen)}
                        onChange={(event, newValue) => [setLocalEstoqueSelecionado(newValue), props.atualizarFormulario("localEstoqueOrigem", newValue)]} >
                        {locaisDeEstoque.map(function (localEstoque, indice) {
                            return (
                            <Option key={indice} value={localEstoque.codigo}>{localEstoque.nome}</Option>
                        );
                        })}
                    </Select>
                    </Stack>
                </Stack>
               
                <Stack mt={"10px"}>
                    <FormLabel>Observações de Transferência</FormLabel>
                    <Input type="text" value={observacoesTransferencia} onChange={(event) => [setObservacoesTransferencia(event.target.value), props.atualizarFormulario("observacoesTransferencia", event.target.value)]}/>
                </Stack>
              </>
              :
              null
            }

        </>
    );

}