import { toast } from 'react-toastify';
import { api } from '../../../../services/api';

const buscarProcessos = (filtros) => {
    const token = JSON.parse(localStorage.getItem('usuario')).token;

    if (!filtros.localEstoque) {
        toast.warning("Escolha um local de estoque!");
        return;
    }

    //Status fixado em "Em andamento"
    filtros.statusOs = 1;

    api.get(`/diligenciamento?dataInicio=${filtros.dataInicio}&dataFim=${filtros.dataFim}&statusOs=${filtros.statusOs}&localEstoque=${filtros.localEstoque}&codEquip=%&numOs=%&iniciarEm=${filtros.iniciarEm}&nomeResponsavel=%&atrasados=false&etapaAtual=`,
        {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
    ).then(({ data }) => {
        return (data.data.listagem);
    }).catch((err) => {
        console.error(err);
        toast.dismiss();
    })
}

const obterProcessosPorEtapaDeAprovacao = (filtros, setDadosEtapaAprovacao) => {
    const token = JSON.parse(localStorage.getItem('usuario')).token;

    //Obtendo dados por O.S
    let contagensOS = {};
    filtros.iniciarEm = "O.S";

    if (!filtros.localEstoque) {
        toast.warning("Escolha um local de estoque!");
        return;
    }

    //Status fixado em "Em andamento"
    filtros.statusOs = 1;

    api.get(`/diligenciamento?dataInicio=${filtros.dataInicial}&dataFim=${filtros.dataFinal}&statusOs=${filtros.statusOs}&localEstoque=${filtros.localEstoque}&codEquip=%&numOs=%&iniciarEm=${filtros.iniciarEm}&nomeResponsavel=%&atrasados=false&etapaAtual=`,
        {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
    ).then(({ data }) => {
        contagensOS = (data.data.Contagem);

        //Obtendo dados por P.M.S
        let contagensPMS = {};
        filtros.iniciarEm = "PMS";

        if (!filtros.localEstoque) {
            toast.warning("Escolha um local de estoque!");
            return;
        }

        //Status fixado em "Em andamento"
        filtros.statusOs = 1;

        api.get(`/diligenciamento?dataInicio=${filtros.dataInicial}&dataFim=${filtros.dataFinal}&statusOs=${filtros.statusOs}&localEstoque=${filtros.localEstoque}&codEquip=%&numOs=%&iniciarEm=${filtros.iniciarEm}&nomeResponsavel=%&atrasados=false&etapaAtual=`,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
        ).then(({ data }) => {
            contagensPMS = (data.data.Contagem);
            setDadosEtapaAprovacao({ os: contagensOS, pms: contagensPMS });
        }).catch((err) => {
            console.error(err);
            toast.dismiss();
        })
    }).catch((err) => {
        console.error(err);
        toast.dismiss();
    })

}

const obterEntregasEmDiaEAtrasados = (filtros, setDadosEntregasEmDiaEAtrasado) => {
    const token = JSON.parse(localStorage.getItem('usuario')).token;

    api.get(`/processosDiaAtrasados?dataInicio=${filtros.dataInicial}&dataFim=${filtros.dataFinal}`,
        {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
    ).then(({ data }) => {
        setDadosEntregasEmDiaEAtrasado(data);
    }).catch((err) => {
        console.error(err);
        toast.dismiss();
    })

}

const obterSolicitacoesLocaisEstoque = (filtros, setDadosSolicitacoesLocalEstoque) => {
    const token = JSON.parse(localStorage.getItem('usuario')).token;

    api.get(`/solicitacaoPorLocalEstoque?dataInicio=${filtros.dataInicial}&dataFim=${filtros.dataFinal}`,
        {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
    ).then(({ data }) => {
        setDadosSolicitacoesLocalEstoque(data);
    }).catch((err) => {
        console.error(err);
        toast.dismiss();
    })

}

const obterMediasAberturasEFechamentos = (filtros, setDadosAberturasEFechamentos) => {
    const token = JSON.parse(localStorage.getItem('usuario')).token;

    api.get(`/mediaPonderada?ano=${filtros.ano}`,
        {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
    ).then(({ data }) => {
        const objeto = data.reduce((acc, item) => {
            const chave = Object.keys(item)[0];
            const valor = item[chave];
            acc[chave] = valor;
            return acc;
        }, {});
        setDadosAberturasEFechamentos(objeto);
    }).catch((err) => {
        console.error(err);
        toast.dismiss();
    })

}

export { buscarProcessos, obterProcessosPorEtapaDeAprovacao, obterEntregasEmDiaEAtrasados, obterSolicitacoesLocaisEstoque, obterMediasAberturasEFechamentos }