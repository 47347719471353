

function verificaNecessidadeJuridico(cotacao, limiteBaixoValorAgregado) {
    /*
        Verifica se um movimento de cotação precisa de avaliação do jurídico, baseado em 3 condições.
        1 - se houver um item com nome "ADIANTAMENTO", vai pro jurídico.
        2 - se houver um item do tipo serviço, e preço total dele nao for de baixo valor agregado, vai pro jurídico.
        3 - se o valor total da cotação passar de 500 mil, vai pro jurídico.
     */

    for (let i = 0; i < cotacao.itens.length; i++) {
        //1 condição
        if(cotacao.itens[i].descricao.includes("ADIANTAMENTO")){
            return true; 
        }
        //2 condição
        else if ((cotacao.itens[i].tipo == "S")&&(cotacao.itens[i].precoTotal > limiteBaixoValorAgregado)){
            return true;
        }
    }

    //3 condição
    if(cotacao.totalCotacao > 500000){
        return true;
    }

    //caso nao atenda nenhuma condição, nao precisa de juridico:
    return false;
}

export { verificaNecessidadeJuridico };