import { Stack, Typography } from "@mui/joy";
import { FaCheckCircle } from "react-icons/fa";

export default function CardDiligenciamento(props) {
    return (
        <Stack onClick={props.onClick} className="hover" border={`2px solid ${props.cor}`} borderRadius="10px" width="110px" minWidth="110px" height="90px" mt="30px" mr={props.ultimo ? "0px" : "10px"} boxShadow={"sm"}>
            <Stack borderBottom="1px solid #ddd" backgroundColor={props.cor} sx={{ borderTopRightRadius: "5px", borderTopLeftRadius: "5px" }}>
                <Typography sx={{ color: "#eee", fontSize: 16, textAlign: "center", userSelect: "none" }}>{props.titulo}</Typography>
            </Stack>
            <Stack minHeight={"62px"} justifyContent={"center"} alignItems="center" p={"0px 10px"} mb="10px">
                <Typography sx={{ margin: "auto", fontSize: 35, color: "#444", fontWeight: "bold", userSelect: "none" }}>{props.valor}</Typography>
            </Stack>
            {props.selecionado ?
                <Stack alignSelf={"center"}>
                    <FaCheckCircle color="#43a047" size={20} />
                </Stack>
                : null
            }
        </Stack>
    )
}