function statusSolicitacao(codigo) {
    const dicionario = ["Em Andamento", "Cancelado", "Concluído"];
    return (dicionario[codigo - 1]);
}

const tiposPedidos = [
    { value: "001", label: "Materiais de Consumo" },
    { value: "002", label: "Prestadores de Serviços" },
    { value: "003", label: "Materiais de Aplicação" },
    { value: "004", label: "Materiais Engenheirados" },
    { value: "005", label: "Materiais de Partes e Peças" },
    { value: "006", label: "Serviços em Equipamentos" },
    { value: "007", label: "Locações de Equipamentos" },
    { value: "008", label: "Serviços Subcontratados" },
    { value: "009", label: "T.I" },
    { value: "010", label: "EPI" },
];

export { statusSolicitacao, tiposPedidos }