import { useState, useEffect, useRef } from "react";

import { formatarDataHoraBrasil } from "../../../../utils/datas";
import { toast } from 'react-toastify';

// Icones
import { IoChevronBack, IoArrowForwardSharp, IoCheckmarkSharp, IoWarning, IoDocumentText, IoArrowDownOutline, IoCubeOutline, IoInformationCircleSharp, IoCheckmarkCircleSharp } from "react-icons/io5";
import { HiOutlineMinus } from "react-icons/hi";
import { FaLongArrowAltDown, FaLongArrowAltRight } from "react-icons/fa";
import { AiFillMessage } from "react-icons/ai";
import { TfiSplitH } from "react-icons/tfi";

//componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";

//Mui
import { Stack, Typography, Button, Modal, Link, Sheet, ModalClose, Select, Option, Avatar, Card, Dropdown, MenuButton, Menu, MenuItem, Switch, Radio, List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, FormHelperText, RadioGroup } from "@mui/joy";

//Roteamento
import { useNavigate, useLocation } from "react-router-dom";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';
import { api, RM } from "../../../../services/api";
import realizaConsulta from "../../../../services/rm";

//Array vazio com 10 posições para testes
const arrayVazioTabela = Array.from({ length: 5 }, () => 0); // Preenche com zeros

//Largura da tela
const larguraTela = window.innerWidth;

let token = "";
let usuarioID = 0;
let usuarioLogin = "";
let permissoes = [];
let listaBotoesFluxo = [];

export default function FluxoAprovacao(props) {

    const navigate = useNavigate();
    const location = useLocation(); //navegacao entre components
    const itensRef = useRef(null);

    //Verificando se o dispositivo é mobile
    const mobile = useMediaQuery('(max-width:1200px)');

    const [modalHistorico, setModalHistorico] = useState(false);
    const [modalMensagem, setModalMensagem] = useState(false);
    const [modalRastreamento, setModalRastreamento] = useState(false);
    const [modalRastreamentoCotacoes, setModalRastreamentoCotacoes] = useState(false);
    const [modalItensMovimento, setModalItensMovimento] = useState(false);
    const [dadosModalMensagem, setDadosModalMensagem] = useState({});
    const [numeroProcesso, setNumeroProcesso] = useState("");
    const [listaFluxoAprovacao, setListaFluxoAprovacao] = useState([]);
    const [cotacaoSelecionada, setCotacaoSelecionada] = useState("");
    const [contratoSelecionado, setContratoSelecionado] = useState("");
    const [cotacoes, setCotacoes] = useState([]);
    const [contratos, setContratos] = useState([]);
    const [etapaDoProcesso, setEtapaDoProcesso] = useState("requisicao");
    const [rastreamentos, setRastreamentos] = useState([]);
    const [rastreamentosCotacoes, setRastreamentosCotacoes] = useState({ solicitacao: [] });
    const [rastreamentosItens, setRastreamentosItens] = useState([]);
    const [movimentoSelecionado, setMovimentoSelecionado] = useState("");

    const [origemChamada, setOrigemChamada] = useState(""); //guarda a url de qual tela chamou a tela de fluxoAprovacao:
    const [cotacaoOrigem, setCotacaoOrigem] = useState(null);
    const [contratoOrigem, setContratoOrigem] = useState(null);
    const [transferenciaMatOrigem, setTransferenciaMatOrigem] = useState(null);
    const [etapaAguardando, setEtapaAguardando] = useState();
    const [aprovadorAguardando, setAprovadorAguardando] = useState();
    const [fluxoSelecionado, setFluxoSelecionado] = useState("Requisição");
    const [dataCriacaoCompra, setDataCriacaoCompra] = useState();
    const [dataCriacaoNota, setDataCriacaoNota] = useState();
    const [objetoNota, setObjetoNota] = useState([]);
    const [numeroMovimento01, setNumeroMovimento01] = useState();

    useEffect(() => {
        //pegar permissoes do usuario logado:
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            permissoes = JSON.parse(localStorage.getItem('usuario')).user.autenticacao;

            if (permissoes.includes("requisicoes")) {
                listaBotoesFluxo.push('Requisição');
            }
            if (permissoes.includes("compras")) {
                listaBotoesFluxo.push('Compras');
            }
            if (permissoes.includes("contratos")) {
                listaBotoesFluxo.push('Contratos');
            }
            if (permissoes.includes("admin")) {
                listaBotoesFluxo = ['Requisição', 'Compras', 'Contratos'];
            }
            listaBotoesFluxo = [...new Set(listaBotoesFluxo)];
            // ['Requisição', 'Compras', 'Contratos']

        }

        // TODO: O parametro processo vem do component Solicitacao
        if (location.state) {
            setNumeroProcesso(location.state.processo);

            //pega url que chamou o fluxoAprovacao
            if (location.state.origemChamada) {
                setOrigemChamada(location.state.origemChamada);
            }
            //pega a cotacao_id (se origem for o componente aprovacaoCompra)
            if (location.state.cotacao) {
                setCotacaoOrigem(location.state.cotacao);
            }
            else if (location.state.contrato) {
                // se a origem que chamou esse componente for a tela de contrato, informar o contrato origem:
                setContratoOrigem(location.state.contrato);
            }
            else if (location.state.transferenciaMat) {
                setTransferenciaMatOrigem(location.state.transferenciaMat);
            }
            // setNumeroProcesso("29");
            listaEtapasSolicitacao(location.state.processo);
        } else {
            navigate("/centralTarefas");
        }


    }, [])

    useEffect(() => {
        if (etapaDoProcesso == "contrato" && contratoSelecionado) {
            listaEtapasContrato(numeroProcesso, contratoSelecionado);
        }
        else if (etapaDoProcesso == "compra" && cotacaoSelecionada) {
            listaEtapasCompras(numeroProcesso, cotacaoSelecionada);
        }
        else if (etapaDoProcesso == "requisicao") {
            listaEtapasRequisicaoInicial(numeroProcesso);
        }
    }, [etapaDoProcesso, cotacaoSelecionada, contratoSelecionado]);

    const exibirMensagem = (titulo, mensagem) => {
        setDadosModalMensagem({ titulo: titulo, mensagem: mensagem });
        setModalMensagem(true);
    }

    const listaEtapasSolicitacao = (processo) => {
        /* 
            Busca os registros de fluxo de aprovação de um Processo específico. 
            Esses registros são as etapas do processo que já foram realizadas.
        */

        api.get("/fluxoAprovacoes/?solicitacao=" + processo)
            .then((response) => {
                console.log(response);
                setCotacoes(response.data.data[0].cotacoes);
                setContratos(response.data.data[0].contratos);

                listaEtapasRequisicaoInicial(processo); //listar apenas a requisicao inicial ao carregar a tela
                obterInformacaoNota(response.data.data[0].solicitacao?.numeroMov1);
                setNumeroMovimento01(response.data.data[0].solicitacao?.numeroMov1);
                // for (let i = 0; i < listaFluxo.length; i++) {
                //     //quando for indice impar, significa que existe a etapa atual e anterior, para calcular a diferenca em dias:
                //     if (i > 0) {
                //         let dataEtapaAnterior = listaFluxo[i - 1].dataDecisao.split(" ")[0];
                //         let dataEtapaAtual = listaFluxo[i].dataDecisao.split(" ")[0];

                //         dataEtapaAnterior = new Date(dataEtapaAnterior);
                //         dataEtapaAtual = new Date(dataEtapaAtual);

                //         const diferencaEmDiasMilissegundos = Math.abs(dataEtapaAnterior - dataEtapaAtual);
                //         const diferencaEmDias = Math.floor(diferencaEmDiasMilissegundos / (1000 * 60 * 60 * 24));

                //         listaFluxo[i].diferenciaEmDias = diferencaEmDias + " dias";

                //     }
                //     //pega a etapa que está em aguardo de decisão do aprovador:
                //     if (i == (listaFluxo.length - 1)) {
                //         setEtapaAguardando(listaFluxo[i].etapaPresente.etapa);
                //         setAprovadorAguardando(listaFluxo[i].etapaPresente.usuario);
                //     }
                // }
                // setListaFluxoAprovacao(listaFluxo);

            })
            .catch((err) => {
                console.log("fluxoAprovacoes não encontrados.");
                toast.error(JSON.stringify(err.response.data));
            })
    }


    const listaEtapasContrato = (processo, contratoSelecionado) => {
        /* 
            Busca os registros de fluxo de aprovação da tela de contrato. 
            Esses registros são as etapas do processo que já foram realizadas.
        */

        api.get("/fluxoRequisicaoContrato/" + processo + "/" + contratoSelecionado)
            .then((response) => {
                console.log(response);
                const listaFluxo = response.data.data;
                for (let i = 0; i < listaFluxo.length; i++) {
                    //quando for indice impar, significa que existe a etapa atual e anterior, para calcular a diferenca em dias:
                    if (i > 0) {
                        let dataEtapaAnterior = listaFluxo[i - 1].dataDecisao.split(" ")[0];
                        let dataEtapaAtual = listaFluxo[i].dataDecisao.split(" ")[0];

                        dataEtapaAnterior = new Date(dataEtapaAnterior);
                        dataEtapaAtual = new Date(dataEtapaAtual);

                        const diferencaEmDiasMilissegundos = Math.abs(dataEtapaAnterior - dataEtapaAtual);
                        const diferencaEmDias = Math.floor(diferencaEmDiasMilissegundos / (1000 * 60 * 60 * 24));

                        listaFluxo[i].diferenciaEmDias = diferencaEmDias + " dias";

                    }
                    //pega a etapa que está em aguardo de decisão do aprovador:
                    if (i == (listaFluxo.length - 1)) {
                        setEtapaAguardando(listaFluxo[i].etapaPresente.etapa);
                        setAprovadorAguardando(listaFluxo[i].etapaPresente.usuario);
                    }
                }
                setListaFluxoAprovacao(listaFluxo);

            })
            .catch((err) => {
                console.log("fluxoAprovacoesContrato não encontrados.");
                toast.error(JSON.stringify(err.response.data));
            })
    }


    const listaEtapasCompras = (processo, numeroCotacao) => {
        /* 
            Busca os registros de fluxo de aprovação da tela de compras. 
            Esses registros são as etapas do processo que já foram realizadas.
        */

        console.log("/fluxoRequisicaoCompra/" + processo + "/" + numeroCotacao);
        api.get("/fluxoRequisicaoCompra/" + processo + "/" + numeroCotacao)
            .then((response) => {
                console.log(response);
                const listaFluxo = response.data.data;
                for (let i = 0; i < listaFluxo.length; i++) {
                    //quando for indice impar, significa que existe a etapa atual e anterior, para calcular a diferenca em dias:
                    if (i > 0) {
                        let dataEtapaAnterior = listaFluxo[i - 1].dataDecisao.split(" ")[0];
                        let dataEtapaAtual = listaFluxo[i].dataDecisao.split(" ")[0];

                        dataEtapaAnterior = new Date(dataEtapaAnterior);
                        dataEtapaAtual = new Date(dataEtapaAtual);

                        const diferencaEmDiasMilissegundos = Math.abs(dataEtapaAnterior - dataEtapaAtual);
                        const diferencaEmDias = Math.floor(diferencaEmDiasMilissegundos / (1000 * 60 * 60 * 24));

                        listaFluxo[i].diferenciaEmDias = diferencaEmDias + " dias";

                    }
                    //pega a etapa que está em aguardo de decisão do aprovador:
                    if (i == (listaFluxo.length - 1)) {
                        setEtapaAguardando(listaFluxo[i].etapaPresente.etapa);
                        setAprovadorAguardando(listaFluxo[i].etapaPresente.usuario);
                    }
                }
                setListaFluxoAprovacao(listaFluxo);

            })
            .catch((err) => {
                console.log("fluxoAprovacoesCompras não encontrados.");
                toast.error(JSON.stringify(err.response.data));
            })
    }



    const listaEtapasRequisicaoInicial = (processo) => {
        /* 
            Busca os registros de fluxo de aprovação da etapa inicial de requisição. 
            Esses registros são as etapas do processo que já foram realizadas.
        */

        if (processo) {
            api.get("/fluxoRequisicaoRequisicao/" + processo)
                .then((response) => {
                    console.log(response);
                    const listaFluxo = response.data.data;
                    for (let i = 0; i < listaFluxo.length; i++) {
                        //quando for indice impar, significa que existe a etapa atual e anterior, para calcular a diferenca em dias:
                        if (i > 0) {
                            let dataEtapaAnterior = listaFluxo[i - 1].dataDecisao.split(" ")[0];
                            let dataEtapaAtual = listaFluxo[i].dataDecisao.split(" ")[0];

                            dataEtapaAnterior = new Date(dataEtapaAnterior);
                            dataEtapaAtual = new Date(dataEtapaAtual);

                            const diferencaEmDiasMilissegundos = Math.abs(dataEtapaAnterior - dataEtapaAtual);
                            const diferencaEmDias = Math.floor(diferencaEmDiasMilissegundos / (1000 * 60 * 60 * 24));

                            listaFluxo[i].diferenciaEmDias = diferencaEmDias + " dias";

                        }
                        //pega a etapa que está em aguardo de decisão do aprovador:
                        if (i == (listaFluxo.length - 1)) {
                            setEtapaAguardando(listaFluxo[i].etapaPresente.etapa);
                            setAprovadorAguardando(listaFluxo[i].etapaPresente.usuario);
                        }
                    }
                    setListaFluxoAprovacao(listaFluxo);

                })
                .catch((err) => {
                    console.log("fluxoAprovacoesRequisição não encontrados.");
                    toast.error(JSON.stringify(err.response.data));
                })
        }
    }


    const CardEtapa = (props) => {
        let movimentos = "";

        // console.log(listaFluxoAprovacao);

        //Verificando se existe aprovador institucional
        const existeInstitucional = listaFluxoAprovacao.findIndex(listaFluxo => listaFluxo.etapa == "Aprov. Institucional") != -1;

        if (props.titulo === "Solicitante") {
            movimentos = "1.1.01";
        } else if (props.solicitacao.numeroMov2 && props.titulo == "Aprov. Técnico") {
            movimentos += "1.1.02 ";

            if (props.solicitacao.movimento1103) {
                movimentos += "-> 1.1.03 ";
            }

            if (props.solicitacao.movimento1121) {
                movimentos += "-> 1.1.21 ";
            }
        }
        else if (props.solicitacao.movimento1104 && props.titulo == "Aprov. Gerencial" && !existeInstitucional) {
            movimentos += "1.1.04 ";
        }
        else if (props.solicitacao.movimento1104 && props.titulo == "Aprov. Institucional") {
            movimentos += "1.1.04 ";
        }

        return (
            <Stack>
                <Stack flexDirection={"row"} justifyContent="center" alignItems="center" mb={"10px"}>
                    {props.informacoesAdicionais || mobile ?
                        mobile ?
                            <Stack width={"50px"} ml="0px">
                                {props.informacoesAdicionais ?
                                    <Button onClick={() => exibirMensagem("Informações Adicionais", props.informacoesAdicionais)} variant="plain" sx={{ margin: 0, padding: "0 5px" }}>
                                        <AiFillMessage color="#1e88e5" size={30} />
                                    </Button>
                                    : null}
                            </Stack>
                            :
                            <Stack flexDirection={"row"} flex={1} maxWidth="350px" height="100%" alignItems="center">
                                <Stack flex={1} height="100%" borderRadius="5px" mr="25px" border={2} borderColor="#1e88e5" bgcolor="#bbdefb">
                                    <Typography p="10px" level="title-sm">{props.informacoesAdicionais}</Typography>
                                </Stack>
                                {mobile ? <Stack></Stack> :
                                    <HiOutlineMinus size={18} color="#555" />
                                }
                            </Stack>
                        : <Stack flex={1} maxWidth="350px"></Stack>
                    }
                    <Stack width={"200px"} p={"10px"} textAlign="center" borderRadius="5px" border={2} borderColor={props.etapaAtual ? "#333333" : props.etapaAguardando ? "#333333" : "#FF9264"} bgcolor={props.etapaAtual ? "#333333" : props.etapaAguardando ? "#80ed99" : "#fff"} mx="25px">
                        <Stack flexDirection="row" alignItems="center" justifyContent={"center"} ml={(props.aprovado || props.reprovado) ? "25px" : "0px"}>
                            <Typography level="title-md" style={props.etapaAtual ? { color: "#eee" } : { color: "#333" }}>{props.titulo}</Typography>
                            {props.aprovado ?
                                <IoCheckmarkSharp size={mobile ? 20 : 22} color="#43a047" style={{ marginLeft: "5px" }} />
                                :
                                props.reprovado ?
                                    <IoWarning size={mobile ? 20 : 22} color="#ffb300" style={{ marginLeft: "5px" }} />
                                    : null
                            }
                        </Stack>
                        <Typography mt="10px" level="body-sm" style={props.etapaAtual ? { color: "#eee" } : { color: "#333" }}>{props.solicitante}</Typography>
                        <Typography mt="0px" level="body-sm" style={props.etapaAtual ? { color: "#eee" } : { color: "#333" }}>{props.data}</Typography>
                        <Typography mt="0px" level="body-sm" style={props.etapaAtual ? { color: "#eee" } : { color: "#333" }}>{props.hora}</Typography>
                        <Typography mt="0px" level="body-sm" style={props.etapaAtual ? { color: "#eee", fontWeight: "bold" } : { color: "#333", fontWeight: "bold" }}>{movimentos}</Typography>
                    </Stack>
                    {(props.motivoReprovacao || props.motivoCancelamento) ?
                        mobile ?
                            <Stack width={"50px"} mr="0px">
                                <Button onClick={() => props.motivoReprovacao ? exibirMensagem("Motivo Reprovação", props.motivoReprovacao) : exibirMensagem("Motivo Cancelamento", props.motivoCancelamento)} variant="plain" sx={{ margin: 0, padding: "0 5px" }}>
                                    <AiFillMessage color={props.motivoReprovacao ? "#ffb300" : "#f44336"} size={30} />
                                </Button>
                            </Stack>
                            :
                            <Stack flexDirection={"row"} flex={1} maxWidth="350px" height="100%" alignItems="center">
                                {mobile ? <Stack></Stack> :
                                    <HiOutlineMinus size={18} color="#555" />
                                }
                                <Stack flex={1} height="100%" borderRadius="5px" ml="25px" border={2} borderColor={props.motivoReprovacao ? "#ffb300" : "#e57373"} bgcolor={props.motivoReprovacao ? "#ffecb3" : "#ffcdd2"}>
                                    <Typography p="10px" level="title-sm">{props.motivoReprovacao ? props.motivoReprovacao : props.motivoCancelamento}</Typography>
                                </Stack>
                            </Stack>
                        :
                        mobile ?
                            <Stack width={"50px"} mr="0px">
                                <Button variant="plain" sx={{ display: "none", margin: 0, padding: "0 5px" }}>
                                    <AiFillMessage size={30} />
                                </Button>
                            </Stack>
                            :
                            <Stack flex={1} maxWidth="350px"></Stack>

                    }
                </Stack>
                {!props.ultimo || !props.etapaAguardando ?
                    <Stack flex={1} flexDirection={"row"} mb="10px" justifyContent="center">
                        <FaLongArrowAltDown color="#444" size={26} />
                    </Stack>
                    : null
                }
            </Stack>
        );
    }

    const obterRastreamentos = () => {
        toast.loading("Buscando rastreamentos...");

        setRastreamentosItens([]);

        if (etapaDoProcesso == "requisicao") {
            api.get('/fluxoRequisicao/' + numeroProcesso)
                .then(({ data }) => {
                    toast.dismiss();
                    setRastreamentos(data.data[0].movimento);
                    setModalRastreamento(true);

                    //Exibindo itens do primeiro movimento por padrão
                    if (data.data[0].movimento.length > 0) {
                        obterItensDoMovimento(data.data[0].movimento[0]["1.1.01"]);
                        setMovimentoSelecionado(data.data[0].movimento[0]["1.1.01"]);
                    }
                })
                .catch((err) => {
                    toast.dismiss();
                    console.log(err);
                    toast.error(JSON.stringify(err.response.data));
                });
            return;
        }
        if (etapaDoProcesso == "compra") {
            if (!cotacaoSelecionada) {
                toast.dismiss();
                toast.warn("Você deve selecionar uma cotação.");
                return;
            }

            api.get('/fluxoCotacao/' + cotacaoSelecionada)
                .then(({ data }) => {
                    toast.dismiss();
                    setRastreamentosCotacoes(data);
                    setModalRastreamentoCotacoes(true);

                    //Exibindo itens do primeiro movimento por padrão
                    if (data.solicitacao.length > 0) {
                        obterItensDoMovimento(data.solicitacao[0]['1.1.01']);
                        setMovimentoSelecionado(data.solicitacao[0]['1.1.01']);
                    }

                })
                .catch((err) => {
                    toast.dismiss();
                    toast.error(err.response.data.message);
                    console.log(err);
                    toast.error(JSON.stringify(err.response.data));
                });
            return;
        }
        if (etapaDoProcesso == "contrato") {
            if (!contratoSelecionado) {
                toast.dismiss();
                toast.warn("Você deve selecionar um contrato.");
                return;
            }

            toast.dismiss();
            toast.warn("Não implementado.");
            return;

            api.get('/fluxoContrato/' + contratoSelecionado)
                .then(({ data }) => {
                    toast.dismiss();
                    setRastreamentosCotacoes(data);
                    setModalRastreamentoCotacoes(true);

                    //Exibindo itens do primeiro movimento por padrão
                    if (data.solicitacao.length > 0) {
                        obterItensDoMovimento(data.solicitacao[0]['1.1.01']);
                        setMovimentoSelecionado(data.solicitacao[0]['1.1.01']);
                    }

                })
                .catch((err) => {
                    toast.dismiss();
                    toast.error(err.response.data.message);
                    console.log(err);
                    toast.error(JSON.stringify(err.response.data));
                });
            return;
        }
        toast.dismiss();
        toast.warn("Não implementado.");
    }

    const handleChangeEtapa = (e) => {

        let etapa = e.target.value;

        if (etapa == "Requisição") {
            etapa = "requisicao";
        } else if (etapa == "Compras") {
            etapa = "compra";
        } else if (etapa == "Contratos") {
            etapa = "contrato";
        }
        setEtapaDoProcesso(etapa);
    }

    const CardRastreamento = (props) => {
        return (
            <Stack onClick={() => obterItensDoMovimento(props.numeroMovimento)} className="hover" width="140px" boxShadow={"md"} border="2px solid #ffdc99" borderRadius="10px" padding="10px" sx={{ backgroundColor: props.numeroMovimento == movimentoSelecionado ? "#ffb732" : "#ffe2ac" }} margin={props.margin}>
                <Stack flexDirection="row" alignItems="center">
                    <IoDocumentText size={18} />
                    <Typography sx={{ color: "#333", fontWeight: "bold", marginLeft: "5px" }}>{props.tipoMovimento}</Typography>
                </Stack>
                {/* <Typography level="body-sm" sx={{ color: "#333", fontWeight: 500, padding: 0 }}>Número: 8540685</Typography> */}
                {/* <Typography level="body-sm" sx={{ color: "#333", fontWeight: 500, padding: 0 }}>Status: Terminado</Typography> */}
                <Typography level="body-sm" sx={{ color: "#333", fontWeight: 500, padding: 0 }}>Número: {props.numeroMovimento}</Typography>
            </Stack>
        )
    }

    const obterItensDoMovimento = (movimento) => {

        toast.loading("Buscando itens do movimento...");

        realizaConsulta('660676', { params: { IDMOV: movimento } })
            .then(({ data }) => {
                setRastreamentosItens(data);
                toast.dismiss();

                setMovimentoSelecionado(movimento);

                //Rolando para os itens no mobile
                // if (mobile) {
                if (itensRef.current) {
                    setTimeout(() => {
                        itensRef.current.scrollIntoView({ behavior: 'smooth' });
                    }, 50);
                }
                // }
            })
            .catch((err) => {
                console.log(err);
                toast.dismiss();
                toast.error("Erro ao buscar os itens do movimento: " + err);
                toast.error(JSON.stringify(err.response.data));
            });
    }

    const obterInformacaoNota = (movimento) => {
        /*
            Este método busca informação de nota (caso tenha sido gerado nota 1.2.01) e busca informação de diligenciamento (data que foi gerado o 1.1.08)
         */
        toast.loading("Buscando informação da nota 1.2.01...");

        realizaConsulta('660698', { params: { IDMOV: movimento } })
            .then(({ data }) => {
                toast.dismiss();
                console.log(data);
                setObjetoNota(data);
                //Rolando para os itens no mobile
                // if (mobile) {
                // if (itensRef.current) {
                //     setTimeout(() => {
                //         itensRef.current.scrollIntoView({ behavior: 'smooth' });
                //     }, 50);
                // }
                // }
            })
            .catch((err) => {
                console.log(err);
                toast.dismiss();
                toast.error("Erro ao buscar a nota do movimento: " + err);
                toast.error(JSON.stringify(err.response.data));
            });
    }

    return (
        <Stack backgroundColor="#F5F5F5" height="100%" minHeight={"100vh"}>
            <Header mobile={mobile} notification />
            <Sidebar mobile={mobile} />

            <Stack ml={mobile ? "0px" : "280px"} mt="50px" overflow={"auto"} height={mobile ? "100%" : "calc(100vh - 200px)"} p={mobile ? "20px" : "50px"}>
                {/* Conteúdo da Tela */}
                <Typography level="h4">Fluxo de Aprovação</Typography>
                <Stack flexDirection="row" mt={"10px"}>
                    <Stack flexDirection="row" alignItems="center">
                        {/* Voltar, caso a origem seja cotação ou solicitação */}
                        <Button onClick={() => navigate(origemChamada + (contratoOrigem ? contratoOrigem : cotacaoOrigem ? cotacaoOrigem : transferenciaMatOrigem ? transferenciaMatOrigem : numeroProcesso), { state: { processo: numeroProcesso } })}>
                            <IoChevronBack size={20} />
                            Voltar
                        </Button>
                        <Typography ml="10px" level="body-md">Processo: #{numeroProcesso}</Typography>
                    </Stack>
                </Stack>
                <Stack mt="30px" sx={{ alignSelf: "flex-start" }}>
                    <Stack flexDirection={"row"} height={"95px"} alignItems={"flex-start"}>
                        <Stack marginRight={"10px"}>
                            <RadioGroup
                                orientation="horizontal"
                                name="fluxoSelecionado"
                                value={fluxoSelecionado}
                                onChange={(e) => [setFluxoSelecionado(e.target.value), handleChangeEtapa(e)]}
                                sx={{
                                    minHeight: 35,
                                    padding: '4px',
                                    borderRadius: '12px',
                                    bgcolor: 'neutral.softBg',
                                    '--RadioGroup-gap': '4px',
                                    '--Radio-actionRadius': '8px',
                                    justifyContent: 'center',
                                }}
                            >
                                {listaBotoesFluxo.map((item) => (
                                    <Radio
                                        key={item}
                                        color="neutral"
                                        value={item}
                                        disableIcon
                                        label={item}
                                        variant="soft"
                                        sx={{
                                            px: 2,
                                            alignItems: 'center',
                                        }}
                                        slotProps={{
                                            action: ({ checked }) => ({
                                                sx: {
                                                    ...(checked && {
                                                        bgcolor: 'background.surface',
                                                        boxShadow: 'sm',
                                                        '&:hover': {
                                                            bgcolor: 'background.surface',
                                                        },
                                                    }),
                                                },
                                            }),
                                        }}
                                    />
                                ))}
                            </RadioGroup>
                            {/* Botões do fluxo de aprovacao */}
                            {fluxoSelecionado == "Compras" &&
                                <Select onChange={(e, newValue) => setCotacaoSelecionada(newValue)} value={cotacaoSelecionada} placeholder={"N° Cotação"} sx={{ marginTop: "10px" }}>
                                    {cotacoes.length ? cotacoes.map((cotacao, indice) => {
                                        return (
                                            <Option key={indice} value={cotacao.id}>{cotacao.codCotacao} ({cotacao.numeroMov06})</Option>
                                        );
                                    }) : <Option disabled>Sem cotação</Option>}
                                </Select>
                            }
                            {fluxoSelecionado == "Contratos" &&
                                <Select onChange={(e, newValue) => setContratoSelecionado(newValue)} value={contratoSelecionado} placeholder={"N° Contrato"} sx={{ marginTop: "10px" }}>
                                    {contratos.length ? contratos.map((contrato, indice) => {
                                        return (
                                            <Option key={indice} value={contrato.numeroMov21}>{contrato.numeroMov21}</Option>
                                        );
                                    }) : <Option disabled>Sem contrato</Option>}
                                </Select>
                            }
                        </Stack>
                        <Stack onClick={obterRastreamentos} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <Button variant="plain">Abrir Rastreamento</Button>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack flexDirection={"row"} mt={mobile ? "15px" : "30px"} height="calc(100% - 70px)" alignItems="center" width="100%">
                    <Stack width="100%" flex={1} height="100%">
                        <Typography mb={mobile ? "0px" : "10px"} level="title-md">Fluxo</Typography>
                        <Typography mb={"10px"} level="body-sm">Legenda</Typography>
                        <Stack flexDirection={mobile ? "column" : "row"} alignItems={mobile ? "flex-start" : "center"}>
                            <Stack flexDirection={"row"} alignItems="center" mr="20px" mb={mobile ? "5px" : "0px"}>
                                {mobile ?
                                    <AiFillMessage color="#1e88e5" size={22} />
                                    :
                                    <Stack width="15px" height="15px" borderRadius={"3px"} border={2} borderColor="#1e88e5" bgcolor={"#bbdefb"}></Stack>
                                }
                                <Typography ml="5px" level="body-sm">Info. Adicionais</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems="center" mr="20px" mb={mobile ? "5px" : "0px"}>
                                {mobile ?
                                    <AiFillMessage color="#ffb300" size={22} />
                                    :
                                    <Stack width="15px" height="15px" borderRadius={"3px"} border={2} borderColor="#ffb300 " bgcolor={"#ffecb3"}></Stack>
                                }
                                <Typography ml="5px" level="body-sm">Mot. Reprovação</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems="center" mr="20px" mb={mobile ? "5px" : "0px"}>
                                {mobile ?
                                    <AiFillMessage color="#F44336" size={22} />
                                    :
                                    <Stack width="15px" height="15px" borderRadius={"3px"} border={2} borderColor="#F44336" bgcolor={"#ffcdd2"}></Stack>
                                }
                                <Typography ml="5px" level="body-sm">Mot. Cancelamento</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems="center" mr="20px" mb={mobile ? "5px" : "0px"}>
                                <Stack width="15px" height="15px" borderRadius={"3px"} border={2} borderColor="#FF9264" bgcolor={"#fff"}></Stack>
                                <Typography ml="5px" level="body-sm">Etapa Anterior</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems="center" mr="20px" mb={mobile ? "5px" : "0px"}>
                                <Stack width="15px" height="15px" borderRadius={"3px"} border={2} borderColor="#333" bgcolor={"#333"}></Stack>
                                <Typography ml="5px" level="body-sm">Etapa Atual</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems="center" mr="20px" mb={mobile ? "5px" : "0px"}>
                                <Stack width="15px" height="15px" borderRadius={"3px"} border={2} borderColor="#333" bgcolor={"#80ed99"}></Stack>
                                <Typography ml="5px" level="body-sm">Etapa Aguardando</Typography>
                            </Stack>
                            <Stack flexDirection={"row"} alignItems="center">
                                <Stack width="15px" height="15px" borderRadius={"3px"} border={2} borderColor="#333" bgcolor={"#e9c46a"}></Stack>
                                <Typography ml="5px" level="body-sm">Info. Recebimento</Typography>
                            </Stack>
                        </Stack>

                        {/* dados de criação de 1.1.08 e 1.2.01  */}
                        {objetoNota.length > 0 ?
                            <Card variant="outlined" sx={{ backgroundColor: "#e9c46a", display: 'flex', marginLeft: "auto", marginTop: "20px", gap: 1, padding: "10px", width: mobile ? "89%" : "300px", alignItems: "center" }}>
                                <Link
                                    onClick={() => navigate("/acompanhamentoItens/" + numeroProcesso, { state: { processo: numeroProcesso, movimento: numeroMovimento01 } })}
                                    underline="none"
                                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}
                                >
                                    {/* mostrar dados da criacao da nota no rm se existir */}
                                    {objetoNota[0]?.dataNota ?
                                        <>
                                            <Typography level="body-md">Material Recebido</Typography>
                                            <Typography level="body-sm">Data de Lançamento: {objetoNota[0]?.dataNota}</Typography>
                                            <Typography level="body-sm">Status: {objetoNota[0]?.statusNota}</Typography>
                                        </>
                                        :
                                        null
                                    }
                                    {/* mostrar dados de criação do mov 1.1.08 se existir */}
                                    {objetoNota[0]?.dataDiligenciamento ?
                                        <>
                                            <Typography mt="10px" level="body-md">Diligenciamento</Typography>
                                            <Typography level="body-sm">Data: {objetoNota[0]?.dataDiligenciamento}</Typography>
                                            <Typography level="body-sm">Status: {objetoNota[0]?.statusDiligenciamento}</Typography>
                                        </>
                                        :
                                        null
                                    }

                                </Link>
                            </Card>
                            :
                            null
                        }

                        <Stack mt="30px">
                            {listaFluxoAprovacao.map((item, index) => (
                                <Stack key={index}>
                                    {item.diferenciaEmDias != "" && (<Stack ml="auto" mr="auto" mb="10px">{item.diferenciaEmDias}</Stack>)}
                                    <CardEtapa
                                        titulo={item.etapaAnterior}
                                        solicitante={item.usuario.nome}
                                        data={formatarDataHoraBrasil(item.dataDecisao)}
                                        hora=""
                                        aprovado={item.decisao == "Aprovado"}
                                        reprovado={item.decisao == "Reprovado"}
                                        informacoesAdicionais={item.informacoesAd}
                                        motivoReprovacao={item.motivoReprovacao}
                                        motivoCancelamento={item.motivoCancelamento}
                                        ultimo={listaFluxoAprovacao.length - 1 == index}
                                        etapaAtual={listaFluxoAprovacao.length - 1 == index}
                                        solicitacao={item.solicitacao}
                                    />
                                    {etapaAguardando && index == listaFluxoAprovacao.length - 1 && (
                                        // <Stack ml="auto" mr="auto" mb="10px">{etapaAguardando}</Stack>
                                        <CardEtapa
                                            titulo={etapaAguardando}
                                            solicitante={aprovadorAguardando}
                                            data={formatarDataHoraBrasil(item.dataDecisao)}
                                            hora=""
                                            aprovado={"A definir"}
                                            reprovado={"A definir"}
                                            ultimo={"A definir"}
                                            etapaAtual={""}
                                            etapaAguardando={true}
                                            solicitacao={"A definir"}
                                        />
                                    )}
                                </Stack>
                            ))}
                        </Stack>
                        {/* <Stack mt={"20px"} flexDirection={mobile ? "column" : "row"} justifyContent={"flex-end"} alignItems={mobile ? "flex-start" : "center"}>
                                <Stack flexDirection={"row"} alignItems="center">
                                    <Typography ml="5px" level="body-lg"><strong>Data da criação de nota:</strong> dd/mm/yyyy</Typography>
                                </Stack>
                            </Stack> */}
                    </Stack>
                </Stack>
            </Stack>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalHistorico}
                onClose={() => setModalHistorico(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: "80%",
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.surface',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Histórico do Processo
                    </Typography>
                    <Stack maxHeight={"100%"}>
                        {arrayVazioTabela.map(function (item, indice) {
                            return (
                                <Stack key={indice} flexDirection={"row"} alignItems="center" py="10px" borderBottom={1} borderColor="#ddd">
                                    <Typography level="body-sm" width="20px" mr="10px">{(indice + 1)}°</Typography>
                                    <IoArrowForwardSharp size={14} />
                                    <Typography ml={"10px"} level="body-sm">julio.rodrigues realizou uma solicitação</Typography>
                                </Stack>
                            )
                        })}
                    </Stack>
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalMensagem}
                onClose={() => setModalMensagem(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: "80%",
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.surface',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                        sx={dadosModalMensagem.titulo == "Informações Adicionais" ?
                            { color: "#1e88e5" } :
                            dadosModalMensagem.titulo == "Motivo Reprovação" ?
                                { color: "#ff8f00" } :
                                dadosModalMensagem.titulo == "Motivo Cancelamento" ?
                                    { color: "#f44336" } : null
                        }
                    >
                        {dadosModalMensagem.titulo}
                    </Typography>
                    <Stack maxHeight={"100%"}>
                        <Typography level="body-sm" mr="10px">{dadosModalMensagem.mensagem}</Typography>
                    </Stack>
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalRastreamento}
                onClose={() => setModalRastreamento(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: mobile ? "75%" : "50%",
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.surface',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Rastreamento
                    </Typography>
                    <Stack flexDirection={mobile ? "column" : "row"} alignItems="flex-start" mt="40px" sx={{ maxHeight: "70vh", overflow: "auto" }}>
                        <Stack alignSelf={mobile ? "center" : "flex-start"}>
                            {rastreamentos.map((rastreamento, indice) => {
                                const keyObjeto = Object.keys(rastreamento)[0];
                                const valueObjeto = Object.values(rastreamento)[0];
                                let numeroMovRM = null;
                                if("1.1.01" in rastreamento){
                                    numeroMovRM = rastreamento.numeroMovimento;
                                }
                                
                                if (valueObjeto) {
                                    if (indice != 0) {
                                        return (
                                            <Stack key={indice}>
                                                <IoArrowDownOutline size={22} color="#888" style={{ margin: "10px 0", alignSelf: "center" }} />
                                                <CardRastreamento numeroMovimento={valueObjeto} tipoMovimento={keyObjeto} />
                                            </Stack>
                                        );
                                    }
                                    else {
                                        return (
                                            <>
                                            <Typography level="title-md" mb="20px">N° Movimento: {numeroMovRM}</Typography>
                                            <CardRastreamento key={indice} numeroMovimento={valueObjeto} tipoMovimento="1.1.01" />
                                            </>
                                        );
                                    }
                                }
                            })}
                        </Stack>
                        <Stack width={mobile ? "100%" : "600px"} mt={mobile ? "20px" : "0px"} ml={mobile ? "0px" : "40px"} pl="0px" borderLeft={mobile ? "0px" : "1px solid #ddd"}>
                            {/* <Typography level="title-lg">Itens do Movimento</Typography> */}
                            <List>
                                {rastreamentosItens.map((item, indice) => {
                                    return (
                                        <Stack borderBottom={mobile ? "1px solid #ddd" : "0px"}>
                                            <ListItem key={indice}>
                                                <ListItemButton>
                                                    {mobile ? null : <ListItemDecorator><IoCubeOutline color="#1976d2" size={30} /></ListItemDecorator>}
                                                    <Stack>
                                                        <ListItemContent>{item.CODIGOPRD + " - " + item.PRODUTO}</ListItemContent>
                                                        <FormHelperText>Quantidade: {item.QUANTIDADE}</FormHelperText>
                                                        <FormHelperText>Preço: R${item.PRECOUNITARIO ? parseFloat(item.PRECOUNITARIO).toFixed(2).replace(".", ",") : parseFloat(0.00).toFixed(2).replace(".", ",")}</FormHelperText>
                                                        <FormHelperText>Ultima Compra: {item.ULTIMA_COMPRA ? item.ULTIMA_COMPRA : parseFloat(0.00).toFixed(2).replace(".", ",")}</FormHelperText>
                                                        <FormHelperText>Status: {item.STATUS}</FormHelperText>
                                                    </Stack>
                                                    {/* <KeyboardArrowRight /> */}
                                                </ListItemButton>
                                            </ListItem>
                                        </Stack>
                                    );
                                })}
                                {rastreamentosItens.length == 0 ?
                                    <Stack flexDirection={"row"} alignItems="center" sx={mobile ? {} : { marginLeft: "20px" }}>
                                        <IoInformationCircleSharp size={20} />
                                        <Typography sx={{ marginLeft: "5px" }}>Escolha um movimento para ver os itens</Typography>
                                    </Stack>
                                    : null
                                }
                            </List>
                        </Stack>

                    </Stack>
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalRastreamentoCotacoes}
                onClose={() => setModalRastreamentoCotacoes(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlied"
                    sx={{
                        width: mobile ? "85vw" : "92vw",
                        borderRadius: 'md',
                        p: mobile ? 2 : 3,
                        boxShadow: 'lg',
                        height: "85vh",
                        outline: 'none'
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.surface',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Rastreamento de Cotações
                    </Typography>

                    <Stack flexDirection={mobile ? "column" : "row"} width={mobile ? larguraTela * 0.85 : null} mt="40px" sx={{ maxHeight: "85%", overflow: "auto" }}>
                        {rastreamentosCotacoes ?
                            <Stack width={mobile ? "260px" : null} flex={mobile ? null : 1}>
                                <Typography>Solicitação(s)</Typography>
                                <Stack flexDirection={"row"} mb="15px" padding={mobile ? "0px" : "10px"} width={"95%"}>
                                    {rastreamentosCotacoes.solicitacao.map((rastreamento, indice) => {
                                        return (
                                            <Stack key={indice} boxShadow="lg" padding="10px 10px" mr="10px" alignItems={"center"}>
                                                {rastreamento["1.1.01"] ? <CardRastreamento tipoMovimento="1.1.01" numeroMovimento={rastreamento["1.1.01"]} margin={{ marginBottom: "5px" }} /> : null}
                                                {rastreamento["1.1.01"] ? <IoArrowDownOutline size={22} color="#888" style={{ margin: "5px 0", alignSelf: "center" }} /> : null}
                                                {rastreamento["1.1.02"] ? <CardRastreamento tipoMovimento="1.1.02" numeroMovimento={rastreamento["1.1.02"]} margin={{ marginBottom: "5px" }} /> : null}
                                                {rastreamento["1.1.02"] ? <IoArrowDownOutline size={22} color="#888" style={{ margin: "5px 0", alignSelf: "center" }} /> : null}
                                                {rastreamento["1.1.03"] && (rastreamento["1.1.04"] || rastreamento["1.1.21"]) ?
                                                    <Stack flexDirection="row" alignItems="flex-start" justifyContent={"center"}>
                                                        <Stack>
                                                            {rastreamento["1.1.03"] ? <CardRastreamento tipoMovimento="1.1.03" numeroMovimento={rastreamento["1.1.03"]} margin={{ marginBottom: "5px" }} /> : null}
                                                            {rastreamento["1.1.03"] ? <IoCheckmarkCircleSharp color="green" size={20} style={{ margin: "5px 0", alignSelf: "center" }} /> : null}
                                                        </Stack>
                                                        <Stack ml={rastreamento["1.1.21"] ? "0px" : "5px"}>
                                                            {rastreamento["1.1.04"] ? <CardRastreamento tipoMovimento="1.1.04" numeroMovimento={rastreamento["1.1.04"]} margin={{ marginBottom: "5px" }} /> : null}
                                                            {rastreamento["1.1.04"] ? <IoArrowDownOutline size={22} color="#888" style={{ margin: "5px 0", alignSelf: "center" }} /> : null}
                                                        </Stack>
                                                        <Stack ml={rastreamento["1.1.04"] ? "0px" : "5px"}>
                                                            {rastreamento["1.1.21"] ? <CardRastreamento tipoMovimento="1.1.21" numeroMovimento={rastreamento["1.1.21"]} margin={{ marginBottom: "5px" }} /> : null}
                                                            {rastreamento["1.1.21"] ? <IoArrowDownOutline size={22} color="#888" style={{ margin: "5px 0", alignSelf: "center" }} /> : null}
                                                        </Stack>
                                                    </Stack>
                                                    :
                                                    <Stack>
                                                        {rastreamento["1.1.03"] ? <CardRastreamento tipoMovimento="1.1.03" numeroMovimento={rastreamento["1.1.03"]} margin={{ marginBottom: "5px" }} /> : null}
                                                        {/* {rastreamento["1.1.03"] ? <IoArrowDownOutline size={22} color="#888" style={{ width: "170px", margin: "5px 0", textAlign: "center" }} /> : null} */}
                                                        {rastreamento["1.1.04"] ? <CardRastreamento tipoMovimento="1.1.04" numeroMovimento={rastreamento["1.1.04"]} margin={{ marginBottom: "5px" }} /> : null}
                                                        {rastreamento["1.1.04"] ? <IoArrowDownOutline size={22} color="#888" style={{ margin: "5px 0", alignSelf: "center" }} /> : null}
                                                        {rastreamento["1.1.21"] ? <CardRastreamento tipoMovimento="1.1.21" numeroMovimento={rastreamento["1.1.21"]} margin={{ marginBottom: "5px" }} /> : null}
                                                        {rastreamento["1.1.21"] ? <IoArrowDownOutline size={22} color="#888" style={{ margin: "5px 0", alignSelf: "center" }} /> : null}
                                                    </Stack>
                                                }
                                            </Stack>
                                        )
                                    })}
                                </Stack>

                                <Typography>Compra(s)</Typography>
                                {rastreamentosCotacoes.compra ?
                                    <Stack flexDirection={"row"} mb="15px" width="95%">
                                        <Stack padding="10px" marginRight="10px" boxShadow="lg">
                                            {rastreamentosCotacoes.compra["1.1.06"] ? <CardRastreamento tipoMovimento="1.1.06" numeroMovimento={rastreamentosCotacoes.compra["1.1.06"]} margin={{ marginBottom: "5px" }} /> : null}
                                            {rastreamentosCotacoes.compra["1.1.06"] ? <IoArrowDownOutline size={22} color="#888" style={{ width: "170px", margin: "5px 0", textAlign: "center" }} /> : null}
                                            {rastreamentosCotacoes.compra["1.1.22"] ? <CardRastreamento tipoMovimento="1.1.22" numeroMovimento={rastreamentosCotacoes.compra["1.1.22"]} margin={{ marginBottom: "5px" }} /> : null}
                                            {rastreamentosCotacoes.compra["1.1.22"] ? <IoArrowDownOutline size={22} color="#888" style={{ width: "170px", margin: "5px 0", textAlign: "center" }} /> : null}
                                            {rastreamentosCotacoes.compra["1.1.23"] ? <CardRastreamento tipoMovimento="1.1.23" numeroMovimento={rastreamentosCotacoes.compra["1.1.23"]} margin={{ marginBottom: "5px" }} /> : null}
                                            {rastreamentosCotacoes.compra["1.1.23"] ? <IoArrowDownOutline size={22} color="#888" style={{ width: "170px", margin: "5px 0", textAlign: "center" }} /> : null}
                                            {rastreamentosCotacoes.compra["1.1.08"] ? <CardRastreamento tipoMovimento="1.1.08" numeroMovimento={rastreamentosCotacoes.compra["1.1.08"]} margin={{ marginBottom: "5px" }} /> : null}
                                        </Stack>
                                    </Stack>
                                    : null
                                }

                                <Typography>Nota(s)</Typography>
                                {rastreamentosCotacoes.nota ?
                                    <Stack flexDirection={"row"} boxShadow="lg" alignSelf="flex-start">
                                        {rastreamentosCotacoes.nota.map((rastreamento, indice) => {
                                            return (
                                                <Stack padding="10px" marginRight="10px">
                                                    <CardRastreamento tipoMovimento={rastreamento.CODTMV} numeroMovimento={rastreamento.NUMEROMOV} margin={{ marginBottom: "5px" }} />
                                                </Stack>
                                            );
                                        })}
                                    </Stack>
                                    : null
                                }
                            </Stack>
                            : null
                        }

                        {/* Área dos itens de cada movimento */}
                        <Stack width={mobile ? "auto" : "500px"} /*height={mobile ? "200px" : "auto"}*/ padding={mobile ? "0px" : "20px"} borderRadius={"10px"} mt={mobile ? "20px" : "0px"}>
                            <Stack width={mobile ? "auto" : "400px"} borderLeft={mobile ? "0px" : "1px solid #ddd"} paddingLeft={mobile ? "0px" : "20px"} overflow="auto">
                                <Typography>Item(s)</Typography>
                                <List ref={itensRef}>
                                    {rastreamentosItens.map((item, indice) => {
                                        return (
                                            <Stack borderBottom={mobile ? "1px solid #ddd" : "0px"}>
                                                <ListItem key={indice}>
                                                    <ListItemButton>
                                                        {mobile ? null : <ListItemDecorator><IoCubeOutline color="#1976d2" size={30} /></ListItemDecorator>}
                                                        <Stack>
                                                            <ListItemContent>{item.CODIGOPRD + " - " + item.PRODUTO}</ListItemContent>
                                                            <FormHelperText>Quantidade: {item.QUANTIDADE}</FormHelperText>
                                                            <FormHelperText>Preço: R${item.PRECOUNITARIO ? parseFloat(item.PRECOUNITARIO).toFixed(2).replace(".", ",") : parseFloat(0.00).toFixed(2).replace(".", ",")}</FormHelperText>
                                                            <FormHelperText>Ultima Compra: {item.ULTIMA_COMPRA ? item.ULTIMA_COMPRA : parseFloat(0.00).toFixed(2).replace(".", ",")}</FormHelperText>
                                                            <FormHelperText>Status: {item.STATUS}</FormHelperText>
                                                        </Stack>
                                                        {/* <KeyboardArrowRight /> */}
                                                    </ListItemButton>
                                                </ListItem>
                                            </Stack>
                                        );
                                    })}
                                    {rastreamentosItens.length == 0 ?
                                        <Stack flexDirection={"row"} alignItems="center" sx={{ marginLeft: "20px" }}>
                                            <IoInformationCircleSharp size={20} />
                                            <Typography sx={{ marginLeft: "5px" }}>Nenhum item para mostrar</Typography>
                                        </Stack>
                                        : null
                                    }
                                </List>
                            </Stack>
                        </Stack>
                    </Stack>
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={modalItensMovimento}
                onClose={() => setModalItensMovimento(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: mobile ? "75%" : "50%",
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                        width: '80%',
                        height: "80vh"
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.surface',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Itens do Movimento
                    </Typography>
                    <Stack height="90%" overflow="auto">
                        <List>
                            {rastreamentosItens.map((item, indice) => {
                                return (
                                    <Stack mb="15px" borderBottom="1px dotted #aaa" paddingBottom="10px">
                                        <ListItem key={indice} style={{ padding: 0, width: "95%" }}>
                                            <ListItemButton>
                                                <ListItemDecorator><IoCubeOutline color="#1976d2" size={30} /></ListItemDecorator>
                                                <Stack>
                                                    <ListItem key={indice}>
                                                        <ListItemButton>
                                                            <Stack>
                                                                <ListItemContent>{item.CODIGOPRD + " - " + item.PRODUTO}</ListItemContent>
                                                                <FormHelperText>Quantidade: {item.QUANTIDADE}</FormHelperText>
                                                                <FormHelperText>Preço: R${item.PRECOUNITARIO ? parseFloat(item.PRECOUNITARIO).toFixed(2).replace(".", ",") : parseFloat(0.00).toFixed(2).replace(".", ",")}</FormHelperText>
                                                                <FormHelperText>Ultima Compra: {item.ULTIMA_COMPRA ? item.ULTIMA_COMPRA : parseFloat(0.00).toFixed(2).replace(".", ",")}</FormHelperText>
                                                                <FormHelperText>Status: {item.STATUS}</FormHelperText>
                                                            </Stack>
                                                            {/* <KeyboardArrowRight /> */}
                                                        </ListItemButton>
                                                    </ListItem>
                                                </Stack>
                                            </ListItemButton>
                                        </ListItem>
                                    </Stack>
                                );
                            })}
                        </List>
                    </Stack>
                </Sheet>
            </Modal>
        </Stack >
    );
}