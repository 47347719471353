import { Button, Typography } from "@mui/joy";
import { Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from "@mui/material";

export default function ModalAvisos(props) {
    /**
     * Modal de Avisos 
     */
    return (
        <Dialog
            open={props.modalAvisos}
            onClose={() => props.setModalAvisos(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            <Typography level="body-lg">Informativo:</Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography level="body-md">{props.mensagem}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="neutral" onClick={() => props.setModalAvisos(false)}>Entendido</Button>
            </DialogActions>
        </Dialog>
    )
}