import { useState, useEffect } from "react";
//componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import Legenda from "../../../../components/Legenda";
//Utils
import { dataAtual, formatarData } from "../../../../utils/datas";
import { statusSolicitacao } from "../../../../utils/choices";
//Mui
import { Input, Select, Stack, Typography, Option, Button, Table, Switch } from "@mui/joy";
//Material
import { Pagination } from '@mui/material/';
import useMediaQuery from '@mui/material/useMediaQuery';

import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

//API
import { api } from "../../../../services/api";

const dadosUsuarioLogado = {};
let idUsuario = 0;
let token = "";
//Dados do usuário logado no localstorage
const usuarioLogado = JSON.parse(localStorage.getItem('usuario'));

export default function CentralContratos(props) {

    const navigate = useNavigate();
    //Verificando se o dispositivo é mobile
    const mobile = useMediaQuery('(max-width:1200px)');

    const [dataInicio, setDataInicio] = useState(dataAtual(-1));
    const [dataTermino, setDataTermino] = useState(dataAtual(1));
    const [status, setStatus] = useState(1);

    const [contratos, setContratos] = useState([]);
    const [paginas, setPaginas] = useState({
        paginaAtual: 1,
        numeroPaginas: 1
    });
    const [verTodosContratos, setVerTodosContratos] = useState(false);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            dadosUsuarioLogado.nome = usuarioLogado.user.nome;
            dadosUsuarioLogado.usuario = usuarioLogado.user.login;
            dadosUsuarioLogado.obra = usuarioLogado.user.obra;
            dadosUsuarioLogado.grupo = usuarioLogado.grupo;
            token = JSON.parse(localStorage.getItem('usuario')).token;
            idUsuario = JSON.parse(localStorage.getItem('usuario')).user.id;

            let permissoes = [];
            if (dadosUsuarioLogado.grupo.length > 0) {
                dadosUsuarioLogado.grupo.forEach((item) => {
                    permissoes.push(item.perfil);
                })
            }
            dadosUsuarioLogado.permissoes = permissoes;
        }
        buscarContratos();
    }, []);

    const buscarContratos = (pagina = 1) => {
        api.get(`/contrato?paginacao=true&page=${pagina}&datasolicitacaoi=${dataInicio}&datasolicitacaof=${dataTermino}&status=${status}&todos=${verTodosContratos}&usuario=${idUsuario}`,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }
        ).then((response) => {
            let contratosDoBanco = [];
            if (response.data) {
                const dados = response.data;
                dados.data.forEach(contrato => {
                    contratosDoBanco.push(
                        {
                            id: contrato.id,
                            solicitacao_id: contrato.solicitacao_id,
                            contratoFornecedor: contrato.contratoFornecedor,
                            contratoMov22: contrato.contratoMov22,
                            contratoMov08: contrato.contratoMov08,
                            contratoMovNota: contrato.contratoMovNota,
                            contratoCodloc: contrato.contratoCodloc,
                            contratoFilial: contrato.contratoFilial,
                            contratoDataEntrega: formatarData(contrato.contratoDataEntrega.split(" ")[0]),
                            contratoDataEmissao: formatarData(contrato.contratoDataEmissao.split(" ")[0]),
                            contratoEtapaAtual: contrato.contratoEtapaAtual,
                            contratoStatus: contrato.contratoStatus,
                            contratoDecisao: contrato.contratoDecisao
                        }
                    );
                });
                setContratos(contratosDoBanco);
                //Definindo a paginação
                paginas.numeroPaginas = dados.meta.last_page;
            }
        }).catch((error) => {
            console.log(error);
            if (error.response.data.message == "Unauthenticated.") {
                localStorage.setItem("usuario", null);
                navigate("/acessoNegado");
            }
            else {
                toast.error("Ocorreu um erro ao obter os contratos.");
                console.log(error.response.data.message);
            }
            toast.error(JSON.stringify(error.response.data));
        });
    }

    const alterarPagina = (event, value) => {
        paginas.paginaAtual = value;
        buscarContratos(value);
    }

    return (
        <Stack backgroundColor="#F5F5F5" height="100%" minHeight={"100vh"}>
            <Header notification mobile={mobile} />
            <Sidebar mobile={mobile} />

            <Stack ml={mobile ? "0px" : "280px"} mt={"50px"} flex={1} height={mobile ? "100%" : "calc(100% - 55px)"} p={mobile ? "20px" : "50px"} overflow="auto">
                {/* Conteúdo da Tela */}
                <Typography level="h4">Movimentos de Contratos</Typography>
                <Stack ml={mobile ? "0px" : "20px"}>

                    <Legenda mobile={mobile} />

                    <Stack flexDirection={mobile ? "column" : "row"} mt={mobile ? "20px" : "30px"} alignItems={mobile ? "flex-start" : "center"} justifyContent={"space-between"}>
                        <Stack width="100%" flexDirection={mobile ? "column" : "row"}>
                            <Stack>
                                <Typography level="body-sm">Criadas a partir desta data:</Typography>
                                <Input type="date" value={dataInicio} onChange={(event) => setDataInicio(event.target.value)} />
                            </Stack>
                            <Stack ml={mobile ? "0px" : "30px"} mt={mobile ? "10px" : "0px"}>
                                <Typography level="body-sm">Até esta data:</Typography>
                                <Input type="date" value={dataTermino} onChange={(event) => setDataTermino(event.target.value)} />
                            </Stack>
                            <Stack ml={mobile ? "0px" : "30px"} mt={mobile ? "10px" : "0px"} mb={mobile ? "20px" : "0px"}>
                                <Typography level="body-sm">Status</Typography>
                                <Select fontSize={12} value={status} onChange={(event, newValue) => setStatus(newValue)} placeholder="Status da Solicitação">
                                    <Option value={1}>Em Andamento</Option>
                                    <Option value={2}>Cancelado</Option>
                                    <Option value={3}>Concluído</Option>
                                </Select>
                            </Stack>
                            {console.log("dado do user ", dadosUsuarioLogado.grupo)}
                            {usuarioLogado &&
                                <Stack ml={mobile ? "0px" : "30px"} mb={mobile ? "40px" : "0px"}>
                                    <Typography level="body-sm">Ver todos</Typography>
                                    <Stack alignSelf={"flex-start"} justifyContent="center" flex={mobile ? null : 1}>
                                        <Switch
                                            checked={verTodosContratos}
                                            onChange={(event) => setVerTodosContratos(event.target.checked)}
                                        />
                                    </Stack>
                                </Stack>
                            }
                        </Stack>
                        <Stack flexDirection={"row"} justifyContent={mobile ? "space-between" : "flex-end"} width={mobile ? "100%" : "auto"}>
                            <Button onClick={() => buscarContratos()} color="neutral">ATUALIZAR</Button>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack mt="30px">
                    {mobile ?
                        <Stack>
                            {contratos.map(function (item, indice) {
                                let podeAprovar = false;
                                let corDaLinha = "";
                                // if (dadosUsuarioLogado.permissoes) {

                                //     if (dadosUsuarioLogado.permissoes.indexOf(item.contratoEtapaAtual) != -1) {
                                //         podeAprovar = true;
                                //     }
                                // }

                                if (dadosUsuarioLogado.permissoes) {

                                    const obraEPermissao = dadosUsuarioLogado.grupo.filter(grupo => grupo.perfil == item.contratoEtapaAtual 
                                                                                           && grupo.obra == item.contratoCodloc).length > 0;
                                    if (
                                        ((dadosUsuarioLogado.permissoes.indexOf(item.contratoEtapaAtual) != -1) && obraEPermissao)
                                    ) {
                                        podeAprovar = true;
                                        corDaLinha = "#ffe082";
                                    }
                                    if (item.contratoDecisao == "Reprovado" && obraEPermissao) {
                                        corDaLinha = "#ef9a9a";
                                    }
                                }

                                return (
                                    <div className="hover">
                                        {/* TODO: fazer de contrato, navigate atualizar url */}
                                        <Stack key={indice} onClick={() => navigate("/contratos/" + item.id, { state: { contrato: item } })} p={"10px"} boxShadow="md" borderRadius={5} mb={"20px"} sx={{ userSelect: "none" }} style={{ backgroundColor: corDaLinha }}>
                                            <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Solicitação: </Typography>{item.solicitacao_id}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Mov. 1.1.22: </Typography>{item.contratoMov22}</Typography>
                                            {/* <Typography level="body-sm"><Typography level="title-md">Mov. 1.1.08: </Typography>{item.contratoMov08}</Typography> */}
                                            <Typography level="body-sm"><Typography level="title-md">Cod. Local: </Typography>{item.contratoCodloc}</Typography>
                                            {/* <Typography level="body-sm"><Typography level="title-md">Filial: </Typography>{item.contratoFilial}</Typography> */}
                                            <Typography level="body-sm"><Typography level="title-md">Etapa Atual: </Typography>{item.contratoEtapaAtual}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Decisão: </Typography>{item.contratoDecisao}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Data Entrega: </Typography>{item.contratoDataEntrega}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Data Emissão: </Typography>{item.contratoDataEmissao}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Status: </Typography>{statusSolicitacao(item.contratoStatus)}</Typography>
                                        </Stack>
                                    </div>
                                )
                            })}
                        </Stack>
                        :
                        <Stack>
                            <Table hoverRow noWrap>
                                <thead>
                                    <tr>
                                        <th style={{ height: "25px", width: "80px" }}>ID</th>
                                        <th style={{ height: "25px", width: "180px" }}>Solicitação</th>
                                        {/* <th style={{ height: "25px", width: "100px" }}>Mov. 1.1.08</th> */}
                                        <th style={{ height: "25px" }}>Cod. Local</th>
                                        {/* <th style={{ height: "25px" }}>Filial</th> */}
                                        <th style={{ height: "25px" }}>Etapa Atual</th>
                                        <th style={{ height: "25px" }}>Decisão</th>
                                        <th style={{ height: "25px", width: "100px" }}>Data Entrega</th>
                                        <th style={{ height: "25px", width: "120px" }}>Data Emissão</th>
                                        <th style={{ height: "25px" }}>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contratos.map(function (item, indice) {
                                        let podeAprovar = false;
                                        let corDaLinha = "";

                                        // if (dadosUsuarioLogado.permissoes) {
                                        //     if ((dadosUsuarioLogado.permissoes.indexOf(item.contratoEtapaAtual) != -1)) {
                                        //         podeAprovar = true;
                                        //         corDaLinha = "#ffe082"
                                        //     }
                                        // }

                                        if (dadosUsuarioLogado.permissoes) {

                                            const obraEPermissao = dadosUsuarioLogado.grupo.filter(grupo => grupo.perfil == item.contratoEtapaAtual 
                                                                                                   && grupo.obra == item.contratoCodloc).length > 0;
                                            if (
                                                ((dadosUsuarioLogado.permissoes.indexOf(item.contratoEtapaAtual) != -1) && obraEPermissao)
                                            ) {
                                                podeAprovar = true;
                                                corDaLinha = "#ffe082";
                                            }
                                            if (item.contratoDecisao == "Reprovado" && obraEPermissao) {
                                                corDaLinha = "#ef9a9a";
                                            }
                                        }


                                        return (
                                            <tr key={indice} className="hover" onClick={() => navigate("/contratos/" + item.id, { state: { contrato: item } })} style={{ backgroundColor: corDaLinha }} >
                                                <td style={{ padding: "0 10px" }}>{item.id}</td>
                                                <td style={{ padding: "0 10px" }}>{item.solicitacao_id}</td>
                                                {/* <td style={{ padding: "0 10px" }}>{item.contratoMov08}</td> */}
                                                <td style={{ padding: "0 10px" }}>{item.contratoCodloc}</td>
                                                {/* <td style={{ padding: "0 10px" }}>{item.contratoFilial}</td> */}
                                                <td style={{ padding: "0 10px" }}>{item.contratoEtapaAtual}</td>
                                                <td style={{ padding: "0 10px" }}>{item.contratoDecisao}</td>
                                                <td style={{ padding: "0 10px" }}>{item.contratoDataEntrega}</td>
                                                <td style={{ padding: "0 10px" }}>{item.contratoDataEmissao}</td>
                                                <td style={{ padding: "0 10px", fontWeight: "bold" }}>{statusSolicitacao(item.contratoStatus)}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Stack>
                    }
                    <Stack mt={3} alignItems={mobile ? "center" : "flex-end"}>
                        <Pagination
                            onChange={alterarPagina}
                            count={paginas.numeroPaginas}
                            page={paginas.paginaAtual}
                            hideNextButton={paginas.paginaAtual == paginas.numeroPaginas}
                            hidePrevButton={paginas.paginaAtual == 1}
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );



}
