import axios from 'axios';
import { Buffer } from "buffer";

//Preparando Token
const username = 'fluig';
const password = 'Centrium505050@@';
const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');

const RM = axios.create({
    baseURL: process.env.REACT_APP_RM_URL,
    timeout: 100000, // Tempo limite para as requisições em milissegundos
    headers: {
        'Authorization': `Basic ${token}`
    },
});

function realizaConsulta(key, options)
{
    const coligada = options.coligada || process.env.REACT_APP_RM_COLIGADA || '1';
    const sistema = options.sistema || process.env.REACT_APP_RM_SISTEMA || 'G';
    const params = options.params || [];
    
    const paramsString = converterParametrosRM(params);

    return RM.get(`${key}/${coligada}/${sistema}/?parameters=${paramsString}`);
}

function converterParametrosRM(data)
{
    let params = [];

    for(const key in data)
    {
        if (Array.isArray(data[key])) {
            data[key] = data[key].join('|');
        }

        params.push(encodeURI(key + '=' + (data[key] || '')));
    }

    return params.join(';');
}

export default realizaConsulta;