import { api } from '../../../../services/api';
import { useNavigate } from 'react-router-dom';
import { React, useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { formatarDataHoraBrasil, formatarDataBrasil } from '../../../../utils/datas';
import {
    Button,
    Stack,
    Typography,
    Table,
    Link,
} from "@mui/joy";

import { Pagination } from '@mui/material/';

import useMediaQuery from '@mui/material/useMediaQuery';
let token = "";
let usuarioID = 0;
let usuarioLogin = "";

export default function FollowUp(props) {

    const navigate = useNavigate();
    const mobile = useMediaQuery('(max-width:1200px)');
    const [ordensDeCompra, setOrdensDeCompra] = useState([]);
    const [paginas, setPaginas] = useState({
        paginaAtual: 1,
        numeroPaginas: 1
    });
    const [TemposDiligenciamento, setTemposDiligenciamento] = useState([]);
    const [adm, setAdm] = useState(false);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("usuario")) !== null) {
            token = JSON.parse(localStorage.getItem('usuario')).token;
            usuarioID = JSON.parse(localStorage.getItem('usuario')).user.id;
            usuarioLogin = JSON.parse(localStorage.getItem('usuario')).user.login;
        }
        //Verificando se o usuário é administrador
        if (JSON.parse(localStorage.getItem("usuario")).grupo.length > 0) {
            const adm = JSON.parse(localStorage.getItem("usuario")).grupo.filter(grupo => grupo.nome == "ADM");
            if (adm.length > 0) {
                setAdm(true);
            }
        }
        listaOrdemDeCompra();
    }, []);


    const alterarPagina = (event, value) => {
        paginas.paginaAtual = value;
        listaOrdemDeCompra(value);
    }

    const listaTemposDiligenciamento = async() => {
            /*
             Carrega a lista de registros de tempos de diligenciamento 
             */
            api.get("/temposDiligenciamento/")
                .then((response) => {
                    setTemposDiligenciamento(response.data.data);
                    console.log(response.data.data);
                })
                .catch((err) => {
                    console.error("Ops! ocorreu um erro lista-registro-diligenciamento: " + err);
                });
    };

    const listaOrdemDeCompra = async(page=1) => {
        /*
         A Url /followup/ sem parametros irá trazer os movimentos 1.1.08 que estão pendentes.
         Primeiro carrega o tempos de diligenciamento, depois a ordem de compra:
         */
        toast.loading("Buscando Ordens de Compra...", {toastId: "listaOC"});
        let listaTemposDiligenciamento = null;
        api.get("/temposDiligenciamento/")
         .then((responseTD) => {
            setTemposDiligenciamento(responseTD.data.data);
            listaTemposDiligenciamento = responseTD.data.data;
            //chamar lista de ordem de compra
            paginas.paginaAtual = page;
            api.get("/followup/?page="+ page)
                .then((response) => {

                    for(let i=0; i < response.data.data.length; i++) {
                        if (!Array.isArray(response.data.data[i])) {
                            let objeto = response.data.data[i];
                            objeto.color = "";
                            let dataEntrega = new Date(objeto.dataEntrega);
                            let dataAtual = new Date();
                            dataAtual.setHours(0,0,0,0);
                            dataEntrega.setHours(0,0,0,0);

                            //Pegar os objetos de Tempos de Diligenciamento de mesmo tipo_pedido da Ordem de Compra atual:
                            let listaTemposDiligenciamentoFiltrada = listaTemposDiligenciamento.filter((item) => item.tipo_pedido.includes(objeto.tipoPedido));
                            
                            //checar se prazo entrega está vencido:
                            //checar se tem registro de FollowUp cadastrado:
                            if (objeto.followup.length > 0) {
                                let dataEfetivaEntrega = new Date(objeto.followup[0].dataEfetivaEntrega);
                                dataEfetivaEntrega.setHours(0,0,0,0);

                                if (dataEfetivaEntrega < dataAtual) {
                                    //pedido atrasado com followup registrado, vermelho
                                    objeto.color = "#ef9a9a";
                                }
                                else if (dataEfetivaEntrega >= dataAtual) {
                                    //está no prazo, e diligenciado, fica verde
                                    objeto.color = "#a2c5a2";
                                    // Porém, Checar se a data de followup atingiu o prazo do TempoDiligenciamento, para o usuario contactar o fornecedor novamente:
                                    for(let i=0; i < listaTemposDiligenciamentoFiltrada.length; i++) {
                                        // Calcula a diferença em entre a data efetiva e dataAtual
                                        let diferencaEmMilissegundos = dataEfetivaEntrega - dataAtual;
                                        // Converte a diferença para dias
                                        let diferencaEmDias = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
                                        if (diferencaEmDias == listaTemposDiligenciamentoFiltrada[i].qtd_dias) {
                                            objeto.color = "#ffa500";
                                        }
                                    }
                                }
                            }
                            else if (dataEntrega < dataAtual) {
                                //pedido atrasado e sem followUp, vermelho
                                objeto.color = "#ef9a9a";
                            }
                            else if ( dataEntrega > dataAtual) {
                                //Ordem Compra no prazo.
                                //ordem de compra está no prazo, mas sem followUp, fica em amarelo:
                                objeto.color = "#ffe082";
                                //Checar se a data de entrega atingiu o prazo do TempoDiligenciamento, para o usuario contactar o fornecedor:
                                if (listaTemposDiligenciamentoFiltrada.length > 0) {
                                    for(let i=0; i < listaTemposDiligenciamentoFiltrada.length; i++) {
                                        // Calcula a diferença entre a data efetiva e dataAtual
                                        let diferencaEmMilissegundos = dataEntrega - dataAtual;
                                        // Converte a diferença para dias
                                        let diferencaEmDias = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
                                        if (diferencaEmDias == listaTemposDiligenciamentoFiltrada[i].qtd_dias) {
                                            objeto.color = "#ffa500";
                                        }
                                    }
                                }
                            }
                            //Caso nao haja registro followUp, usar DataEntrega da ordem de compra.
                            response.data.data[i] = objeto;
                        }
                    }
                    setOrdensDeCompra(response.data.data);
                    //Definindo a paginação
                    paginas.numeroPaginas = response.data.meta.last_page;

                })
                .catch((err) => {
                    console.error("Ops! ocorreu um erro ao carregar lista ordem compra: "+ err);
                });
            })//sucess tempos-diligenciamento.
            .catch((err) => {
                console.error("Ops! ocorreu um erro lista-registro-tempos-diligenciamento: " + err);
            });
            
            setTimeout(() => {
                toast.dismiss();
            }, 3000);
    }


    return (
        <>
            <Stack backgroundColor="#F5F5F5" height="100vh">

                <Stack flexDirection={"row"} overflow={"auto"} justifyContent="space-between" alignItems="center" backgroundColor="#222" padding="10px 20px">
                    <Typography sx={{ color: "#eee", fontSize: 13 }}>FOLLOW UP</Typography>
                    <Stack flexDirection={"row"}>

                        { adm ?
                            <Stack flexDirection={"row"} mr={mobile? "10px": "40px"}>
                                <Link onClick={()=> navigate("/temposDiligenciamento")} sx={{ color: "#fff", marginLeft: "10px", fontSize: 13 }}>Tempos de Diligenciamento</Link>
                            </Stack>
                            :
                            null
                        }

                        <Stack flexDirection={"row"} mr={mobile? "10px": "40px"}>
                            <Link onClick={()=> navigate("/modulos")} sx={{ color: "#888", marginLeft: "10px", fontSize: 13 }}>Ir para módulos</Link>
                        </Stack>
                        <Stack flexDirection={"row"}>
                            <Typography sx={{ color: "#eee" }} level='body-sm'>{usuarioLogin}</Typography>
                            <Link sx={{ color: "#888", marginLeft: "10px" }} onClick={() => [navigate("/"), localStorage.setItem("usuario", null)]}>sair</Link>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack ml={mobile ? "0px" : "20px"} mt="50px" overflow={"auto"} height={mobile ? "100%" : "calc(100vh - 200px)"} p={mobile ? "20px" : "50px"}>
                {/* Conteúdo da Tela */}
                <Typography level="h4">Ordens de Compra</Typography>

                <Typography mt={mobile ? "10px" : "30px"} sx={{ fontWeight: "bold" }} level="body-sm">Legenda</Typography>
                <Stack flexDirection={"row"} mt="10px">
                    <Stack flexDirection={"row"} alignItems="center">
                        <div style={{ width: "15px", height: "15px", backgroundColor: "#ef9a9a" }}></div>
                        <Typography ml={"10px"} level="body-sm">Atrasados</Typography>
                    </Stack>
                    <Stack flexDirection={"row"} alignItems="center" ml="20px">
                        <div style={{ width: "15px", height: "15px", backgroundColor: "#ffe082" }}></div>
                        <Typography ml={"10px"} level="body-sm">A Diligenciar</Typography>
                    </Stack>
                    <Stack flexDirection={"row"} alignItems="center" ml="20px">
                        <div style={{ width: "15px", height: "15px", backgroundColor: "#ffa500" }}></div>
                        <Typography ml={"10px"} level="body-sm">Tempo para diligenciar atingido</Typography>
                    </Stack>
                    <Stack flexDirection={"row"} alignItems="center" ml="20px">
                        <div style={{ width: "15px", height: "15px", backgroundColor: "#a2c5a2" }}></div>
                        <Typography ml={"10px"} level="body-sm">Diligenciados</Typography>
                    </Stack>
                </Stack>


                {mobile ?
                    <Stack mt="30px">
                        <Stack>
                            {ordensDeCompra.map(function (item, indice) {
                                if (!Array.isArray(item)) {
                                    return (
                                        <div style={{ backgroundColor: item.color }} key={indice} className="hover" onClick={() => navigate("/cadastroFollowUp/"+item.id+"/"+ item.numeroMov8)}>
                                            <Stack p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                                                <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Solicitação:</Typography> {item.solicitacao_id}</Typography>
                                                <Typography level="body-sm"><Typography level="title-md">Número OC:</Typography>{item.numeroMov8}</Typography>
                                                <Typography level="body-sm"><Typography level="title-md">Comprador:</Typography>{item.comprador}</Typography>
                                                <Typography level="body-sm"><Typography level="title-md">Data de Entrega:</Typography>{formatarDataBrasil(item.dataEntrega)}</Typography>
                                                <Typography level="body-sm"><Typography level="title-md">Data Efetiva Entrega:</Typography>
                                                    { item.followup.length > 0 ?
                                                        formatarDataBrasil(item.followup[0].dataEfetivaEntrega)
                                                        :
                                                        "-"
                                                    }
                                                </Typography>
                                                <Typography level="body-sm"><Typography level="title-md">Tipo Frete:</Typography>{item.tipoFrete}</Typography>
                                                {/* <Typography level="body-sm"><Typography level="title-md">Status RM:</Typography>{item.statusRM}</Typography> */}
                                                {/* <Typography level="body-sm"><Typography level="title-md">Grupo:</Typography>{item.grupo}</Typography> */}
                                            </Stack>
                                        </div>
                                    )
                                }
                            })}
                        </Stack>
                    </Stack>
                    :
                    <Stack mt="50px">
                        {/* Visualizacao desktop */}
                            <Table hoverRow stripe="even" noWrap>
                                <thead>
                                    <tr>
                                        <th style={{ height: "25px", width: "80px" }}>Solicitação</th>
                                        <th style={{ height: "25px", width: "100px" }}>Número OC</th>
                                        <th style={{ height: "25px", width: "300px" }}>Comprador</th>
                                        <th style={{ height: "25px", width: "150px" }}>Data da Entrega</th>
                                        <th style={{ height: "25px", width: "150px" }}>Data Efetiva Entrega</th>
                                        <th style={{ height: "25px", width: "200px" }}>Tipo Frete</th>
                                        {/* <th style={{ height: "25px", width: "200px" }}>Status RM</th> */}
                                        {/* <th style={{ height: "25px", width: "80px", textAlign: "center" }}>Grupo</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {ordensDeCompra.map(function (item, indice) { 
                                        // ignorar os arrays de item recebidos que nao pertencem a esse component
                                        if (!Array.isArray(item)) {
                                            return (
                                                <tr style={{ backgroundColor: item.color }} onClick={() => navigate("/cadastroFollowUp/"+item.id+"/"+ item.numeroMov8)} key={indice} className="hover">
                                                    <td style={{ padding: "0 10px" }}>
                                                        {item.solicitacao_id}</td>
                                                    <td style={{ padding: "0 10px" }}>
                                                        {item.numeroMov8}</td>
                                                    <td style={{ padding: "0 10px" }}>
                                                        {item.comprador}</td>
                                                    <td style={{ padding: "0 10px" }}>
                                                    {formatarDataBrasil(item.dataEntrega)}</td>

                                                    { item.followup.length > 0 ?
                                                        <td style={{ padding: "0 10px" }}>
                                                            {formatarDataBrasil(item.followup[0].dataEfetivaEntrega)}</td>
                                                        :
                                                        <td style={{ padding: "0 10px" }}> - </td>
                                                    }

                                                    <td style={{ padding: "0 10px" }}>
                                                        {item.tipoFrete}</td>
                                                    {/* <td style={{ padding: "0 10px" }}>
                                                        {item.statusRM}</td> */}
                                                    {/* <td style={{ padding: "0 10px", textAlign: "center" }} onClick={() => [setOpenModalGruposUsuario(true), setGruposUsuarioConsultado(item.grupo)]}>
                                                        <FaListUl></FaListUl>
                                                    </td> */}
                                                </tr>  
                                            );
                                        }
                                    })}
                                </tbody>
                            </Table>
                    </Stack>
                }
                <Stack mt={3} alignItems={mobile ? "center" : "flex-end"}>
                    <Pagination
                        onChange={alterarPagina}
                        count={paginas.numeroPaginas}
                        page={paginas.paginaAtual}
                        hideNextButton={paginas.paginaAtual == paginas.numeroPaginas}
                        hidePrevButton={paginas.paginaAtual == 1}
                    />
                </Stack>

                {/* <Stack mt={3} alignItems={mobile ? "center" : "flex-end"}>
                            <Pagination count={lastPageUsuario} page={pageUsuario} onChange={handleChangePage} />
                </Stack> */}
            </Stack>


            </Stack>
        </>
    );

}