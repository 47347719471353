function formatarMoeda(valor) {
  if (isNaN(valor)) {
    return 'R$ 0,00';
  }
  const valorFormatado = parseFloat(valor).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return valorFormatado;
}

function formatarMoedaSemCifrao(valor) {
  if (isNaN(valor)) {
    return '0,00';
  }
  // Converte o número para formato de moeda brasileira
  const numeroFormatado = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);

  // Remove o símbolo do real (R$)
  const numeroSemSimbolo = numeroFormatado.replace('R$', '').trim();

  return numeroSemSimbolo;
}

export { formatarMoeda, formatarMoedaSemCifrao };
