import { React, useState, useEffect } from "react";
import { FaRegTrashAlt } from 'react-icons/fa';
import { api } from '../../../../services/api';
import { toast } from 'react-toastify';
// css
import "../../../../App.css";

//componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";

//Mui
import {
    Button,
    Stack,
    Typography,
    Table,
    Link,
    Modal,
    ModalDialog,
    ModalClose,
    Sheet,
    FormControl,
    FormLabel,
    Input,
    Divider,
    Box,
    Select,
    Option,
    ToggleButtonGroup,
    Switch,
    FormHelperText,
} from "@mui/joy";

import { Pagination } from '@mui/material/';
//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';
let token = "";
let usuarioID = 0;
let usuarioLogin = "";

export default function LeadTime() {

    //Verificando se o dispositivo é mobile
    const mobile = useMediaQuery('(max-width:1200px)');
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [modal, setModal] = useState(false);
    const [leadTimeObj, setLeadTimeObj] = useState({
        id: '',
        tipoPedido: '',
        prazo: '',
    });
    const [novoObj, setNovoObj] = useState(false);
    const [listaObjetos, setObjetos] = useState([]);
    const [excluir, setExcluir] = useState(false);

    //Variáveis de cadastro de leadTime:
    const [tipoPedido, setTipoPedido] = useState('');
    const [prazo, setPrazo] = useState('');

    const [erro, setErro] = useState({
        tipoPedido: '', prazo: '', prazoInvalido: false
    });
    const [objConsultado, setObjConsultado] = useState(
        {
            tipoPedido: '',
            descricao: '',
            prazo: '',
        });
    const [readMode, setReadMode] = useState(false);


    useEffect(() => {
        //Obtendo o token
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            token = JSON.parse(localStorage.getItem('usuario')).token;
            usuarioID = JSON.parse(localStorage.getItem('usuario')).user.id;
            usuarioLogin = JSON.parse(localStorage.getItem('usuario')).user.login;
        }

        listaLeadTime();
    }, []);

    const listaLeadTime = (page = 1) => {
        //TODO: Chamando api de leadTime:
        api.get("/tiposPedido/?page=" + page,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            }
        )
            .then((response) => {
                console.log(response.data);
                // setPage(page);
                // setLastPage(response.data.meta.last_page);
                setObjetos(response.data);
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro : " + err);
                toast.error(JSON.stringify(err.response.data));
            });
    }


    const validaDados = (prazo) => {
        const erros = [];
        setErro({
            tipoPedido: '', prazo: '', prazoInvalido: false
        });
        if (!prazo || prazo <= 0) {
            erros.prazo = 'Informe o prazo.';
            erros.prazoInvalido = true;
        }

        if (Object.keys(erros).length > 0) {
            setErro(erros);
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = () => {
        /* Validação dos dados de cadastro */
        let retornoValidacao = null;
        retornoValidacao = validaDados(prazo);
        //Se passou na validacao, chamar Api para salvar dados:
        if (retornoValidacao) {
            //encaminhar para POST
            criaObj();
        }
        else {
            return;
        }
    }

    const criaObj = () => {
        //Criar um novo usuario no Banco de dados
        const dadosObjeto = {
            tipoPedido: objConsultado.tipoPedido,
            prazo: prazo, //o prazo é um useState pois é o unico campo modificável da tabela leadtime.
        }
        api.post("/tiposPedido/",
            dadosObjeto, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token } })
            .then((response) => {
                toast.success("LeadTime cadastrado com sucesso!");
                setModal(false);
                listaLeadTime(); //recarrega listagem
                limparForm();
            }).catch(error => {
                toast.error("Ocorreu um erro ao cadastrar o LeadTime. Por favor, tente novamente em alguns instantes.");
                console.log("Erro no cria Leadtime" + error);
                toast.error(JSON.stringify(error.response.data));
            });
    }

    // const editaObj = () => {
    //     //Editar objeto no Banco de dados
    //     const dadosObjeto = {
    //         tipoPedido: tipoPedido,
    //         prazo: prazo,
    //     }

    //     api.put("/leadTime/" + objConsultado.id,
    //         dadosObjeto, { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token } }).
    //         then((response) => {
    //             toast.success("LeadTime atualizado com sucesso!");
    //             setModal(false);
    //             listaLeadTime();
    //             limparForm();
    //         }).catch(error => {
    //             toast.error("Ocorreu um erro ao atualizar o LeadTime. Por favor, tente novamente em alguns instantes.");
    //             console.log("Erro no edita LeadTime" + error);
    //             toast.error(JSON.stringify(err.response.data));
    //         });
    // }

    const limparForm = () => {
        /*  Limpa o formulário */
        setErro({
            tipoPedido: '', prazo: '', prazoInvalido: false
        });
        setObjConsultado({ tipoPedido: '', descricao: '', prazo: '' });
        setPrazo('');
        setReadMode(false);

    }

    // const removeObjeto = (objeto_id) => {
    //     api.delete("/leadTime/" + objeto_id + "/", { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token } })
    //         .then((response) => {
    //             toast.info("LeadTime removido com sucesso!");
    //             //recarrega para refazer a lista de usuários:
    //             listaLeadTime();
    //             setObjConsultado({});
    //             setExcluir(false);
    //         })
    //         .catch(error => {
    //             toast.error("Não é possível remover este registro.Ele pode estar sendo usado por outro cadastro.");
    //             toast.error(JSON.stringify(error.response.data));
    //          });
    // }


    const exibirModalCadastro = () => {
        setModal(true);
        setReadMode(false);
    }

    return (
        <Stack backgroundColor="#F5F5F5" height="100vh">
            <Header mobile={mobile} />
            <Sidebar mobile={mobile} />

            <Stack ml={mobile ? "0px" : "280px"} mt="50px" overflow={"auto"} height={mobile ? "100%" : "calc(100vh - 200px)"} p={mobile ? "20px" : "50px"}>
                {/* Conteudo da tela */}
                <Typography level="h4">Cadastro de LeadTime</Typography>

                {/* <Stack display={"flex"} ml={mobile ? "0" : "30px"} mr={mobile? null:"470px"} mt={"20px"} flexDirection={"row"} alignItems="end" justifyContent={"end"}>
                    <Button onClick={() => exibirModalCadastro()}>Adicionar Novo Registro</Button>
                </Stack> */}

                {mobile ?
                    // Visual mobile
                    <Stack mt="30px">
                        <Stack>
                            {listaObjetos.map(function (item, indice) {
                                return (
                                    <div key={indice} className="hover" onClick={() => [setModal(true), setObjConsultado({ tipoPedido: item.tipoPedido, descricao: item.descricao, prazo: item.prazo }), setPrazo(item.prazo)]}>
                                        <Stack p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                                            {/* <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Solicitação:</Typography> 46434</Typography> */}
                                            <Typography level="body-sm"><Typography level="title-md">Tipo Pedido:</Typography>{item.tipoPedido}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Descrição:</Typography>{item.descricao}</Typography>
                                            <Typography level="body-sm"><Typography level="title-md">Prazo:</Typography>{item.prazo}</Typography>
                                            {/* <Typography level="body-sm"><Typography level="title-md">Ações:</Typography><Link onClick={() => removeObjeto(item.id)}><FaRegTrashAlt size={20} color="#990000"></FaRegTrashAlt></Link></Typography> */}
                                        </Stack>
                                    </div>
                                )
                            })}
                        </Stack>
                    </Stack>
                    :
                    <Stack mt="50px">
                        {/* Visualizacao desktop*/}
                        <Table hoverRow stripe="even" noWrap sx={{ maxWidth: "70%", }}>
                            <thead>
                                <tr>
                                    <th style={{ height: "25px" }}>Código</th>
                                    <th style={{ height: "25px", width: "60%" }}>Tipo Pedido</th>
                                    <th style={{ height: "25px" }}>Prazo (dias)</th>
                                    {/* <th style={{ height: "25px" }}>Ações</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {listaObjetos.map(function (item, indice) {
                                    return (
                                        <tr key={indice} className="hover">
                                            <td style={{ padding: "0 10px" }} onClick={() => [setModal(true), setObjConsultado({ tipoPedido: item.tipoPedido, descricao: item.descricao, prazo: item.prazo }), setPrazo(item.prazo)]}>
                                                {item.tipoPedido}</td>
                                            <td style={{ padding: "0 10px" }} onClick={() => [setModal(true), setObjConsultado({ tipoPedido: item.tipoPedido, descricao: item.descricao, prazo: item.prazo }), setPrazo(item.prazo)]}>
                                                {item.descricao}</td>
                                            <td style={{ padding: "0 10px" }} onClick={() => [setModal(true), setObjConsultado({ tipoPedido: item.tipoPedido, descricao: item.descricao, prazo: item.prazo }), setPrazo(item.prazo)]}>
                                                {item.prazo}</td>
                                            {/* <td style={{ padding: "0 10px"}}>
                                                    <Link onClick={() => removeObjeto(item.id)}><FaRegTrashAlt size={20} color="#990000"></FaRegTrashAlt></Link>
                                            </td> */}
                                            {/* <td style={{ padding: "0 10px", textAlign: "center" }} onClick={() => [setOpenModalGruposUsuario(true), setGruposUsuarioConsultado(item.grupo)]}>
                                                <FaListUl></FaListUl>
                                            </td> */}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Stack>
                }


                <Modal
                    aria-labelledby="modal-title"
                    aria-describedby="modal-desc"
                    open={modal}
                    disableScrollLock={true}
                    onClose={() => [setModal(false), limparForm()]}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <Sheet
                        variant="outlined"
                        sx={{
                            minWidth: mobile ? "70%" : null,
                            maxWidth: "80%",
                            borderRadius: 'md',
                            p: 3,
                            boxShadow: 'lg',
                        }}
                    >
                        <ModalClose
                            variant="outlined"
                            sx={{
                                top: 'calc(-1/4 * var(--IconButton-size))',
                                right: 'calc(-1/4 * var(--IconButton-size))',
                                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                                borderRadius: '50%',
                                bgcolor: 'background.surface',
                            }}
                        />
                        <Typography
                            component="h2"
                            id="modal-title"
                            level="h4"
                            textColor="inherit"
                            fontWeight=""
                            mb={1}
                            sx={{ color: "#1e88e5" }} >
                            Cadastro de Prazo LeadTime
                        </Typography>
                        <Stack maxHeight={"100%"}>
                            <Stack>
                                <Stack flexDirection={"column"} mt="30px">

                                    <Stack flex={1} maxWidth={mobile ? "" : "240px"} minWidth={mobile ? "100%" : "220px"} ml={mobile ? "0" : "10px"} mt={mobile ? "20px" : "5px"}>
                                        <Typography fontSize="14px">Tipo de Pedido:</Typography>
                                        <Typography fontSize="18px">{objConsultado.tipoPedido} - {objConsultado.descricao}</Typography>
                                    </Stack>

                                    <Stack flex={1} maxWidth={mobile ? "" : "240px"} minWidth={mobile ? "100%" : "220px"} ml={mobile ? "0" : "10px"} mt={mobile ? "20px" : "2px"}>
                                        <FormControl error={erro.prazoInvalido} sx={{ mb: "10px" }}>
                                            <FormLabel>Prazo</FormLabel>
                                            <Input type="number" value={prazo} onChange={(e) => setPrazo(e.target.value)} />

                                            {erro.prazoInvalido && (
                                                <FormHelperText error={erro.prazoInvalido}>
                                                    {erro.prazo}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Stack>

                                </Stack>

                                <Stack ml={mobile ? "0" : "30px"} flexDirection={"row"} mt={mobile ? "20px" : "20px"} alignItems="center" justifyContent={"flex-end"}>
                                    <Button disabled={readMode} onClick={handleSubmit}>Salvar</Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Sheet>
                </Modal>



            </Stack>

        </Stack>
    )


}