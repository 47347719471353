import { useState, useEffect } from "react";

import { formatarDataHoraBrasil } from "../../../../utils/datas";
import { toast } from 'react-toastify';

// Icones

//componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";

//Mui
import { Stack, Typography, Button, Modal, Sheet, ModalClose, Select, Option, Dropdown, MenuButton, Menu, MenuItem, Switch, Radio, List, ListItem, ListItemButton, ListItemContent, ListItemDecorator, FormHelperText, Table } from "@mui/joy";

//Roteamento
import { useNavigate, useLocation, useParams } from "react-router-dom";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';
import { api, RM } from "../../../../services/api";

//Largura da tela
const larguraTela = window.innerWidth;

export default function FluxoAprovacao(props) {

    const navigate = useNavigate();
    const location = useLocation(); //navegacao entre components
    const params = useParams();

    //Verificando se o dispositivo é mobile
    const mobile = useMediaQuery('(max-width:1200px)');

    //Cores dos status
    const coresStatus = {
        "EM ANDAMENTO": "#1976d2",
        "NÃO INICIADO": "#aaa",
        "RECEBIDO": "#81c784",
        "FATURADO": "",
        "PENDENTE": "#fff59d",
        "NORMAL": "",
        "BAIXADO": "#f57c00",
        "TERMINADO": "#333",
        "CANCELADO": "#e57373",
        "AGUARDANDO ANÁLISE": "",
        "PARC. RECEBIDO": "#c5cae9",
        "PARC. QUITADO": "",
        "NÃO PROCESSADO": "#ff8a80",
        "BLOQUEADO": "",
        "QUITADO": "",
        "EM FATURAMENTO": "",

    }

    const [numeroProcesso, setNumeroProcesso] = useState("");
    const [itens, setItens] = useState([]);

    useEffect(() => {
        // TODO: O parametro processo vem do component Solicitacao
        if (location.state) {
            setNumeroProcesso(location.state.processo);
            obterDadosDosItens(location.state.movimento);
        }
        else {
            navigate(-1);
        }
    }, [])

    const obterDadosDosItens = (movimento) => {
        if (movimento){
            api.get('/acompanhamentoItens/' + movimento)
            .then(({ data }) => {
                setItens(data);
            })
            .catch((err) => {
                console.log(err);
                toast.error(JSON.stringify(err.response.data));
                toast.error("Ocorreu um erro ao obter os itens");
            })
        }
    }

    const CardItem = (props) => {
        return (
            <Stack mb="20px" className="hover" boxShadow={"lg"}>
                <Stack padding="20px" border={"2px solid " + coresStatus[props.status]} sx={{borderTopLeftRadius: "5px", borderTopRightRadius: "5px"}}>
                    <Typography sx={{ marginBottom: "5px" }}><strong>Código:</strong> {props.codigo}</Typography>
                    <Typography sx={{ marginBottom: "5px" }}><strong>Descrição:</strong> {props.descricao}</Typography>
                    <Typography sx={{ marginBottom: "5px" }}><strong>Data Entrega:</strong> {props.dataMovimento}</Typography>
                    <Typography sx={{ marginBottom: "5px" }}><strong>Responsável:</strong> {props.responsavel}</Typography>
                    <Typography><strong>Movimento:</strong> {props.movimento}</Typography>
                </Stack>
                <Stack padding="5px" textAlign={"center"} alignItems="center" sx={{ backgroundColor: coresStatus[props.status], borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", color: "#333", fontWeight: 500 }}>
                    {props.status}
                </Stack>
            </Stack>
        );
    }

    return (
        <Stack backgroundColor="#F5F5F5" height="100%" minHeight={"100vh"}>
            <Header mobile={mobile} notification />
            <Sidebar mobile={mobile} />

            <Stack ml={mobile ? "0px" : "280px"} mt="50px" overflow={"auto"} height={mobile ? "100%" : "calc(100vh - 200px)"} p={mobile ? "20px" : "50px"}>
                {/* Conteúdo da Tela */}
                <Typography level="h4">Acompanhamento dos Itens</Typography>
                <Stack flexDirection="row" alignItems="center" mt="10px">
                    <Button onClick={() => navigate("/solicitacao/" + numeroProcesso)} sx={{ marginRight: "10px" }}>Voltar</Button>
                    <Typography>Processo: #{numeroProcesso}</Typography>
                </Stack>

                {mobile ?
                    <Stack mt="20px">
                        {itens.map((item, indice) => {
                            return (
                                <CardItem
                                    key={indice}
                                    codigo={item.CODIGO}
                                    descricao={item.PRODUTO}
                                    dataMovimento={item.DATA}
                                    responsavel={item.RESPONSAVEL}
                                    movimento={item.MOVIMENTO}
                                    status={item.STATUS}
                                />
                            )
                        })}
                    </Stack>
                    :
                    <Stack mt="20px">
                        <Table noWrap>
                            <thead>
                                <tr>
                                    <th style={{ height: "25px", width: "50px", textAlign: "center" }}>Item</th>
                                    <th style={{ height: "25px", width: "50px", textAlign: "center" }}>Código</th>
                                    <th style={{ height: "25px", maxWidth: "300px" }}>Descrição</th>
                                    <th style={{ height: "25px", width: "120px", textAlign: "center" }}>Data Entrega</th>
                                    <th style={{ height: "25px", width: "120px", textAlign: "center" }}>Responsavel</th>
                                    <th style={{ height: "25px", width: "300px" }}>Movimento</th>
                                    <th style={{ height: "25px", width: "150px" }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {itens.map((item, indice) => {
                                    return (
                                        <tr key={indice}>
                                            <td style={{ padding: "0 10px", textAlign: "center" }}>{indice + 1}</td>
                                            <td style={{ padding: "0 10px", textAlign: "center" }}>{item.CODIGO}</td>
                                            <td style={{ padding: "0 10px" }}>{item.PRODUTO}</td>
                                            <td style={{ padding: "0 10px", textAlign: "center" }}>{item.DATA}</td>
                                            <td style={{ padding: "0 10px", textAlign: "center" }}>{item.RESPONSAVEL}</td>
                                            <td style={{ padding: "0 10px" }}>{item.MOVIMENTO}</td>
                                            <td style={{ padding: "0 10px", fontWeight: "bold", backgroundColor: coresStatus[item.STATUS], color: "#333" }}>{item.STATUS}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Stack>
                }
            </Stack>
        </Stack>
    );
}