import { toast } from "react-toastify";
import { api } from "../../services/api";

async function obterTodasEtapas() {
    try {
        const response = await api.get('/workflow');
        const result = response.data.data;
        return (result);
    } catch (err) {
        toast.error("Ocorreu um erro ao listar etapas");
        console.log(err);
    }
}

function definirProximaEtapa(formulario, etapaAtual, decisao,
    etapaARetornar = null, emergencial = false, tipoPedido = null, totalCotacao = null, criterioAtendimento = null) {

    //Detectando o cancelamento
    if (decisao == "Cancelado") return "Cancelado";

    switch (etapaAtual) {
        case "Solicitante":
            return "Aprov. Tecnico";

        case "Aprov. Tecnico":
            if (decisao == "Aprovado") {
                return "Aprov. Gerencial";
            }
            else if (decisao == "Reprovado") {
                return "Solicitante";
            }

            break;

        case "Aprov. Gerencial":
            if (decisao == "Aprovado") {
                if (formulario.tipoPedido == "007") { //007 - Locação de Equipamentos
                    return "Aprov. Institucional"
                }
                else {
                    return "Compras"; //Etapa do Rm, o processo aguarda a cotação
                }
            }
            else if (decisao == "Reprovado") {
                return ("Solicitante");
            }

            break;

        case "Aprov. Institucional":
            if (decisao == "Aprovado") {
                return "Compras"; //Etapa do Rm, o processo aguarda a cotação
            }
            else if (decisao == "Reprovado") {
                return ("Solicitante");
            }

            break;

        case "Aprov. mcp1":
            if (decisao == "Aprovado") {
                return "Aprov. mcp2";
            }
            else if (decisao == "Reprovado") {
                return "Compras"; //mcp1 é o primeiro depois da cotacao, só poderá voltar para ela caso reprove
            }

            break;

        case "Aprov. mcp2":
            if (decisao == "Aprovado") {
                return "Aprov. gestor adm suprimentos";
            }
            else if (decisao == "Reprovado") {
                return etapaARetornar;
            }

            break;

        case "Aprov. gestor adm suprimentos":
            if (decisao == "Aprovado") {
                if (emergencial) {
                    //se tipo pedido for da alçada de manutenção e equipamentos:
                    if ((tipoPedido == "005") || (tipoPedido == "006") || (tipoPedido == "007")) {
                        if (totalCotacao > 2000) {
                            return "Aprov. diretoria suporte operacional";
                        }
                        else {
                            return "diligenciamento";
                        }
                    }
                    //caso seja tipo pedido de 'materiais e serviços':
                    else {
                        if (totalCotacao > 5000) {
                            return "Aprov. diretoria suporte operacional"
                        }
                        else {
                            return "diligenciamento";
                        }
                    }
                }
                //caso nao seja emergencial:
                else {
                    return "Aprov. gestor contrato/equipamentos"
                }
            }
            else if (decisao == "Reprovado") {
                return etapaARetornar;
            }

            break;

        case "Aprov. gestor contrato/equipamentos":
            if (decisao == "Aprovado") {
                //se tipo pedido for da alçada de manutenção e equipamentos:
                if ((tipoPedido == "005") || (tipoPedido == "006") || (tipoPedido == "007")) {
                    return "Aprov. gestor corporativo equipamentos"
                }
                //caso seja tipo pedido de 'materiais e serviços'
                else {
                    if (totalCotacao > 5000) {
                        return "Aprov. diretoria suporte operacional"
                    }
                    else {
                        return "diligenciamento";
                    }
                }
            }
            else if (decisao == "Reprovado") {
                return etapaARetornar;
            }

            break;

        case "Aprov. gestor corporativo equipamentos":
            if (decisao == "Aprovado") {
                if (totalCotacao > 2000) {
                    return "Aprov. diretoria suporte operacional";
                }
                else {
                    return "diligenciamento";
                }
            }
            else if (decisao == "Reprovado") {
                return etapaARetornar;
            }

            break;

        case "Aprov. diretoria suporte operacional":
            if (decisao == "Aprovado") {
                //tipo pedido de 'manutencao e equipamentos'
                if ((tipoPedido == "005") || (tipoPedido == "006") || (tipoPedido == "007")) {
                    return "Aprov. diretoria equipamentos";
                }
                //tipo pedido 'materiais e servicos'
                else {
                    return "Aprov. diretoria operações";
                }
            }
            else if (decisao == "Reprovado") {
                return etapaARetornar;
            }

            break;

        case "Aprov. diretoria equipamentos":
            if (decisao == "Aprovado") {
                return "diligenciamento";
            }
            else if (decisao == "Reprovado") {
                return etapaARetornar;
            }
            break;

        case "Aprov. diretoria operações":
            if (decisao == "Aprovado") {
                if (totalCotacao > 50000) {
                    return "Aprov. diretoria geral";
                }
                else {
                    return "diligenciamento";
                }
            }
            else if (decisao == "Reprovado") {
                return etapaARetornar;
            }
            break;

        case "Aprov. diretoria geral":
            if (decisao == "Aprovado") {
                return "diligenciamento";
            } else if (decisao == "Reprovado") {
                return etapaARetornar;
            }
            break;

        default:
            break;
    }
}

async function obterProximaEtapa(formulario, etapaAtual, decisao, etapaARetornar, precoTotalCotacao = null) {
    const etapas = await obterTodasEtapas();

    const indiceEtapaAtual = etapas.findIndex(etapa => etapa.label === etapaAtual);
    const emergencial = formulario.criterio == "MP" || formulario.criterio == "UR";
    //const emergencial = false;
    const tipoPedido = formulario.tipoPedido;

    //001 002 003 004 008 - Materiais e Serviços
    //005 006 007 - Manutenção e Equipamentos

    //TODO: Ainda esta para definir como será considerado o total da cotação
    var totalCotacao = 0;
    if (precoTotalCotacao != null) {
        totalCotacao = precoTotalCotacao;

    }

    //Caso esteja na ultima etapa do workflow (Sem considerar regras de negócio)
    // if (indiceEtapaAtual == etapas.length - 1) return console.log("diligenciamento");

    //Detectando o cancelamento
    if (decisao == "Cancelado") return "Cancelado";

    console.log(etapas[indiceEtapaAtual].condicao);
    const proximaEtapa = eval(etapas[indiceEtapaAtual].condicao);

    return proximaEtapa;
}

async function obterEtapasAnteriores(etapaAtual) {
    const etapas = await obterTodasEtapas();

    //Obtendo o indice da etapa atual no array de etapas
    const indiceEtapaAtual = etapas.findIndex(etapa => etapa.label === etapaAtual);
    const indiceAprovInst = etapas.findIndex(etapa => etapa.label === "Aprov. Institucional");

    let etapasAnteriores = [];
    for (let i = indiceAprovInst + 1; i < indiceEtapaAtual; i++) {
        if (etapas[i] && etapas[i].label) {
            etapasAnteriores.push(etapas[i].label);
        }
    }

    //Invertendo a ordem do array
    etapasAnteriores.reverse();

    return etapasAnteriores;
}

async function obterWorkflowEtapaAtual(etapaAtual) {
    const etapas = await obterTodasEtapas();
    //obtendo indice da etapa atual:
    const indiceEtapaAtual = etapas.findIndex(etapa => etapa.label === etapaAtual);
    
    return etapas[indiceEtapaAtual];
}


export { definirProximaEtapa, obterProximaEtapa, obterEtapasAnteriores, obterTodasEtapas, obterWorkflowEtapaAtual }
