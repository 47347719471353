import { useState, useEffect, memo } from "react";
import { Button, Card, Checkbox, Input, Link, Option, Radio, RadioGroup, Select, Sheet, Stack, Typography } from "@mui/joy";
import { IoSearch } from "react-icons/io5";

// Componentes
import AccordionDiligenciamento from "../../../../components/AccordionDiligenciamento";
import CardDiligenciamento from "../../../../components/CardDiligenciamento";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';

//Imagens
import logoDiligenciamento from "../../../../assets/diligenciamento.png";

//Icones
import { IoAlertCircleSharp } from "react-icons/io5";

//Servicos
import { obterPermissoesUsuario } from "../../../../services/permissoes";

//Métodos
import { buscarProcessos, obterDadosOffline, obterTiposPedido } from './metodos';
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const obterData = (mesesAtras = 0) => {
    const date = new Date();
    if (mesesAtras > 0) {
        date.setMonth((date.getMonth() + 1) - mesesAtras);
        if (date.getMonth() == 0) {
            date.setMonth(11);
            date.setFullYear(date.getFullYear() - 1);
            return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, "0") + '-' + date.getDate().toString().padStart(2, "0");
        }
        return date.getFullYear() + '-' + (date.getMonth()).toString().padStart(2, "0") + '-' + date.getDate().toString().padStart(2, "0");
    }
    return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, "0") + '-' + date.getDate().toString().padStart(2, "0");
}

const formatarCodEquip = (codEquip) => {
    if (codEquip.length == 8 && !isNaN(codEquip)) {
        const stringFormatada = codEquip.replace(/(\d{1})(\d{3})(\d{4})/, '$1.$2.$3');
        return stringFormatada;
    }
    return codEquip;
}

const codigosStatus = {
    1: "EM ANDAMENTO",
    2: "CANCELADO",
    3: "CONCLUÍDO"
}

let codigoLocalEstoque = {}

export default function Painel() {

    let usuarioLogado = (JSON.parse(localStorage.getItem('usuario')).user.login);
    const navigate = useNavigate();

    const mobile = useMediaQuery('(max-width:1200px)');

    const [filtros, setFiltros] = useState({ dataInicio: obterData(1), dataFim: obterData(), statusOs: "1", atrasados: false, iniciarEm: "O.S", localEstoque: "", codEquip: "", numOs: "", nomeResponsavel: "" });
    const [locaisEstoque, setLocaisEstoque] = useState([]);
    const [processos, setProcessos] = useState({ listagem: [], Contagem: {} });
    const [etapaAtualSelecionada, setEtapaAtualSelecionada] = useState("");
    const [ultimaAtualizacao, setUltimaAtualizacao] = useState("00/00/0000 as 00:00");
    const [tiposPedido, setTiposPedido] = useState([]);

    useEffect(() => {
        toast.loading("Obtendo permissões...", { toastId: 1 });
        obterPermissoesUsuario(usuarioLogado).then(response => {
            setLocaisEstoque(response[1]);
            response[1].forEach(localEstoque => {
                codigoLocalEstoque[localEstoque.split(" - ")[0]] = localEstoque
            });

            //Obtendo dados offline
            setFiltros(obterDadosOffline().filtros ? obterDadosOffline().filtros : filtros);
            setProcessos(obterDadosOffline().processos ? obterDadosOffline().processos : processos);
            setEtapaAtualSelecionada(obterDadosOffline().etapaAtual ? obterDadosOffline().etapaAtual : etapaAtualSelecionada);
            setUltimaAtualizacao(obterDadosOffline().dataEHora ? obterDadosOffline().dataEHora : ultimaAtualizacao);
            toast.dismiss();
        });

        obterTiposPedido().then(tiposPedido => {
            setTiposPedido(tiposPedido);
        })
    }, []);

    return (
        <Stack>
            {
                mobile ?
                    <Stack height="95vh" p="30px" justifyContent={"center"} alignItems="center">
                        <IoAlertCircleSharp color="#ffb300" size={30} />
                        <Typography textAlign={"center"}>O diligenciamento não é suportado por smartphones e tablets</Typography>
                    </Stack>
                    :
                    <Stack>
                        {/* Header */}
                        <Stack flexDirection={"row"} justifyContent="space-between" alignItems="center" backgroundColor="#222" padding="10px 20px">
                            <Stack flexDirection={"row"} alignItems="center">
                                {/* <img src={logoDiligenciamento} style={{ width: "30px", height: "30px", borderRadius: "15px" }} /> */}
                                <Typography sx={{ color: "#eee", fontSize: 20, marginLeft: "15px" }}>DILIGENCIAMENTO</Typography>
                            </Stack>
                            <Stack flexDirection={"row"}>
                                <Stack>
                                    <Link onClick={() => navigate("/dashboard")} sx={{ marginRight: "30px" }}>Dashboard</Link>
                                </Stack>
                                <Stack>
                                    <Link onClick={() => navigate("/modulos")} sx={{ marginRight: "30px" }}>Ir para módulos</Link>
                                </Stack>
                                <Stack flexDirection={"row"} mr="40px">
                                    <Typography sx={{ color: "#888" }}>Ultima Atualização:</Typography>
                                    <Typography sx={{ color: "#eee", marginLeft: "10px" }}>{ultimaAtualizacao}</Typography>
                                </Stack>
                                <Stack flexDirection={"row"}>
                                    <Typography sx={{ color: "#eee" }}>{usuarioLogado}</Typography>
                                    <Link onClick={() => [navigate("/"),]} sx={{ color: "#888", marginLeft: "10px" }}>sair</Link>
                                </Stack>
                            </Stack>
                        </Stack>
                        {/* Corpo da aplicação */}
                        <Stack margin="20px">
                            {/* Filtragens */}
                            <Stack flexDirection={"row"} justifyContent="space-between" alignitems="center">
                                <Stack padding="20px" height="160px" border="1px solid #ddd" boxShadow={"lg"} borderRadius="5px" sx={{ alignSelf: "flex-start", backgroundColor: "#fafafa" }}>
                                    <Stack flexDirection={"row"} alignItems='center'>
                                        <Stack mr="20px" sx={{ width: "150px" }}>
                                            <Typography>Data Inicio:</Typography>
                                            <Input value={filtros.dataInicio} onChange={(e) => setFiltros(prevState => ({ ...prevState, dataInicio: e.target.value }))} type="date" />
                                        </Stack>
                                        <Stack mr="20px" sx={{ width: "150px" }}>
                                            <Typography>Data Fim:</Typography>
                                            <Input value={filtros.dataFim} onChange={(e) => setFiltros(prevState => ({ ...prevState, dataFim: e.target.value }))} type="date" />
                                        </Stack>
                                        {/* <Stack mr="20px">
                                <Typography>Status O.S:</Typography>
                                <Select value={filtros.statusOs} onChange={(e, newValue) => setFiltros(prevState => ({ ...prevState, statusOs: newValue }))} sx={{ width: "170px" }}>
                                    <Option value="1">Em Andamento</Option>
                                    <Option value="2">Cancelado</Option>
                                    <Option value="3">Concluído</Option>
                                </Select>
                            </Stack> */}
                                        <Stack mr="20px" height="64px">
                                            <Typography>Somente Atrasados:</Typography>
                                            <Checkbox checked={filtros.atrasados} onChange={(e) => setFiltros(prevState => ({ ...prevState, atrasados: e.target.checked }))} sx={{ margin: "auto" }} />
                                        </Stack>
                                        <Stack mr="20px" height="64px">
                                            <Typography>Iniciar em:</Typography>
                                            <RadioGroup
                                                orientation="horizontal"
                                                aria-labelledby="segmented-controls-example"
                                                name="justify"
                                                value={filtros.iniciarEm}
                                                onChange={(event) => setFiltros(prevState => ({ ...prevState, iniciarEm: event.target.value }))}
                                                sx={{
                                                    minHeight: 35,
                                                    padding: '4px',
                                                    borderRadius: '12px',
                                                    bgcolor: 'neutral.softBg',
                                                    '--RadioGroup-gap': '4px',
                                                    '--Radio-actionRadius': '8px',
                                                }}
                                            >
                                                {['O.S', 'PMS'].map((item) => (
                                                    <Radio
                                                        key={item}
                                                        color="neutral"
                                                        value={item}
                                                        disableIcon
                                                        label={item}
                                                        variant="plain"
                                                        sx={{
                                                            px: 2,
                                                            alignItems: 'center',
                                                        }}
                                                        slotProps={{
                                                            action: ({ checked }) => ({
                                                                sx: {
                                                                    ...(checked && {
                                                                        bgcolor: 'background.surface',
                                                                        boxShadow: 'sm',
                                                                        '&:hover': {
                                                                            bgcolor: 'background.surface',
                                                                        },
                                                                    }),
                                                                },
                                                            }),
                                                        }}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </Stack>
                                    </Stack>
                                    <Stack mt="20px" flexDirection={"row"} alignItems='center'>
                                        <Stack mr="20px">
                                            <Typography>Local de Estoque:</Typography>
                                            <Select value={filtros.localEstoque} onChange={(e, newValue) => setFiltros(prevState => ({ ...prevState, localEstoque: newValue }))} sx={{ width: "385px" }}>
                                                {locaisEstoque.map((localEstoque, indice) => {
                                                    return (
                                                        <Option key={indice} value={localEstoque.split(" - ")[0]}>{localEstoque}</Option>
                                                    )
                                                })}
                                            </Select>
                                        </Stack>
                                        <Stack mr="20px" sx={{ width: "120px" }}>
                                            <Typography>Cód Equip:</Typography>
                                            <Input value={formatarCodEquip(filtros.codEquip)} onChange={(e) => setFiltros(prevState => ({ ...prevState, codEquip: e.target.value }))} placeholder="0.000.0000" />
                                        </Stack>
                                        <Stack mr="20px" sx={{ width: "100px" }}>
                                            <Typography>Num. O.S:</Typography>
                                            <Input value={filtros.numOs} onChange={(e) => setFiltros(prevState => ({ ...prevState, numOs: e.target.value }))} placeholder="000000" />
                                        </Stack>
                                        <Stack mr="20px" sx={{ width: "170px" }}>
                                            <Typography>Nome Responsável:</Typography>
                                            <Input value={filtros.nomeResponsavel} onChange={(e) => setFiltros(prevState => ({ ...prevState, nomeResponsavel: e.target.value }))} />
                                        </Stack>
                                    </Stack>
                                    <Stack alignSelf={"flex-end"} mt="20px" mr="20px">
                                        <Button onClick={() => [buscarProcessos(filtros, processos, setProcessos, "", setUltimaAtualizacao), setEtapaAtualSelecionada("")]} sx={{ position: "relative", right: "-64px", bottom: "13px", borderRadius: "50%", width: "45px", padding: "10px", height: "45px" }}><IoSearch size={26} /></Button>
                                    </Stack>
                                </Stack>
                                <Stack mr="0px" ml="50px">
                                    <Stack boxShadow={"md"} maxWidth="400px" padding="20px" borderRadius={"5px"} backgroundColor="#ffecb3" border="1px solid #ffe082 ">
                                        <Typography fontSize={14} fontWeight={"bold"} sx={{ marginBottom: "5px" }}>DICA DE USO</Typography>
                                        <Typography fontSize={14} sx={{ textAlign: "justify" }}>Seja preciso em suas buscas, intervalos superiores a 1 mês podem apresentar lentidão e travamentos devido ao volume de dados.</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            {/* Cards */}

                            
                            <Typography mt={"30px"} sx={{ fontWeight: "bold" }} level="body-sm">Legenda Etapas:</Typography>
                                <Stack flexDirection={"row"} mt="10px">
                                    <Stack flexDirection={"row"} alignItems="center">
                                        <div style={{ width: "15px", height: "15px", backgroundColor: "#FFB74D" }}></div>
                                        <Typography ml={"10px"} level="body-sm">Requisição</Typography>
                                    </Stack>
                                    <Stack flexDirection={"row"} alignItems="center" ml="20px">
                                        <div style={{ width: "15px", height: "15px", backgroundColor: "#64B5F6" }}></div>
                                        <Typography ml={"10px"} level="body-sm">Aprovação Suprimentos</Typography>
                                    </Stack>
                                    <Stack flexDirection={"row"} alignItems="center" ml="20px">
                                        <div style={{ width: "15px", height: "15px", backgroundColor: "#66BB6A" }}></div>
                                        <Typography ml={"10px"} level="body-sm">Aprovação Financeira</Typography>
                                    </Stack>
                                </Stack>

                            <Stack p={"0px 20px"} flexDirection={"row"} justifyContent="center" mt="10px" mb="40px" alignItems="center" flexWrap={"wrap"}>
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Solicitante", setEtapaAtualSelecionada("Solicitante"), setUltimaAtualizacao)} valor={processos.Contagem["Solicitante"]} etapa="Solicitante" titulo="Solicitante" cor="#FFB74D" selecionado={etapaAtualSelecionada == "Solicitante"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Aprov. Técnico", setEtapaAtualSelecionada("Aprov. Técnico"), setUltimaAtualizacao)} valor={processos.Contagem["Aprov. Técnico"]} etapa="Aprov. Técnico" titulo="Aprov. Téc." cor="#FFB74D" selecionado={etapaAtualSelecionada == "Aprov. Técnico"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Aprov. Gerencial", setEtapaAtualSelecionada("Aprov. Gerencial"), setUltimaAtualizacao)} valor={processos.Contagem["Aprov. Gerencial"]} etapa="Aprov. Gerencial" titulo="Aprov. Ger." cor="#FFB74D" selecionado={etapaAtualSelecionada == "Aprov. Gerencial"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Aprov. Institucional", setEtapaAtualSelecionada("Aprov. Institucional"), setUltimaAtualizacao)} valor={processos.Contagem["Aprov. Institucional"]} etapa="Aprov. Institucional" titulo="Aprov. Inst" cor="#FFB74D" selecionado={etapaAtualSelecionada == "Aprov. Institucional"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Análise Crítica Compras", setEtapaAtualSelecionada("Análise Crítica Compras"), setUltimaAtualizacao)} valor={processos.Contagem["Análise Crítica Compras"]} etapa="Análise Crítica Compras" titulo="Análise Crít" cor="#FFB74D" selecionado={etapaAtualSelecionada == "Análise Crítica Compras"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Baixas", setEtapaAtualSelecionada("Baixas"), setUltimaAtualizacao)} valor={processos.Contagem["Baixas"]} etapa="Baixas" titulo="Baixas" cor="#FFB74D" selecionado={etapaAtualSelecionada == "Baixas"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Compras", setEtapaAtualSelecionada("Compras"), setUltimaAtualizacao)} valor={processos.Contagem["Compras"]} etapa="Compras" titulo="Compras" cor="#64B5F6" selecionado={etapaAtualSelecionada == "Compras"} />
                                {/* <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "OC Gerada", setEtapaAtualSelecionada("OC Gerada"), setUltimaAtualizacao)} valor={processos.Contagem["OC Gerada"]} etapa="OC Gerada" titulo="O.C Gerada" cor="#006064" selecionado={etapaAtualSelecionada == "OC Gerada"} /> */}
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Em Cotação", setEtapaAtualSelecionada("Em Cotação"), setUltimaAtualizacao)} valor={processos.Contagem["Em Cotação"]} etapa="Em Cotação" titulo="Cotação" cor="#64B5F6" selecionado={etapaAtualSelecionada == "Em Cotação"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Aprov. mcp1", setEtapaAtualSelecionada("Aprov. mcp1"), setUltimaAtualizacao)} valor={processos.Contagem["Aprov. mcp1"]} etapa="Aprov. mcp1" titulo="Aprov. MCP1" cor="#64B5F6" selecionado={etapaAtualSelecionada == "Aprov. mcp1"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Aprov. mcp2", setEtapaAtualSelecionada("Aprov. mcp2"), setUltimaAtualizacao)} valor={processos.Contagem["Aprov. mcp2"]} etapa="Aprov. mcp2" titulo="Aprov. MCP2" cor="#64B5F6" selecionado={etapaAtualSelecionada == "Aprov. mcp2"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Aprov. mcpf", setEtapaAtualSelecionada("Aprov. mcpf"), setUltimaAtualizacao)} valor={processos.Contagem["Aprov. mcpf"]} etapa="Aprov. mcpf" titulo="Aprov. MCPF" cor="#64B5F6" selecionado={etapaAtualSelecionada == "Aprov. mcpf"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Aprov. gestor adm suprimentos", setEtapaAtualSelecionada("Aprov. gestor adm suprimentos"), setUltimaAtualizacao)} valor={processos.Contagem["Aprov. gestor adm suprimentos"]} etapa="Aprov. gestor adm suprimentos" titulo="Adm. Sup." cor="#66BB6A" selecionado={etapaAtualSelecionada == "Aprov. gestor adm suprimentos"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Aprov. gestor contrato/equipamentos", setEtapaAtualSelecionada("Aprov. gestor contrato/equipamentos"), setUltimaAtualizacao)} valor={processos.Contagem["Aprov. gestor contrato/equipamentos"]} etapa="Aprov. gestor contrato/equipamentos" titulo="Contr. / Equip" cor="#66BB6A" selecionado={etapaAtualSelecionada == "Aprov. gestor contrato/equipamentos"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Aprov. gestor corporativo equipamentos", setEtapaAtualSelecionada("Aprov. gestor corporativo equipamentos"), setUltimaAtualizacao)} valor={processos.Contagem["Aprov. gestor corporativo equipamentos"]} etapa="Aprov. gestor corporativo equipamentos" titulo="Corp. Equip" cor="#66BB6A" selecionado={etapaAtualSelecionada == "Aprov. gestor corporativo equipamentos"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Aprov. diretoria equipamentos", setEtapaAtualSelecionada("Aprov. diretoria equipamentos"), setUltimaAtualizacao)} valor={processos.Contagem["Aprov. diretoria equipamentos"]} etapa="Aprov. diretoria equipamentos" titulo="Dir. Equip." cor="#66BB6A" selecionado={etapaAtualSelecionada == "Aprov. diretoria equipamentos"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Aprov. diretoria operações", setEtapaAtualSelecionada("Aprov. diretoria operações"), setUltimaAtualizacao)} valor={processos.Contagem["Aprov. diretoria operações"]} etapa="Aprov. diretoria operações" titulo="Dir. Op." cor="#66BB6A" selecionado={etapaAtualSelecionada == "Aprov. diretoria operações"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Aprov. diretoria geral", setEtapaAtualSelecionada("Aprov. diretoria geral"), setUltimaAtualizacao)} valor={processos.Contagem["Aprov. diretoria geral"]} etapa="Aprov. diretoria geral" titulo="Dir. geral" cor="#66BB6A" selecionado={etapaAtualSelecionada == "Aprov. diretoria geral"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Diligenciamento", setEtapaAtualSelecionada("Diligenciamento"), setUltimaAtualizacao)} valor={processos.Contagem["Diligenciamento"]} etapa="Diligenciamento" titulo="Diligenc." cor="#66BB6A" selecionado={etapaAtualSelecionada == "Diligenciamento"} />
                                <CardDiligenciamento onClick={() => buscarProcessos(filtros, processos, setProcessos, "Disponivel", setEtapaAtualSelecionada("Disponivel"), setUltimaAtualizacao)} valor={processos.Contagem["Disponivel"]} etapa="Disponivel" titulo="Disponível" cor="#66BB6A" ultimo selecionado={etapaAtualSelecionada == "Disponivel"} />          
                            
                            </Stack>

                                            

                            {/* Accordions */}
                            <Stack key={processos} width="100%" margin='10px' alignSelf={"center"}>
                                {processos.listagem.map((processo, indice) => {
                                    // console.log({ processo });
                                    let tipoPedido = "";
                                    if(tiposPedido.filter(tipoPedido => tipoPedido.tipoPedido == processo.tipoPedido).length > 0){
                                        tipoPedido = tiposPedido.filter(tipoPedido => tipoPedido.tipoPedido == processo.tipoPedido)[0].descricao;
                                    }
                                    const props = {
                                        solicitacao: processo.idsolicitacao,
                                        equipamento: processo.descricaoObj,
                                        localEstoque: codigoLocalEstoque[processo.localEstoque],
                                        codEquip: processo.prefixoEquipamento,
                                        dataAbertura: processo.dataSolicitacao,
                                        dataNecessidade: processo.dataNecessidade,
                                        dataEncerramento: "",
                                        status: codigosStatus[processo.status],
                                        // itens: processo.itens_diligenciamento,
                                        itens: processo.itens_diligenciamento_fake,
                                        serie: processo.serie,
                                        tipoPedido: tipoPedido,
                                        // tiposPedido: tiposPedido
                                        // idmov: processo.numeroMov1
                                        // }
                                    }
                                    return (
                                        <AccordionDiligenciamento key={indice} {...props} />
                                    )
                                })}
                            </Stack>
                        </Stack>
                    </Stack>
            }
        </Stack>
    )
}