import axios from 'axios';
import { Buffer } from "buffer";

//Preparando Token
const username = 'fluig';
const password = 'Centrium505050@@';
const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
const tokenLaravel = JSON.parse(localStorage.getItem('usuario'))?.token;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000, // Tempo limite para as requisições em milissegundos
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${tokenLaravel}`
  },
});

const RM = axios.create({
  baseURL: process.env.REACT_APP_RM_URL, // Sua URL base da API externa
  timeout: 100000, // Tempo limite para as requisições em milissegundos
  headers: {
    'Authorization': `Basic ${token}`
  },
});

export { api, RM };
