import { useState } from "react";
import { Stack, Typography, Card, CardContent, Button, Input, FormLabel, Link, FormControl, Modal, ModalClose, Sheet, FormHelperText } from "@mui/joy";
//stack like a div
import Pelican from "../../../../assets/logoPelicano.png";

//Roteamento
import { useLocation } from "react-router-dom";

//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';

import { api } from "../../../../services/api";
import { toast } from 'react-toastify';

export default function Login(props) {
    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const [loginRecuperacao, setLoginRecuperacao] = useState('');
    const [erro, setErro] = useState({ usuarioInvalido: false, senhaInvalida: false, recuperacaoSenhaInvalida: false });

    const location = useLocation();

    //Verificando se o dispositivo é mobile
    const mobile = useMediaQuery('(max-width:1200px)');

    const [open, setOpen] = useState(false);

    const acessoNegado = location.pathname == "/acessoNegado";

    //Métodos
    const handleSubmit = (e) => {
        e.preventDefault();
        /* Validação dos dados do login informados pelo usuario */
        //event.preventDefault();
        const erros = { usuarioInvalido: false, senhaInvalida: false, recuperacaoSenhaInvalida: false };

        erros.usuarioInvalido = !usuario.trim();
        erros.senhaInvalida = !senha.trim();
        setErro(erros);

        if (erros.usuarioInvalido || erros.senhaInvalida) return;
        //Implementação da lógica do login

        api.post('/login/', { login: usuario, password: senha })
            .then(function (response) {
                if (response.data) {
                    const dadosUsuario = response.data.data;

                    //Persistindo os dados do usuário logado
                    localStorage.setItem('usuario', JSON.stringify(dadosUsuario));
                    // navigate("/centralTarefas");
                    window.location.href = "/modulos";
                }
                else {
                    toast.error("Não foi possível realizar o login, consulte o log");
                    console.error(response);
                }
            }).catch(function (response) {
                if (response.response) {
                    if (response.response.data.message == "Não Autorizado") {
                        toast.warning("Usuário ou senha incorretos");
                    }
                    else {
                        toast.warning(response.response.data.message);
                    }
                }
                else {
                    toast.error("Sem conexão com o servidor");
                }
            });
    }

    const validaRecuperacaoSenha = () => {

        api.post('/resetPassword/', { login: loginRecuperacao })
        .then(function (response) {
            if (response.data) {
                // navigate("/centralTarefas");
                // window.location.href = "/modulos";
                toast.success("Pedido recebido. Verifique sua caixa de e-mail para resetar sua senha.");
                setOpen(false);
            }

        }).catch(function (response) {
            if (response.response) {
                if (response.response.data.message == "Não Autorizado") {
                    toast.warning("Login incorreto.");
                }
                else {
                    toast.warning(response.response.data.message);
                }
            }
            else {
                toast.error("Sem conexão com o servidor");
            }
        });

    }

    return (
        <Stack backgroundColor="#F5F5F5" justifyContent={"center"} height="100vh">
            <Stack style={{ backgroundColor: "" }} alignItems="center" marginX={mobile ? "" : "auto"} p={mobile ? "30px" : "50px"}>

                <Stack m={"auto"} width={mobile ? "200px" : "300px"} height={mobile ? "150px" : "200px"}>
                    <img
                        width={"100%"}
                        height={"auto"}
                        alt=""
                        src={Pelican} />
                </Stack>

                <Typography level="title-lg" m={"auto"} mb={"20px"}>SUPRIMENTOS</Typography>
                {acessoNegado ? <Typography sx={{ color: "#ff0000", fontWeight: "bold" }} level="title-md" m={"auto"} mb={"20px"}>Acesso Negado, faça login para continuar</Typography> : null}

                {/* Conteúdo da Tela */}
                <Card variant="outlined" sx={[mobile ? { width: "90%", maxWidth: "500px", minWidth: "250px" } : { width: 480 }, { marginBottom: "100px" }]}>
                    <form onSubmit={handleSubmit}>
                        <CardContent orientation="vertical">
                            <FormControl error={erro.usuarioInvalido} sx={{ mb: "10px" }}>
                                <FormLabel style={{ fontWeight: "bold" }}>Usuário</FormLabel>
                                <Input placeholder="Usuário do RM" value={usuario} onChange={(e) => setUsuario(e.target.value)} />

                                {erro.usuarioInvalido && (
                                    <FormHelperText error>
                                        Informe o nome de usuário.
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <FormControl error={erro.senhaInvalida} sx={{ mb: "10px" }}>
                                <FormLabel style={{ fontWeight: "bold" }}>Senha</FormLabel>
                                <Input placeholder="Senha" type="password" value={senha} onChange={(e) => setSenha(e.target.value)} />

                                {erro.senhaInvalida && (
                                    <FormHelperText error>
                                        Informe a senha.
                                    </FormHelperText>
                                )}
                            </FormControl>

                            <Button
                                type="submit"
                                variant="solid"
                                size="md"
                                color="primary"
                                aria-label="Enviar"
                                sx={{ m: 'auto', mb: '20px', alignSelf: 'center', fontWeight: 600 }} >
                                ENTRAR
                            </Button>
                            <Link m={"auto"} onClick={() => setOpen(true)}>Esqueci minha senha</Link>
                        </CardContent>
                    </form>
                </Card>
            </Stack>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={() => setOpen(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.surface',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Recuperação de Senha
                    </Typography>
                    <FormControl sx={{ mb: "10px" }}>
                        <FormLabel style={{ fontWeight: "bold" }}>Usuário</FormLabel>
                        <Input placeholder="nome.sobrenome" value={loginRecuperacao} onChange={(e) => setLoginRecuperacao(e.target.value)} />

                        {/* {erro.recuperacaoSenhaInvalida && (
                            <FormHelperText error>
                                Informe o nome de usuário para recuperar sua senha.
                            </FormHelperText>
                        )} */}

                    </FormControl>
                    <Button
                        onClick={validaRecuperacaoSenha}
                        variant="solid"
                        size="md"
                        color="primary"
                        aria-label="Enviar Código"
                        sx={{ m: 'auto', mt: "10px", mb: '20px', alignSelf: 'center', fontWeight: 600 }} s>
                        Enviar Código
                    </Button>
                </Sheet>
            </Modal>
        </Stack>
    );
}
