import { React, useState, useEffect, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { formatarDataHoraBrasil, formatarDataBrasil } from '../../../utils/datas';
import {
    Button,
    Stack,
    Typography,
    Table,
    Modal,
    ModalDialog,
    ModalClose,
    Sheet,
    FormControl,
    FormLabel,
    Input,
    Divider,
    Box,
    Select,
    Option,
    ToggleButtonGroup,
    Switch,
    Textarea,
    FormHelperText,
    Link,
    Checkbox
} from "@mui/joy";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { FaRegTrashAlt, FaEye, FaFolderOpen } from "react-icons/fa";
import { Link as ReactLink } from "react-router-dom";

import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";

import useMediaQuery from '@mui/material/useMediaQuery';
import realizaConsulta from "../../../services/rm";
let token = "";
let usuarioID = 0;
let usuarioLogin = "";
let grupos = "";


export default function FormularioProcesso(props) {

    const mobile = useMediaQuery('(max-width:1200px)'); //Telas menores que 1200px de largura serão considerados mobile
    const [coligada, setColigada] = useState();
    const [listaObras, setlistaObras] = useState([]);
    const [obraDestino, setObraDestino] = useState();
    const [valueTogglePagina, setValueTogglePagina] = useState("formulario");
    
    const [modalEscolhaItem, setModalEscolhaItem] = useState(false);
    const [itemBuscado, setItemBuscado] = useState();
    const [modalPatrimonio, setModalPatrimonio] = useState(false);
    
    const [patrimonio, setPatrimonio] = useState();
    const [localizacao, setLocalizacao] = useState();
    const [marca, setMarca] = useState();
    const [modelo, setModelo] = useState();
    const [placa, setPlaca] = useState();
    const [serie, setSerie] = useState();
    const [patrimoniosProcesso, setPatrimoniosProcesso] = useState([
        {
            descricao: "Monitor LCD 23'",
            localizacao: "01.001",
            marca: "LG",
            modelo: "Galaxy",
            placa: "N/A",
            serie: "0001101"
        },
        {
            descricao: "Notebook Dell G15",
            localizacao: "03.300",
            marca: "DELL",
            modelo: "G15 2022",
            placa: "N/A",
            serie: "0001102"
        },
        {
            descricao: "Ar Condicionado TCL 3000",
            localizacao: "03.300",
            marca: "TCL",
            modelo: "3000",
            placa: "N/A",
            serie: "0001103"
        }]
    );

    useEffect(() => {
        //Obtendo o token
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            token = JSON.parse(localStorage.getItem('usuario')).token;
            usuarioID = JSON.parse(localStorage.getItem('usuario')).user.id;
            usuarioLogin = JSON.parse(localStorage.getItem('usuario')).user.login;
            grupos = JSON.parse(localStorage.getItem("usuario")).grupo;
        }

        if (listaObras.length == 0){
            carregaLocalEstoque();
        }
        // pesquisarPatrimonio();
    }, []);


    const carregaLocalEstoque = () => {
        //TODO: otimizar consulta para ficar rápido
        axios.get(`https://pelicanoconstrucoes127067.rm.cloudtotvs.com.br:8051/api/framework/v1/consultaSQLServer/RealizaConsulta/660607/1/G/`, 
            {
                headers: {
                    'Authorization': "Basic " + btoa("fluig" + ":" + "Centrium505050@@")
                }
            }
          ).then(function ({ data }) {
            if(data.length == 0){
				alert("Falha ao consultar locais de estoque no RM, tente novamente em alguns instantes.");
				console.log("Não foram encontrados locais de estoque, favor revisar consulta #660607");
			}
            for (let i = 0; i < data.length; i++) {
                listaObras.push({ codigo: data[i].CODLOCAL, nome: data[i].NOME});
            }
            console.log("show-me-local-estoque");
            console.log(listaObras);
          }).catch(function (erro) {
            if (erro.responseJSON.Message == "Não foi possível consumir a licença no Slot 527: Erro: -1505 Message: Excedeu numero de licenças") {
				alert("Estamos sem licenças, tente novamente mais tarde!");
				console.log("Mensagem de erro: Sem licenças no RM");
			}
          })
    }


    const pesquisarPatrimonio = (termoPesquisado=null) => {
        realizaConsulta('660606', {
            params: {
                'CHAPA': '022557', //TODO: matricula do usuario logado
                'CODCOLIGADA': coligada,
                'LOCAL_S': '' //TODO: local
            }
        }).then(function ({ data }) {
            if(data.length == 0){
				alert("Falha ao consultar patrimônios no RM, tente novamente em alguns instantes.");
				console.log("Não foram encontrados patrimônios, favor revisar consulta.");
			}
            for (let i = 0; i < data.length; i++) {
                console.log(data[i]);
            }
          }).catch(function (erro) {
            console.log("Mensagem de erro: ", erro);
            // if (erro.responseJSON.Message == "Não foi possível consumir a licença no Slot 527: Erro: -1505 Message: Excedeu numero de licenças") {
			// 	alert("Estamos sem licenças, tente novamente mais tarde!");
			// 	console.log("Mensagem de erro: Sem licenças no RM");
			// }
          })

    }


    return (
        <Stack backgroundColor="#F5F5F5" height="100%" minHeight={"100vh"}>
            <Header mobile={mobile} notification />
            {/* <Sidebar mobile={mobile} /> */}

            <Stack
                ml={mobile ? "0px" : "250px"}
                mt="50px"
                flex={1}
                height={"calc(100% - 55px)"}
                p={mobile ? "20px" : "50px 50px 50px 80px"}
                overflow="hidden" >

                <Typography level={"h4"}>COMUNICADO MOVIMENTAÇÃO DE IMOBILIZADO</Typography>

                <Stack flexDirection={"row"} mt="10px" ml="auto">
                    {/* <Link fontSize="16px" onClick={() => navigate("/fluxoAprovacao", { state: { processo: numeroProcesso, origemChamada: "/aprovacaoCompras/", cotacao: cotacao.numeroMov6 } })} mr="20px">
                    Listagem de processos
                    </Link> */}
                    <ToggleButtonGroup
                        value={valueTogglePagina}
                        onChange={(event, newValue) => newValue ? setValueTogglePagina(newValue) : null} >
                        <Button value="formulario" sx={valueTogglePagina == "formulario" ? { fontWeight: "bold" } : { fontWeight: "normal" }}>Formulário</Button>
                        <Button value="anexo" sx={valueTogglePagina == "anexo" ? { fontWeight: "bold" } : { fontWeight: "normal" }}>Anexos</Button>
                    </ToggleButtonGroup>
                </Stack>

                {valueTogglePagina == "formulario" && (
                    <>
                        <Typography level="title-lg" mt="40px">
                            Dados do Processo
                        </Typography>

                        <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"}  mr="10px">
                                <FormLabel>Coligada</FormLabel>
                                <Select fontSize={12} value={coligada} onChange={(event, newValue) => setColigada(newValue)} placeholder="Coligada">
                                    <Option value={1}>1 - PELICANO CONSTRUÇÕES S/A</Option>
                                    <Option value={2}>2 - RAF EMPREENDIMENTOS</Option>
                                    <Option value={3}>3 - UNIPEL ENGENHARIA</Option>
                                    <Option value={4}>4 - CONSÓRCIO TAP</Option>
                                    <Option value={7}>7 - TENCOL ENGENHARIA LTDA</Option>
                                    <Option value={9}>9 - EXOTIC IMPORTAÇÃO. EXPORTAÇÃO DE MÁRMORES E GRANITOS EIRELLI</Option>
                                    <Option value={10}>10 - CONSÓRCIO PELICANO/MONTEIRO DE CASTRO - PMC</Option>
                                </Select>
                            </Stack>

                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"} >
                                <FormLabel>Obra Destino</FormLabel>
                                {/* <Input type="text" value={obraDestino} onClick={(e)=>carregaLocalEstoque()} onChange={(e) => setObraDestino(e.target.value)} /> */}

                                <Select value={obraDestino} onChange={(event, newValue) => setObraDestino(newValue)} >

                                     {listaObras.map(function (obra, indice) {
                                        return (
                                            <Option key={indice} value={obra.codigo}>{obra.codigo} - {obra.nome}</Option>
                                        );
                                    })}
                                </Select>
                            </Stack>

                        </Stack>

                        <Typography level="title-lg" mt="40px">
                            Patrimônio
                        </Typography>

                        <Stack flexDirection={"row"} justifyContent={mobile ? "space-between" : "flex-end"} width={mobile ? "100%" : "auto"}>
                            <Button color="primary" sx={{ marginRight: "20px", width: "170px" }} onClick={() => setModalEscolhaItem(true)}>ADICIONAR ITEM</Button>
                        </Stack>

                        <Stack mt="30px">
                            {mobile ?
                                <Stack>
                                    {/* TODO: fazer mobile */}
                                </Stack>
                                :
                                <Table hoverRow stripe="even" noWrap aria-label="basic table">
                                <thead>
                                    <tr>
                                    <th style={{ width: "40px", height: "25px" }}>Item</th>
                                    <th style={{ width: "300px", height: "25px" }}>Descrição</th>
                                    <th style={{ width: "80px", height: "5px" }}>Localização</th>
                                    <th style={{ width: "80px", height: "25px" }}>Marca</th>
                                    <th style={{ width: "80px", height: "25px" }}>Modelo</th>
                                    <th style={{ width: "100px", height: "25px" }}>Placa</th>
                                    <th style={{ width: "100px", height: "25px" }}>Série</th>
                                    <th style={{ width: "70px", height: "25px", textAlign: "center" }}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {patrimoniosProcesso.map((item, index) =>
                                    
                                        <tr>
                                        <td style={{ padding: "0 10px" }}>{parseInt(index + 1)}</td>
                                        <td style={{ padding: "0 10px" }}>{item.descricao}</td>
                                        <td style={{ padding: "0 10px" }}>{item.localizacao}</td>
                                        <td style={{ padding: "0 10px" }}>{item.marca}</td>
                                        <td style={{ padding: "0 10px" }}>{item.modelo}</td>
                                        <td style={{ padding: "0 10px" }}>{item.placa}</td>
                                        <td style={{ padding: "0 10px" }}>{item.serie}</td>
                                        
                                        <td style={{ padding: "0 10px", display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
                                            {/* <div className="hover">
                                            {((etapaAtual == "Solicitante") && (!osVinculada)) ?
                                                <FaRegEdit onClick={() => [setOpenModal(true), setIndiceItemConsultado(index), setItemTemporario(cotacao.itens[index])]} color="#2974ac" size={22} />
                                               
                                            </div> */}
                                            {/* {((etapaAtual == "Solicitante") && (!osVinculada)) ?
                                            <div onClick={() => [setIndiceItemConsultado(index), setDialogExclusao(true)]} className="hover" style={{ marginLeft: "10px" }}>
                                                <FaRegTrashAlt color="#990000" size={22} />
                                            </div>
                                            : null} */}
                                        </td>
                                        </tr>
                                    )}
                                </tbody>
                                </Table>
                            }
                        </Stack>

                        <Stack mt="30px">
                            <Typography level="title-lg">OBSERVAÇÃO:</Typography>
                            <Textarea minRows={3} /> 
                        </Stack>

                        <Stack flexDirection={"column"} mt="20px">
                            <Typography level="title-lg">SEGUE COM O EQUIPAMENTO:</Typography>
                            <Stack flexDirection={"row"}>
                                <Checkbox label="Liter Téc" sx={{ marginRight: "20px"}}/>
                                <Checkbox label="Triângulo" sx={{ marginRight: "20px"}}/>
                                <Checkbox label="Dut" sx={{ marginRight: "20px"}}/>
                                <Checkbox label="Extintor" sx={{ marginRight: "20px"}}/>
                                <Checkbox label="CH. de Roda" sx={{ marginRight: "20px"}}/>
                                <Checkbox label="CH. Ignição" sx={{ marginRight: "20px"}}/>
                                <Checkbox label="Macaco" sx={{ marginRight: "20px"}}/>
                                <Checkbox label="Pneu Step" sx={{ marginRight: "20px"}}/>
                            </Stack>
                        </Stack>

                        {/* Modal Escolha de Item */}
                        <Modal
                            open={modalEscolhaItem}
                            onClose={() => setModalEscolhaItem(false)}>
                            <ModalDialog
                                variant="outlined"
                                role="alertdialog"
                                aria-labelledby="alert-dialog-modal-title"
                                aria-describedby="alert-dialog-modal-description">
                                <Typography
                                    id="alert-dialog-modal-title"
                                    level="h2">
                                    Escolha de Item
                                </Typography>
                                <Divider />
                                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
                                    <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
                                        <Stack flex={1} minWidth={mobile ? "100%" : "250px"}  mr="10px">
                                            <FormLabel>Procurar</FormLabel>
                                            <Input type="text" value={itemBuscado} onChange={(e) => setItemBuscado(e.target.value)} placeholder="Nome ou grupo do patrimônio"/>
                                        
                                            <Button value="Buscar" sx={{ fontWeight: "normal" }}>Buscar</Button>
                                        </Stack>
                                    </Stack>
                                </Box>
                            </ModalDialog>
                        </Modal>


                        {/* Modal Patrimônio */}
                        <Modal
                            open={modalPatrimonio}
                            onClose={() => setModalPatrimonio(false)}>
                            <ModalDialog
                                variant="outlined"
                                role="alertdialog"
                                aria-labelledby="alert-dialog-modal-title"
                                aria-describedby="alert-dialog-modal-description"
                            >
                                <Typography
                                    id="alert-dialog-modal-title"
                                    level="h2">
                                    Formulário de Patrimônio
                                </Typography>
                                <Divider />
                                <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2 }}>
                                    <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
                                        <Stack flex={1} minWidth={mobile ? "100%" : "110px"}  mr="10px">
                                            <FormLabel>Descrição Patrimônio</FormLabel>
                                            <Input type="text" value={patrimonio} onChange={(e) => setPatrimonio(e.target.value)} />
                                        </Stack>

                                        <Stack flex={1} minWidth={mobile ? "100%" : "110px"} >
                                            <FormLabel>Localização</FormLabel>
                                            <Input type="text" value={localizacao} onChange={(e) => setLocalizacao(e.target.value)} />
                                        </Stack>
                                    </Stack>
                                    <Stack flexDirection={mobile ? "column" : "row"} mt="30px">
                                        <Stack flex={1} minWidth={mobile ? "100%" : "110px"} mr="10px">
                                            <FormLabel>Marca</FormLabel>
                                            <Input type="text" value={marca} onChange={(e) => setMarca(e.target.value)} />
                                        </Stack>

                                        <Stack flex={1} minWidth={mobile ? "100%" : "110px"} mr="10px">
                                            <FormLabel>Modelo</FormLabel>
                                            <Input type="text" value={modelo} onChange={(e) => setModelo(e.target.value)} />
                                        </Stack>

                                        <Stack flex={1} minWidth={mobile ? "100%" : "110px"} mr="10px">
                                            <FormLabel>Placa</FormLabel>
                                            <Input type="text" value={placa} onChange={(e) => setPlaca(e.target.value)} />
                                        </Stack>

                                        <Stack flex={1} minWidth={mobile ? "100%" : "110px"} mr="10px">
                                            <FormLabel>Série</FormLabel>
                                            <Input type="text" value={serie} onChange={(e) => setSerie(e.target.value)} />
                                        </Stack>
                                    </Stack>
                                </Box>
                            </ModalDialog>
                        </Modal>


                    </>
                )}



            </Stack>
        </Stack>
    );
}

