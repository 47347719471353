import { api } from '../../../../services/api';
import { useNavigate } from 'react-router-dom';
import { React, useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { formatarDataHoraBrasil } from '../../../../utils/datas';
import {
    Button,
    Stack,
    Typography,
    Table,
    Link,
    Modal,
    Sheet,
    ModalClose,
    FormControl,
    FormHelperText,
    Select,
    Option,
    FormLabel,
    Input,

} from "@mui/joy";

import { FaRegTrashAlt } from 'react-icons/fa';

import { Pagination } from '@mui/material/';

import { tiposPedidos } from '../../../../utils/choices';
import MultipleSelect from 'react-select';

import useMediaQuery from '@mui/material/useMediaQuery';
let token = "";
let usuarioID = 0;
let usuarioLogin = "";

export default function TemposDiligenciamento(props) {
    
    const navigate = useNavigate();
    const mobile = useMediaQuery('(max-width:1200px)');
    const [listaTempos, setListaTempos] = useState([]);
    const [tipoPedido, setTipoPedido] = useState();
    const [qtdDias, setQtdDias] = useState();
    const [IDSelecionado, setIDSelecionado] = useState(null);
    const [adm, setAdm] = useState(false);

    useEffect(() => {
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            token = JSON.parse(localStorage.getItem('usuario')).token;
            usuarioID = JSON.parse(localStorage.getItem('usuario')).user.id;
            usuarioLogin = JSON.parse(localStorage.getItem('usuario')).user.login;
        }

        //Verificando se o usuário é administrador
        if (JSON.parse(localStorage.getItem("usuario")).grupo.length > 0) {
            const adm = JSON.parse(localStorage.getItem("usuario")).grupo.filter(grupo => grupo.nome == "ADM");
            if (adm.length > 0) {
                setAdm(true);
            }
        }

        listaRegistroTempos();

    }, []);

    const exibirModalCadastro = () => {
        limpaFormCadastro();
        setModalCadastro(true);
    }

    const listaRegistroTempos = (page=1) => {
        /*
         Carrega a lista de registros de tempos de diligenciamento 
         */
        api.get("/temposDiligenciamento/" )
            .then((response) => {
                const listaTempos = response.data.data.map(item => {
                    const tipos = item.tipo_pedido.split(',').map(tipo => tipo.trim());
                    const labels = tipos.map(tipo => {
                        const tipoEncontrado = tiposPedidos.find(tp => tp.value === tipo);
                        return tipoEncontrado ? tipoEncontrado.label : tipo;
                    });
                    item.tipo_pedido = labels.join(', ');
                    return item;
                });
                setListaTempos(listaTempos);
            })
            .catch((err) => {
                console.error("Ops! ocorreu um erro lista-registro-diligenciamento: " + err);
            });
    }


    const carregaRegistroSelecionado = (id) => {
        limpaFormCadastro();

        api.get("/temposDiligenciamento/" + id + "/")
        .then((response) => {
            let objeto = response.data.data;

            setIDSelecionado(objeto.id);
            setQtdDias(objeto.qtd_dias);
            //tratar tipo pedido para preencher o MultipleSelect corretamente:
            let arrayTipoPedido = [objeto.tipo_pedido];
            if (objeto.tipo_pedido.includes(',')) {
                arrayTipoPedido = objeto.tipo_pedido.split(',');
            }
            //Transformando o array de códigos em um array de objetos para exibir no select
            let arrayDeObjetosTipoPedido = [];
            arrayTipoPedido.forEach(tipo => {
                arrayDeObjetosTipoPedido.push(tiposPedidos.find(tipos => tipos.value == tipo));
            });
            setTipoPedido(arrayDeObjetosTipoPedido);

            setModalCadastro(true);
        })
        .catch((err) => {
            console.error("Ops! ocorreu um erro lista-registro-diligenciamento: " + err);
        });
    }


    const [modalCadastro, setModalCadastro] = useState(false);
    const [erro, setErro] = useState({
        tipoPedido: "",
        qtdDias: "",
        tipoPedidoInvalido: false,
        qtdDiasInvalido: false
    });

    const salvaRegistroTempos = () => {

        // Validação dos campos antes de salvar no banco:
        const erros = [];
        setErro({ tipoPedido: "", qtdDias: "", tipoPedidoInvalido: false, qtdDiasInvalido: false });

        if (!tipoPedido) {
            erros.tipoPedido = 'Informe o tipo de pedido.';
            erros.tipoPedidoInvalido = true;
        }
        if (!qtdDias) {
            erros.qtdDias = 'Informe a quantidade de dias.';
            erros.qtdDiasInvalido = true;
        }

        if (Object.keys(erros).length > 0) {
            setErro(erros);
        } else {
            //tratar o campo tipo pedido para o backend
            let tiposPedidosString = "";
            tipoPedido.forEach((tipo, indice) => {
                tiposPedidosString = indice == 0 ? tipo.value : tiposPedidosString + "," + (tipo.value);
            });

            if (IDSelecionado == null) {
                let novoObjeto = {
                    tipo_pedido: tiposPedidosString,
                    qtd_dias: qtdDias
                }

                console.log("Cade meu objeto");
                console.log(novoObjeto);

                api.post("/temposDiligenciamento/", novoObjeto)
                    .then((response) => {
                        toast.success("Registro adicionado com sucesso!");
                        setModalCadastro(false);
                        limpaFormCadastro();
                        listaRegistroTempos();
                    })
                    .catch((err) => {
                        console.error("Ops! ocorreu um erro lista-registro-tempos: " + err);
                        toast.error("Não foi possível adicionar este registro no momento. Por favor, tente novamente em instantes.");
                    });
            }  else {

                let objetoEditado = {
                    tipo_pedido: tiposPedidosString,
                    qtd_dias: qtdDias
                }
                console.log("Editando.....");
                api.put("/temposDiligenciamento/" + IDSelecionado + "/", objetoEditado)
                    .then((response) => {
                        toast.success("Registro alterado com sucesso!");
                        setModalCadastro(false);
                        limpaFormCadastro();
                        listaRegistroTempos();
                    })
                    .catch((err) => {
                        console.error("Ops! ocorreu um erro lista-registro-tempos: " + err);
                        toast.error("Não foi possível adicionar este registro no momento. Por favor, tente novamente em instantes.");
                    });
            }

        }
    }

    const limpaFormCadastro = () => {
        setTipoPedido("");
        setQtdDias("");
        setIDSelecionado(null);
        setErro({ tipoPedido: "", qtdDias: "", tipoPedidoInvalido: false, qtdDiasInvalido: false });
    }

    const removeRegistro = (id) => {
        const confirmar_exclusao = window.confirm("Deseja excluir o registro de ID: " + id + "?");

        if (confirmar_exclusao) {
            api.delete("/temposDiligenciamento/" + id + "/")
                .then((response) => {
                    toast.info("Registro removido com sucesso.");
                    listaRegistroTempos();
                })
                .catch(error => {
                    toast.error("Não foi possível remover este registro.");
                })
        }
    }

    return(
        <>

        { adm? 
            <Stack backgroundColor="#F5F5F5" height="100vh">

                <Stack flexDirection={"row"} overflow={"auto"} justifyContent="space-between" alignItems="center" backgroundColor="#222" padding="10px 20px">
                    <Typography sx={{ color: "#eee", fontSize: 13 }}>FOLLOW UP</Typography>
                    <Stack flexDirection={"row"}>

                        <Stack flexDirection={"row"} mr={mobile? "10px": "40px"}>
                            <Link onClick={()=> navigate("/followUp")} sx={{ color: "#fff", marginLeft: "10px", fontSize: 13 }}>Voltar para FollowUp</Link>
                        </Stack>

                        <Stack flexDirection={"row"} mr={mobile? "10px": "40px"}>
                            <Link onClick={() => navigate("/modulos")} sx={{ color: "#888", marginLeft: "10px", fontSize: 13 }}>Ir para módulos</Link>
                        </Stack>
                        <Stack flexDirection={"row"}>
                            <Typography sx={{ color: "#eee" }} level='body-sm'>{usuarioLogin}</Typography>
                            <Link sx={{ color: "#888", marginLeft: "10px" }} onClick={() => [navigate("/"), localStorage.setItem("usuario", null)]}>sair</Link>
                        </Stack>
                    </Stack>
                </Stack>

                <Stack ml={mobile ? "0px" : "30px"} mt="50px" overflow={"auto"} height={mobile ? "100%" : "calc(100vh - 200px)"} p={mobile ? "20px" : "50px"}>
                    {/* Conteúdo da Tela */}
                    <Typography level="h2">Cadastro Tempos de Diligenciamento</Typography>
                    <Stack display={"flex"} justifyContent={"space-between"} flexDirection="row" mt={"10px"}>
                        <Stack flexDirection="row" alignItems="center">
                            {/* <Button onClick={() => navigate("/followup")} color="neutral">
                                <IoChevronBack size={20} />
                                Voltar
                            </Button> */}
                            {/* <Typography ml="10px" level="body-md">Processo: #{numeroProcesso}</Typography> */}
                        </Stack>
                        <Stack display={"flex"} ml={mobile ? "0" : "30px"} flexDirection={"row"} alignItems="end" justifyContent={"flex-end"}>
                            <Button onClick={() => exibirModalCadastro()}>Adicionar Novo Registro</Button>
                        </Stack>
                    </Stack>

                    {/* Seção de Modal de cadastro de registro tempos diligenciamento */}
                    <Modal
                        aria-labelledby="modal-title"
                        aria-describedby="modal-desc"
                        open={modalCadastro}
                        disableScrollLock={true}
                        onClose={() => setModalCadastro(false)}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        <Sheet
                            variant="outlined"
                            sx={{
                                maxWidth: "80%",
                                borderRadius: 'md',
                                p: 3,
                                boxShadow: 'lg',
                            }}
                        >
                            <ModalClose
                                variant="outlined"
                                sx={{
                                    top: 'calc(-1/4 * var(--IconButton-size))',
                                    right: 'calc(-1/4 * var(--IconButton-size))',
                                    boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                                    borderRadius: '50%',
                                    bgcolor: 'background.surface',
                                }}
                            />
                            <Typography
                                component="h2"
                                id="modal-title"
                                level="h4"
                                textColor="inherit"
                                fontWeight=""
                                mb={1}
                                sx={{ color: "#1e88e5" }} >
                                Tempo de Diligenciamento
                            </Typography>
                            <Stack maxHeight={"100%"}>
                                <Stack>
                                    <Stack flexDirection={"column"} mt="30px">

                                        <Stack flex={1} maxWidth={mobile ? "" : "280px"} minWidth={mobile ? "100%" : "130px"} ml={mobile ? "0" : "10px"} mt={mobile ? "20px" : "5px"}>
                                            <FormControl error={erro.tipoPedidoInvalido} sx={{ mb: "10px" }}>
                                                <Typography fontSize="14px">Tipo de Pedido</Typography>
                                                <MultipleSelect
                                                    value={tipoPedido}
                                                    onChange={(e) => setTipoPedido(prevState => (e))}
                                                    placeholder=""
                                                    isMulti
                                                    options={tiposPedidos}
                                                    noOptionsMessage={() => "Sem opções"}
                                                />

                                                {erro.tipoPedidoInvalido && (
                                                    <FormHelperText error={erro.tipoPedidoInvalido}>
                                                        {erro.tipoPedido}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Stack>

                                        <Stack flex={1} maxWidth={mobile ? "" : "240px"} minWidth={mobile ? "100%" : "150px"} ml={mobile ? "0" : "10px"} mt={mobile ? "20px" : ""}>
                                            <FormControl error={erro.qtdDiasInvalido} sx={{ mb: "10px" }}>
                                                <FormLabel>Prazo máximo para diligenciar (dias)</FormLabel>
                                                <Input type="number" value={qtdDias} onChange={(e) => setQtdDias(e.target.value)} />

                                                {erro.qtdDiasInvalido && (
                                                    <FormHelperText error={erro.qtdDiasInvalido}>
                                                        {erro.qtdDias}
                                                    </FormHelperText>
                                                )}
                                            </FormControl>
                                        </Stack>
                                    </Stack>

                                    <Stack ml={mobile ? "0" : "30px"} flexDirection={"row"} mt={mobile ? "20px" : "20px"} alignItems="center" justifyContent={"flex-end"}>
                                        <Button onClick={() => salvaRegistroTempos()}>Salvar</Button>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Sheet>
                    </Modal>


                    {mobile ?
                        <Stack mt="30px">
                            <Stack>

                                {listaTempos.map(function (item, indice) {
                                    if (!Array.isArray(item)) {
                                        return (
                                            <div key={indice} className="hover" onClick={() => console.log("clicou follow up")}>
                                                <Stack p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                                                    <Typography onClick={() => carregaRegistroSelecionado(item.id)} level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">ID:</Typography> {item.id}</Typography>
                                                    <Typography onClick={() => carregaRegistroSelecionado(item.id)} level="body-sm"><Typography level="title-md">Data criação:</Typography>{formatarDataHoraBrasil(item.created_at)}</Typography>
                                                    <Typography onClick={() => carregaRegistroSelecionado(item.id)} level="body-sm"><Typography level="title-md">Tipo Pedido:</Typography> {item.tipo_pedido}</Typography>
                                                    <Typography onClick={() => carregaRegistroSelecionado(item.id)} level="body-sm"><Typography level="title-md">Prazo para diligenciar (dias):</Typography>{item.qtd_dias}</Typography>
                                                    <Typography level="body-sm"><Typography level="title-md">Ações:</Typography><Link onClick={() => removeRegistro(item.id)}><FaRegTrashAlt size={20} color="#990000"></FaRegTrashAlt></Link></Typography>
                                                </Stack>
                                            </div>
                                        )
                                    }
                                })}

                            </Stack>
                        </Stack>
                        :
                        <Stack mt="50px">
                            {/* Visualizacao desktop */}
                            <Table hoverRow stripe="even" noWrap >
                                <thead>
                                    <tr>
                                        <th style={{ height: "25px", width: "20px" }}>ID</th>
                                        <th style={{ height: "25px", width: "300px" }}>Data Criação</th>
                                        <th style={{ height: "25px", width: "180px" }}>Tipo Pedido</th>
                                        <th style={{ height: "25px", width: "300px" }}>Prazo para diligenciar (dias)</th>
                                        <th style={{ heigth: "25px", width: mobile? "60px": "30px" }}>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listaTempos.map(function (item, indice) {
                                        // ignorar os arrays de itens recebidos que nao pertencem a esse component
                                        if (!Array.isArray(item)) {
                                            return (
                                                <tr key={indice} className="hover">
                                                    <td style={{ padding: "0 10px" }} onClick={() => carregaRegistroSelecionado(item.id)}>
                                                        {item.id}</td>
                                                    <td style={{ padding: "0 10px" }} onClick={() => carregaRegistroSelecionado(item.id)}>
                                                        {formatarDataHoraBrasil(item.created_at)}</td>
                                                    <td style={{ padding: "0 10px" }} onClick={() => carregaRegistroSelecionado(item.id)}>
                                                        {item.tipo_pedido}</td>
                                                    <td style={{ padding: "0 10px" }} onClick={() => carregaRegistroSelecionado(item.id)}>
                                                        {item.qtd_dias}</td>
                                                    <td style={{ padding: "0 10px"}}>
                                                        <Link onClick={() => removeRegistro(item.id)}><FaRegTrashAlt size={20} color="#990000"></FaRegTrashAlt></Link>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })}
                                </tbody>
                            </Table>

                        </Stack>
                    }

                    {/* <Stack mt={3} alignItems={mobile ? "center" : "flex-end"}>
                            <Pagination count={lastPageUsuario} page={pageUsuario} onChange={handleChangePage} />
                </Stack> */}
                </Stack>

            </Stack>
            :
            <Typography level="h3">Acesso Negado. Você não tem permissão para usar esta tela.</Typography>
        }
        </>
    )
}