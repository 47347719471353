import { api } from '../../../../services/api';
import UsuariosGrupo from '../../../../components/UsuariosGrupo';
import { React, useState, useEffect } from "react";

import {
    Button,
    Stack,
    Typography,
    Modal,
    ModalDialog,
    ModalClose,
    Sheet,
    Table,
    Autocomplete,
    Input
} from "@mui/joy";

//Icones
import { FaUser, FaPlus, FaTrashAlt } from "react-icons/fa";

//Utils
import { formatarMoeda } from '../../../../utils/moedas';

import axios from "axios";

import { toast } from 'react-toastify';

let token = "";
let usuarioID = 0;
let usuarioLogin = "";

export default function UsuarioGrupo({ grupoConsultado, mobile, abrirModal }) {

    const [usuarioGrupoObj, setUsuarioGrupoObj] = useState({ id: '', grupo_id: '', usuario_id: '' });
    const [grupoConsultadoObj, setGrupoConsultadoObj] = useState(grupoConsultado);
    const [modalUsuariosGrupo, setModalUsuariosGrupo] = useState(abrirModal);
    const [usuariosDisponiveis, setUsuariosDisponiveis] = useState([]);
    const [usuarioSelected, setUsuarioSelected] = useState(null);
    const [gruposUsuariosRelac, setGruposUsuariosRelac] = useState([]);
    const [modalDadosUsuario, setModalDadosUsuario] = useState(false); //mostrar o form de dados do usuario relacionado a grupo
    const [novoUsuarioModal, setNovoUsuarioModal] = useState(false); // indica se o form será de adicionar novo usuario, caso nao, será edicao;

    const [usuarioGrupoSelecionado, setUsuarioGrupoSelecionado] = useState(null);

    useEffect(() => {
        //Obtendo o token
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            token = JSON.parse(localStorage.getItem('usuario')).token;
            usuarioID = JSON.parse(localStorage.getItem('usuario')).user.id;
            usuarioLogin = JSON.parse(localStorage.getItem('usuario')).user.login;
        }

        recarregaGrupoConsultado(grupoConsultadoObj.id);
        listaGrupoUsuariosRelac();
    }, []);

    const listaGrupoUsuariosRelac = () => {
        toast.loading("Obtendo usuários...", { toastId: 1 });
        api.get("/grupoTeste/" + grupoConsultadoObj.id,
            { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token } })
            .then((response) => {
                setGruposUsuariosRelac(response.data.data);
                toast.dismiss();
            })
            .catch((err) => {
                toast.error("Ocorreu um erro ao buscar usuários do grupo");
                console.log(err);
                toast.dismiss();
                toast.error(JSON.stringify(err.response.data));
            });
    }

    // const listaUsuariosDisponiveis = () => {
    //     api.get("/usuarios/", { headers: { "Content-Type": "application/json", "Authorization": "Bearer " + token } })
    //         .then((response) => {
    //             //armazenar apenas nomes unicos
    //             const _usuarios = response.data.data;
    //             const nomesUnicos = new Set();
    //             const usuariosUnicos = [];

    //             _usuarios.forEach((usuario) => {
    //                 if (!nomesUnicos.has(usuario.nome)) {
    //                     nomesUnicos.add(usuario.nome);
    //                     usuariosUnicos.push(usuario);
    //                 }
    //             });
    //             setUsuariosDisponiveis(usuariosUnicos);
    //             console.log(usuariosUnicos);
    //         })
    //         .catch((err) => {
    //         });
    // }

    const handleAutocompleteUsuario = (event, newValue) => {
        setUsuarioSelected(newValue);
    };

    // const criaUsuarioGrupo = () => {
    //     // criar um novo registro de usuario-grupo
    //     const dadosUsuarioGrupo = {
    //         grupo_id: grupoConsultadoObj.id,
    //         usuario_id: usuarioSelected.id,
    //     }

    //     api.post("/usuariosRelac/",
    //         dadosUsuarioGrupo)
    //         .then((response) => {
    //             toast.success("Usuário adicionado ao Grupo!");
    //             //recarrega o grupo para refazer a lista de usuários do mesmo:
    //             recarregaGrupoConsultado(grupoConsultadoObj.id);
    //             setUsuarioSelected(null);
    //         })
    //         .catch(error => {
    //             toast.error("Não foi possível vincular usuário ao grupo.");
    //         });
    // }

    const removeUsuarioGrupo = (usuario_id) => {
        // url delete ordem de /grupo/usuario

        const confirmar_exclusao = window.confirm("Deseja excluir o usuário deste Grupo?");

        if (confirmar_exclusao) {
            api.delete("/usuariosRelac/" + grupoConsultado.id + "/" + usuario_id)
                .then((response) => {
                    toast.info("Usuário removido do Grupo!");
                    //recarrega o grupo para refazer a lista de usuários do mesmo:
                    recarregaGrupoConsultado(grupoConsultado.id);
                    setUsuarioSelected(null);
                })
                .catch(error => {
                    toast.error("Não foi possível remover usuário do grupo.");
                    console.log("erro removeUsuarioGrupo " + JSON.stringify(error));
                    toast.error(JSON.stringify(error.response.data));
                });
        }
    }

    const recarregaGrupoConsultado = (grupo_id) => {

        api.get("/grupos/" + grupo_id)
            .then((response) => {
                setGrupoConsultadoObj(response.data.data)
            })
            .catch((err) => {
                console.error("ops! ocorreu um erro : " + err);
                toast.error(JSON.stringify(err.response.data));
            });
    }

    // Função para filtrar usuários com base no termo
    const filtrarUsuarios = (termo) => {
        const usuariosFiltrados = gruposUsuariosRelac.filter((usuario) =>
            usuario.usuarios.nome.toLowerCase().includes(termo.toLowerCase())
        );
        return usuariosFiltrados;
    };
    // Estado para armazenar o termo de pesquisa
    const [termoPesquisa, setTermoPesquisa] = useState('');
    // Função para lidar com a mudança no campo de pesquisa
    const handlePesquisaChange = (e) => {
        setTermoPesquisa(e.target.value);
    };

    const [mostrarModalUsuariosGrupo, setMostrarModalUsuariosGrupo] = useState(false);
    const handleMostrarModalUsuariosGrupo = () => {
        setMostrarModalUsuariosGrupo(!mostrarModalUsuariosGrupo);
    };

    return (
        <>
            <Button onClick={handleMostrarModalUsuariosGrupo} variant="plain">Ver usuários</Button>
            <Modal
                open={mostrarModalUsuariosGrupo}
                onClose={() => setMostrarModalUsuariosGrupo(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: "80%",
                        height: "70%",
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                        outline: 'none'
                    }}
                >
                    <ModalClose
                        variant="outlined"
                        sx={{
                            top: 'calc(-1/4 * var(--IconButton-size))',
                            right: 'calc(-1/4 * var(--IconButton-size))',
                            boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                            borderRadius: '50%',
                            bgcolor: 'background.surface',
                        }}
                    />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        Grupo - {grupoConsultadoObj.nome}
                    </Typography>
                    <Stack mt="30px" sx={{ height: "80%" }}>
                        
                        {/* <Stack width="100%" flexDirection={mobile ? "column" : "row"} justifyContent="space-between" alignItems="center" marginBottom={"20px"}>
                            <Autocomplete options={usuariosDisponiveis} getOptionLabel={option => option.nome} 
                                onChange={handleAutocompleteUsuario} value={usuarioSelected}
                                style={mobile ? { width: "100%" } : { flex: 1, marginRight: "20px" }} />
                            <Button disabled={!usuarioSelected} onClick={criaUsuarioGrupo} startDecorator={<FaPlus size="20px" />} sx={mobile ? { margin: "10px 0 20px 0", width: "100%" } : {}}>Adicionar Usuário</Button>
                        </Stack> */}

                        <Stack width="100%" flexDirection={"row"} justifyContent="flex-end" alignItems={mobile ? "flex-start" : "center"} marginBottom={mobile ? "0px" : "20px"}>
                            <Input type="text" placeholder="Pesquisar Usuário"
                                value={termoPesquisa}
                                onChange={handlePesquisaChange} sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}></Input>

                            <Button onClick={() => [setUsuarioGrupoSelecionado(null), setModalDadosUsuario(true), setNovoUsuarioModal(true)]}
                                sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                                Adicionar Usuário
                            </Button>
                        </Stack>

                        {mobile ?
                            <Stack mt="30px" sx={{ flex: 1, overflow: "auto" }}>
                                <Stack sx={{overflowX: "hidden"}}>
                                    {gruposUsuariosRelac.map(function (item, indice) {
                                        return (
                                            <div key={indice} className="hover" onClick={() => [setModalDadosUsuario(true), setUsuarioGrupoSelecionado(item), setNovoUsuarioModal(false)]}>
                                                <Stack p={"10px"} boxShadow="sm" border="2px solid #ddd" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                                                    <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}>{item.usuarios.nome}</Typography>
                                                    <Typography level="body-sm"><Typography level="title-md">Perfil:</Typography>{item.perfil}</Typography>
                                                    <Typography level="body-sm"><Typography level="title-md">Tipo do Pedido:</Typography>{item.tipoPedido}</Typography>
                                                    {/* <Typography level="body-sm"><Typography level="title-md">Subgrupo Pedido:</Typography>{item.subGrupo}</Typography> */}
                                                    <Typography level="body-sm"><Typography level="title-md">Valor Mínimo:</Typography>{item.valorMin}</Typography>
                                                    <Typography level="body-sm"><Typography level="title-md">Valor Máximo:</Typography>{item.valorMax}</Typography>
                                                    {/* <Typography level="body-sm"><Typography level="title-md">Grupo:</Typography>{item.grupo}</Typography> */}
                                                </Stack>
                                            </div>
                                        )
                                    })}
                                </Stack>
                            </Stack>
                            :
                            <Stack sx={{ flex: 1, overflow: "auto" }}>
                                {/* Visualizacao desktop */}
                                <Table hoverRow stripe="even" noWrap>
                                    <thead>
                                        <tr>
                                            <th style={{ height: "25px" }}>Nome</th>
                                            <th style={{ height: "25px", width: "20%", minWidth: "180px" }}>Perfil</th>
                                            <th style={{ height: "25px", width: "20%", minWidth: "100px" }}>Tipo Pedido</th>
                                            {/* <th style={{ height: "25px", width: "150px" }}>Subgrupo Pedido</th> */}
                                            <th style={{ height: "25px", width: "10%", minWidth: "120px" }}>Valor Mínimo</th>
                                            <th style={{ height: "25px", width: "10%", minWidth: "120px" }}>Valor Máximo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filtrarUsuarios(termoPesquisa).map((item, indice) => (

                                            <tr key={indice} onClick={() => [setModalDadosUsuario(true), setUsuarioGrupoSelecionado(item), setNovoUsuarioModal(false)]} className="hover">
                                                <td style={{ padding: "0 10px" }}>{item.usuarios.nome}</td>
                                                <td style={{ padding: "0 10px" }}>{item.perfil}</td>
                                                <td style={{ padding: "0 10px" }}>{item.tipoPedido}</td>
                                                {/* <td style={{ padding: "0 10px" }}>{item.subGrupo}</td> */}
                                                <td style={{ padding: "0 10px" }}>{item.valorMin ? formatarMoeda(parseFloat(item.valorMin)) : "n/a"}</td>
                                                <td style={{ padding: "0 10px" }}>{item.valorMax ? formatarMoeda(parseFloat(item.valorMax)) : "n/a"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Stack>
                        }
                    </Stack>
                </Sheet>
            </Modal>

            {/* Componente Formulario De Usuario Grupo */}
            <UsuariosGrupo key={gruposUsuariosRelac} open={modalDadosUsuario} novoUsuarioModal={novoUsuarioModal} setOpenModal={setModalDadosUsuario}
                mobile={mobile} usuarioGrupoSelecionado={usuarioGrupoSelecionado} grupo={grupoConsultadoObj} listaGrupoUsuariosRelac={listaGrupoUsuariosRelac} />

        </>
    )
}