import { FaBell } from "react-icons/fa";
import {
    Link,
    Stack,
    Menu,
    MenuButton,
    MenuItem,
    Dropdown,
    Typography,
    Divider
} from '@mui/joy';

export default function Header(props) {
    return (
        <Stack position="fixed" zIndex={2} top={0} left={0} width="100vw" height={"50px"} backgroundColor="#0288D1" display={"flex"} flexDirection="row" justifyContent="flex-end" alignItems={"center"}>
            {/* <Dropdown>
                <MenuButton style={{ border: 0, backgroundColor: "transparent", marginRight: "0px" }}>
                    <Link underline="none" style={{ padding: "0 5px", height: "20px" }}>
                        <Stack justifyContent={"center"}>
                            {props.notification ?
                                <Stack width={"13px"} height="13px" borderRadius={"13px"} backgroundColor="#C62828" position="absolute" top="-3px" right="3px"></Stack>
                                : null
                            }
                            <FaBell size={26} color="#FBC02D" />
                        </Stack>
                    </Link>
                </MenuButton>
                <Menu style={props.mobile ? { width: "90%" } : { maxWidth: "30%" }}>
                    <MenuItem>
                        <Stack width="100%">
                            <Typography level="body-md">Nova Solicitação</Typography>
                            <Typography pb={1} level="body-sm">Descrição da nova solicitação</Typography>
                            <Divider />
                        </Stack>
                    </MenuItem>
                    <MenuItem>
                        <Stack width="100%">
                            <Typography level="body-md">Nova Solicitação</Typography>
                            <Typography pb={1} level="body-sm">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nemo alias debitis accusantium eius reiciendis totam voluptate obcaecati vero laudantium tenetur tempora nihil quibusdam eos nobis cum, nostrum sint neque? Aliquam.</Typography>
                            <Divider />
                        </Stack>
                    </MenuItem>
                </Menu>
            </Dropdown> */}
        </Stack>
    )
}