import axios from "axios";
//Componentes
import Header from "../../../../components/Header";
import Sidebar from "../../../../components/Sidebar";
import { useState, useEffect } from "react";
//Componentes
import ConfirmarExclusao from "../../../../components/ConfirmarExclusao";
import ModalAvisos from "../../../../components/modalAvisos";
//Utils
import { dataAtual } from "../../../../utils/datas";

import { Stack, Typography, Button, Select, Option, Input, FormLabel, Table, ToggleButtonGroup, Link, Checkbox, Textarea, Sheet, Switch, FormHelperText } from "@mui/joy";

import { toast } from 'react-toastify';
//icones react
import { FaRegEdit, FaRegTrashAlt, FaDownload, FaFolderOpen, FaInfoCircle, FaCheck } from "react-icons/fa";
import { GoLaw } from "react-icons/go";
import { IoEye, IoEyeOff } from "react-icons/io5";
//MediaQuery
import useMediaQuery from '@mui/material/useMediaQuery';
//Apis do RM
import { RM, api } from '../../../../services/api';

import { useNavigate, useLocation, useParams } from "react-router-dom";

import Autocomplete from '@mui/joy/Autocomplete';
import realizaConsulta from "../../../../services/rm";

let token = "";
let usuarioID = 0;
let usuarioLogin = "";
let desabilitaAprovadores = true;
let desabilitarEtapasAprovacao = true;

export default function AprovacaoTransferenciaMaterial(props) {

    const mobile = useMediaQuery('(max-width:1200px)');
    const navigate = useNavigate();
    const { id_param } = useParams();
    const [formulario, setFormulario] = useState({});
    const [mostrarCamposPedidoTransferencia, setMostrarCamposPedidoTransferencia] = useState(false);
    const [filialOrigem, setFilialOrigem] = useState(null);
    const [observacoesTransferencia, setObservacoesTransferencia] = useState("");
    const [locaisDeEstoque, setLocaisDeEstoque] = useState([]);
    const [localEstoqueSelecionado, setLocalEstoqueSelecionado] = useState(null);
    const [codLocSelectOpen, setCodLocSelectOpen] = useState(false);
    
    const [permissaoAprovador, setPermissaoAprovador] = useState(true);
    const [decisaoAprovacao, setDecisaoAprovacao] = useState("");
    const [informacoesAd, setInformacoesAd] = useState("");
    const [motivoCancelamento, setMotivoCancelamento] = useState("");

    const [enviarDisabled, setEnviarDisabled] = useState(false);

    useEffect(() => {
        //Obtendo o token
        if (JSON.parse(localStorage.getItem("usuario")) != null) {
            token = JSON.parse(localStorage.getItem('usuario')).token;
            usuarioID = JSON.parse(localStorage.getItem('usuario')).user.id;
            usuarioLogin = JSON.parse(localStorage.getItem('usuario')).user.login;
        }
        //Busca objeto Transferencia:
        if (id_param) {
            console.log(`transferenciaMateriais/${id_param}`);
            api.get(`transferenciaMateriais/${id_param}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            .then((response) => {
                console.log("novo mundo ", response.data.data);
                if (response.data) {
                    setFormulario(response.data.data);
                }
            })
            .catch((error) => {
                console.error(error);
                if (error.response && error.response.data) {
                    // Tratar diferentes mensagens de erro
                    console.error(error.response.data.message);
                }
                // Notificar o usuário sobre o erro
                toast.error("Ocorreu um erro ao obter os dados");
            });
        }

    }, []);


    const rolarParaOCampo = (decisao) => {
        if (decisao != "Aprovado") {
          //Precisa haver um delay na chamada da função de rolagem porque a renderização do componente é assíncrona
          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
          }, 50);
        }
      }


    const definirStatus = (decisao) => {
        //TODO: status na tabela transf?
        //se aprovado ou reprovado, continua em andamento:
        if (decisao == "Aprovado") {
            formulario.status = 1;
            return;
        }
        if (decisao == "Cancelado") {
            formulario.status = 2;
            return;
        }
    }

    const movimentarTransferencia = () => {

        formulario.decisao = decisaoAprovacao;
        formulario.informacoesAd = informacoesAd;
        formulario.motivoCancelamento = motivoCancelamento;
        setEnviarDisabled(true);


        let id_toast = toast.loading("Atualizando a transferência...");

        api.put(`transferenciaMateriais/${id_param}`, 
            formulario,
            {
                headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            toast.update(id_toast, { render: "Transferência salva com sucesso!", type: "success", isLoading: false, autoClose: 4000 });
            console.log("atualizou transferencia ", response.data.data);
            setTimeout(() => {
                window.location.reload(true);
            }, 2000);
        })
        .catch((error) => {
            setEnviarDisabled(false);
            if (error.response && error.response.data) {
                // Tratar diferentes mensagens de erro
                //toast.error(JSON.stringify(error.response.data?.errors));
                toast.update(id_toast, { render: JSON.stringify(error.response.data?.errors), type: "warning", isLoading: false, autoClose: 4000 });
                console.error(error.response.data);
            }else {
              toast.update(id_toast, { render: "Ocorreu um erro ao salvar. Verifique os dados, e tente novamente.", type: "warning", isLoading: false, autoClose: 4000 });
            }

        });
    }

    return(
        <Stack backgroundColor="#F5F5F5" height="100%" minHeight={"100vh"}>
            <Header mobile={mobile} notification />
            <Sidebar mobile={mobile} />
        
            <Stack
                ml={mobile ? "0px" : "250px"}
                mt="50px"
                flex={1}
                height={"calc(100% - 55px)"}
                p={mobile ? "20px" : "50px"}
                overflow="auto">
                {/* Conteúdo da Tela */}
                <Typography level="h4">Processo Transferência de Material: {formulario?.solicitacao_id}  | Etapa atual : {formulario?.solicitacao?.etapaAtual}</Typography>

                <Typography level="title-lg" mt="40px">
                    Dados da Transferência
                </Typography>

                <Link fontSize="16px"  flexDirection={"row"} ml="auto" onClick={() => navigate("/fluxoAprovacao", { state: { processo: formulario.solicitacao_id, origemChamada: "/aprovacaoTransferencia/", transferenciaMat: formulario.id }})} mr="20px">
                  Ver Fluxo Aprovação
                </Link>
                
                {formulario ?
                    <>
                        <Stack flexDirection={mobile ? "column" : "row"}>
                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile? "": ""} mt={mobile ? "20px" : "30px"}>
                                <FormLabel>Solicitante</FormLabel>
                                <Input type="text" value={formulario?.user?.nome} disabled />
                            </Stack>

                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile? "": "30px"} mt={mobile ? "20px" : "30px"}>
                                <FormLabel>Filial Origem</FormLabel>
                                <Input type="text" value={formulario?.filial_origem?.nome} disabled />
                            </Stack>

                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile? "": "30px"} mt={mobile ? "20px" : "30px"}>
                                <FormLabel>Local Origem</FormLabel>
                                <Input type="text" value={formulario.localEstoqueOrigem} disabled />
                            </Stack>
                        </Stack>

                        <Stack flexDirection={mobile ? "column" : "row"}>
                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile? "": ""} mt={mobile ? "20px" : "30px"}>
                                <FormLabel>Filial Destino</FormLabel>
                                <Input type="text" value={formulario?.filial_destino?.nome} disabled />
                            </Stack>

                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile? "": "30px"} mt={mobile ? "20px" : "30px"}>
                                <FormLabel>Local Destino</FormLabel>
                                <Input type="text" value={formulario.localEstoqueDestino} disabled />
                            </Stack>

                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile? "": "30px"} mt={mobile ? "20px" : "30px"}>
                                <FormLabel>Série</FormLabel>
                                <Input type="text" value={formulario.serie} disabled />
                            </Stack>
                        </Stack>

                        <Stack flexDirection={mobile ? "column" : "row"}>
                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile? "": ""} mt={mobile ? "20px" : "30px"}>
                                <FormLabel>N° Movimento</FormLabel>
                                <Input type="text" value={formulario.numeroMovimento} disabled />
                            </Stack>
                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile? "": "30px"} mt={mobile ? "20px" : "30px"}>
                                <FormLabel>Centro de Custo Origem</FormLabel>
                                <Input type="text" value={formulario.centroCustoOrigem} disabled />
                            </Stack>
                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile? "": "30px"} mt={mobile ? "20px" : "30px"}>
                                <FormLabel>Data emissão</FormLabel>
                                <Input type="date" value={formulario.DataEmissao} disabled />
                            </Stack>
                        </Stack>

                        <Stack flexDirection={mobile ? "column" : "row"}>
                            <Stack flex={1} minWidth={mobile ? "100%" : "110px"} width={mobile ? "100%" : "110px"} ml={mobile? "": ""} mt={mobile ? "20px" : "30px"}>
                                <FormLabel>Observações</FormLabel>
                                <Input type="text" value={formulario.observacoes} disabled />
                            </Stack>
                        </Stack>

                    </>
                :
                    null
                }


            {/* BLOCO DOS ITENS  */}

            <Stack mt="30px">
              {mobile ?
                <Stack>
                  {formulario?.solicitacao?.itens.map((item, indice) => {
                    return (
                      <div key={indice}>
                        {/* a <Stack> possui uma condicao, caso seja aprovador gerencial/institucional/analise-critica responsavel, ocultar itens que não possuem quantidade a comprar. */}
                        <Stack p={"10px"} boxShadow="md" borderRadius={5} mb={"10px"} sx={{ userSelect: "none" }}>
                          <Stack flexDirection="row" alignItems="center" justifyContent={"space-between"}>
                            <Typography level="body-lg" borderBottom={1} borderColor="#ccc" mb={"10px"}><Typography level="title-lg">Código: </Typography>{item.codProduto}</Typography>
                            
                          </Stack>
                          <Typography level="body-sm"><Typography level="title-md">Item: </Typography>{indice + 1}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Descrição: </Typography>{item.descricao}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Saldo: </Typography>{item.estoque}</Typography>
                          <Typography level="body-sm"><Typography level="title-md">Qtd: </Typography>{item.quantidadeProduto}</Typography>

                          {/* {etapaAtual == "Solicitante" && osVinculada ? <Typography level="body-sm"><Typography level="title-md">Status: </Typography>{item.movimentado[0] ? item.movimentado[0].STATUS : " - "}</Typography> : null}
                          {etapaAtual == "Solicitante" && osVinculada && item.movimentado[0] ? <div onClick={() => [setModalMovimentacoesItem(true), setIndiceItemConsultado(indice), setItemTemporario(formulario.itens[indice])]} className="hover"><FaInfoCircle size={20} color="#444" /></div> : null} */}

                          <Stack level="body-sm" mt="20px">
                            <Stack flexDirection="row" alignItems="center">
                            </Stack>
                          </Stack>
                        </Stack>
                      </div>
                    );
                  })}
                </Stack>
                :
                <Table hoverRow stripe="even" noWrap aria-label="basic table">
                  <thead>
                    <tr>
                      
                      <th style={{ width: "250px", height: "25px" }}>Item</th>
                      <th style={{ width: "300px", height: "25px"}}>Código</th>
                      <th style={{ width: "420px", height: "25px"}}>Descrição</th>
                      <th style={{ width: "300px", height: "25px" }}>Saldo</th>
                      <th style={{ width: "200px", height: "25px" }}>Qtd</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formulario?.solicitacao?.itens.map((item, index) =>
                      item.quantidadeProduto ?
                        <tr key={index}>
                          <td style={{ padding: "0 10px" }}>{parseInt(index + 1)}</td>
                          <td style={{ padding: "0 10px" }}>{item.codProduto}</td>
                          <td style={{ padding: "0 10px", minWidth: "400px" }}>{item.descricao}</td>
                          <td style={{ padding: "0 10px" }}>{item.estoque}</td>
                          <td style={{ padding: "0 10px" }}>{item.quantidadeProduto}</td>
                          <td style={{ padding: "0 10px", display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
                          </td>
                        </tr>
                        : null
                    )}
                  </tbody>
                </Table>
              }

            </Stack>

            {/* Container de aprovação */}
            {permissaoAprovador && formulario.Aprovador ?
              <Stack flexDirection={"column"} boxShadow={mobile ? "" : "md"} padding={0} borderRadius={"10px"} mt="50px" sx={mobile ? {} : { backgroundColor: "#fff" }}>
                <Stack p={mobile ? 0 : 3} flexDirection={mobile ? "column" : "row"} justifyContent={mobile ? "flex-start" : "space-between"} alignItems={mobile ? "flex-start" : "flex-end"}>
                  <Stack>
                    <Typography level="title-lg">Decisão</Typography>
                    <ToggleButtonGroup
                      value={decisaoAprovacao}
                      onChange={(event, newValue) =>
                        [
                          setDecisaoAprovacao(newValue ? newValue : decisaoAprovacao),
                          rolarParaOCampo(newValue ? newValue : decisaoAprovacao),
                          definirStatus(newValue ? newValue : decisaoAprovacao),
                        ]
                      }
                    >
                      <Button value="Aprovado" style={decisaoAprovacao == "Aprovado" ? { backgroundColor: "#7cb342" } : {}}>
                        <Typography sx={decisaoAprovacao == "Aprovado" ? { color: "#eee" } : {}}>Aprovar</Typography>
                      </Button>
                      <Button value="Cancelado" style={decisaoAprovacao == "Cancelado" ? { backgroundColor: "#f44336" } : {}}>
                        <Typography sx={decisaoAprovacao == "Cancelado" ? { color: "#eee" } : {}}>Cancelar</Typography>
                      </Button>
                    </ToggleButtonGroup>
                  </Stack>
                  <Stack width={mobile ? "100%" : "auto"}>
                    <Button disabled={(decisaoAprovacao == "") || (enviarDisabled)} sx={mobile ? { marginTop: "20px" } : {}} onClick={(e) => movimentarTransferencia(null)}>Confirmar Decisão</Button>
                  </Stack>
                </Stack>
                {/* Bloco informacoes dos aprovadores */}
                <Stack p={mobile ? 0 : 3} flexDirection={mobile ? "column" : "row"} justifyContent={"space-between"} alignItems={"flex-end"}>
                  <Stack width={mobile ? "100%" : "50%"}>
                    <Stack mt="30px" mr="30px">
                      <Typography level="title-md">Informações Adicionais</Typography>
                      <Textarea value={informacoesAd} onChange={event => setInformacoesAd(event.target.value)} minRows={3} sx={{ marginTop: "5px" }} placeholder="Descreva as informações adicionais" />
                    </Stack>
                  </Stack>
                  {decisaoAprovacao == "Cancelado" ?
                    <Stack mt="30px" width={mobile ? "100%" : "50%"}>
                      <Typography level="title-md">Motivo do Cancelamento</Typography>
                      <Textarea value={motivoCancelamento} onChange={event => setMotivoCancelamento(event.target.value)} minRows={3} sx={{ marginTop: "5px", border: 1, borderColor: "#f44336" }} placeholder="Informe o motivo do cancelamento" />
                    </Stack>
                    : null}
                </Stack>
              </Stack>
              : null
            }

            </Stack>
        </Stack>
    );
}